// Library imports
import React from 'react';
import { ResponsivePie } from '@nivo/pie';
import { shape, string } from 'prop-types';
import { useTheme } from 'styled-components';
// Component imports
import NoData from '../../../../../../components/NoData';
import CompareList from '../../../../../../components/CompareList';
// Util imports
import { shortName } from '../../../../../../utils';
// Styled components
import { InnerContent } from '../styles';
import { TotalContainer, TotalText, TotalLabel, ChartContainer } from './styles';

const Totals = ({ sport, game }) => {
    const theme = useTheme();

    const generateFieldList = team => {
        if (!team?.stats) {
            return [];
        }
        const teamStats = [];
        teamStats.push(team.stats.off?.oppg ? team.stats.off?.oppg : 'N/A');
        teamStats.push(team.recordData?.ou ? team.recordData?.ou : 'N/A');
        if (sport === 'mlb') {
            return teamStats;
        }
        if (sport === 'nba' || sport === 'cbb') {
            teamStats.push(team.stats.off?.paceofplay ? team.stats.off?.paceofplay : 'N/A');
        } else {
            teamStats.push(team.stats.off?.top ? team.stats.off?.top : 'N/A');
        }
        return teamStats;
    };

    if (!game.totalStats) {
        return <NoData title="No data" message="There is currently no total data for this game.  Check back soon." />;
    }

    const totalStats = game.totalStats;
    const overValue = totalStats.over / (totalStats.under + totalStats.over);
    const underValue = totalStats.under / (totalStats.under + totalStats.over);
    const pieData = [
        { id: 'Overs', label: 'Overs', value: overValue },
        { id: 'Unders', label: 'Unders', value: underValue },
    ];
    const pieColors = totalStats.under < totalStats.over ? ['#d4edda', '#f8d7da'] : ['#f8d7da', '#d4edda'];

    return (
        <InnerContent fluid>
            <TotalContainer>
                <TotalLabel>Total</TotalLabel>
                <TotalText>{game.total}</TotalText>
            </TotalContainer>
            <ChartContainer>
                <ResponsivePie
                    data={pieData}
                    colors={pieColors}
                    startAngle={-90}
                    endAngle={90}
                    padAngle={3}
                    valueFormat=" >-.2%"
                    innerRadius={0.25}
                    borderWidth={2}
                    arcLinkLabelsTextColor={theme.colors.black}
                    arcLinkLabelsThickness={3}
                    arcLinkLabelsColor={{ from: 'color' }}
                    arcLabelsSkipAngle={10}
                />
            </ChartContainer>
            <CompareList
                labels={sport === 'mlb' ? ['PPG', 'O/U'] : ['PPG', 'O/U', sport === 'nba' || sport === 'cbb' ? 'Pace of Play' : 'Time of Poss.']}
                dataSetOne={generateFieldList(game.awayTeam)}
                dataSetOneTitle={shortName(game.awayTeam)}
                dataSetOneTitleColor={game.awayTeam.color}
                dataSetTwo={generateFieldList(game.homeTeam)}
                dataSetTwoTitle={shortName(game.homeTeam)}
                dataSetTwoTitleColor={game.homeTeam.color}
            />
        </InnerContent>
    );
};

Totals.propTypes = {
    sport: string.isRequired,
    game: shape({}).isRequired,
};

export default Totals;
