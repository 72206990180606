// Library imports
import React from 'react';
import { Controller } from 'react-hook-form';
import { MDBInput } from 'mdb-react-ui-kit';
import { bool, shape, string } from 'prop-types';
// Styled component imports
import { ErrorMessage, InputContainer } from './styles';

const TextInput = ({
    name,
    isRequired = false,
    control,
    id = '',
    size = '',
    label = '',
    type = 'text',
    readOnly = false,
    helperText = '',
    rules = {},
    ...inputProps
}) => {
    return (
        <InputContainer>
            <Controller
                name={name}
                control={control}
                rules={{
                    required: isRequired ? 'This field is required' : false,
                    ...rules,
                    validate: {
                        forbiddenCode: value => {
                            const forbiddenPattern = /[<>]/;
                            if (forbiddenPattern.test(value)) {
                                return 'Input contains invalid characters';
                            } else {
                                return true;
                            }
                        },
                        ...rules.validate,
                    },
                }}
                render={({ field, fieldState: { error } }) => {
                    return (
                        <div style={{ position: 'relative', width: '100%' }}>
                            <MDBInput
                                readOnly={readOnly}
                                label={label}
                                id={id}
                                type={type}
                                invalid={!!error ? 'true' : 'false'}
                                size={size}
                                {...field}
                                {...inputProps}
                                value={field.value || ''}
                            />
                            {helperText && !error && (
                                <div
                                    id={`${id}HelperText`}
                                    className="form-helper"
                                    style={{
                                        marginTop: '4px',
                                        fontSize: '0.875rem',
                                        color: '#6c757d',
                                    }}
                                >
                                    {helperText}
                                </div>
                            )}
                            {error && (
                                <ErrorMessage
                                    id={`${id}ErrorMessage`}
                                    style={{
                                        marginTop: '4px',
                                        fontSize: '0.875rem',
                                        color: '#d63031', // Error red
                                    }}
                                >
                                    {error.message}
                                </ErrorMessage>
                            )}
                        </div>
                    );
                }}
            />
        </InputContainer>
    );
};

TextInput.propTypes = {
    control: shape({}).isRequired,
    isRequired: bool,
    name: string.isRequired,
    type: string,
    id: string,
    size: string,
    label: string,
    readOnly: bool,
    helperText: string,
    rules: shape({}),
};

export default TextInput;
