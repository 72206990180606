// Firebase imports
import firebase from 'firebase';
// Util imports
import { ACTIVE_SPORTS } from '../utils/constants';

export const authListener = () => {
    return new Promise((resolve, reject) => {
        firebase.auth().onAuthStateChanged(async user => {
            if (user) {
                resolve(user);
            } else {
                window.location = '/login';
                reject('--- Error authenticating user! ---');
            }
        });
    });
};

export const getUserToken = async () => {
    return await firebase.auth().currentUser.getIdToken();
};

export const isUserAdmin = async () => {
    const token = await getUserToken();
    return fetch(`${process.env.REACT_APP_HOST}/api/user/isAdmin`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    })
        .then(async res => {
            const json = await res.json();
            if (json.error) return false;
            return true;
        })
        .catch(error => {
            console.log('Error checking if user is an admin.\n' + error);
            return false;
        });
};

export const register = (email, password, displayName = '', packageType) => {
    return fetch(`${process.env.REACT_APP_HOST}/api/user`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password, displayName, packageType }),
    }).then(async res => {
        const json = await res.json();
        if (json.error) throw new Error(json.error);
        return json;
    });
};

export const login = async (email, password) => {
    const user = await firebase.auth().signInWithEmailAndPassword(email, password);
    if (user) {
        window.location = `/sports/${ACTIVE_SPORTS[0]}`;
    }
};

export const updateProfile = async fieldsToUpdate => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/user`, {
        method: 'PATCH',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(fieldsToUpdate),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    json.avatarSrc = await getAvatarSrc(json.avatar.fileLocation);
    return json;
};

export const uploadUserAvatar = async file => {
    const token = await getUserToken();
    var data = new FormData();
    data.append('image', file);
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/user/avatar`, {
        method: 'POST',
        headers: {
            authorization: `Bearer ${token}`,
        },
        body: data,
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    json.avatarSrc = await getAvatarSrc(json.avatar.fileLocation);
    return json;
};

const getAvatarSrc = async url => {
    var httpsReference = firebase.storage().refFromURL(url);
    const src = await httpsReference.getDownloadURL();
    return src;
};

export const deleteProfile = async (uid, email) => {
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/user`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, uid }),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const getUserData = async uid => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/user?uid=` + uid, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    json.avatarSrc = await getAvatarSrc(json.avatar.fileLocation);
    return json;
};

export const addPick = async pickDetails => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/pick`, {
        method: 'POST',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(pickDetails),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const editPick = async pickDetails => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/pick`, {
        method: 'PATCH',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(pickDetails),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const deletePick = async pickId => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/pick`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ pickId }),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const pickRecord = async (timeframe, vip) => {
    const token = await getUserToken();
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/pick/record?vip=${vip}&timeframe=${timeframe}`, {
        method: 'GET',
        headers: {
            authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
        },
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json;
};

export const sendEmail = async (email, type) => {
    const res = await fetch(`${process.env.REACT_APP_HOST}/api/email`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, type }),
    });
    const json = await res.json();
    if (json.error) throw new Error(json.error);
    return json.message;
};
