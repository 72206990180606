// Library imports
import styled from 'styled-components';
import { MDBContainer } from 'mdb-react-ui-kit';
// Util imports
import { XL_SCREEN_WIDTH } from '../../utils/constants';

export const FeatureSectionContainer = styled(MDBContainer)`
    background: ${({ bgColor }) => bgColor} !important;
    padding: 6rem 10rem;

    @media all and (max-width: ${XL_SCREEN_WIDTH}) {
        padding: 6rem 2rem;
    }
`;

export const TitleContainer = styled.div`
    margin-bottom: 3rem;
    width: 100%;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
`;

export const SectionTitle = styled.span`
    color: ${({ theme }) => theme.colors.black};
    font-size: clamp(2rem, 7vw, 3rem);
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: 900;
    line-height: 1.2;
    display: block;
`;

export const SectionText = styled.p`
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 1.35rem;
    letter-spacing: 0.05rem;
    line-height: 1.75rem;
    font-weight: 400;
`;
