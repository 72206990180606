// Library imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
// Controller imports
import { authListener, getUserData } from '../../controllers/UserController';
import { getCustomer, getReciept, updateReciept } from '../../controllers/PaymentController';
// Util imports
import { convertToISOString } from '../../utils';

const initialState = {
    user: {
        reciept: {},
        cust: {},
    },
    userLoading: true,
    receiptLoading: false,
    customerLoading: false,
};

const isUserExpired = async (reciept, customerData) => {
    if (reciept?.endDate && moment(reciept.endDate).isBefore(moment.now()) && reciept?.type === 'account') {
        // #1 Check if user is a Stripe customer, has an active subscription
        // #2 Check that the subscription period end date is after today (they have paid for next month)
        // #3 Make sure the subscription isn't past due (failed payment)
        if (
            customerData?.subscriptions?.data?.length &&
            moment.unix(customerData.subscriptions.data[0].current_period_end).isAfter(moment()) &&
            customerData?.subscriptions.data[0].status !== 'past_due'
        ) {
            await updateReciept(reciept._id, 'endDate', convertToISOString(moment.unix(customerData.subscriptions.data[0].current_period_end)));
            return false;
        } else {
            return true;
        }
    }
    return false;
};

export const fetchUser = createAsyncThunk('user/fetchUser', async (_, { rejectWithValue }) => {
    try {
        const user = await authListener();
        const userData = await getUserData(user.uid);
        var reciept = await getReciept(userData.email, 'account');
        if (Object.keys(reciept).length === 0) {
            reciept = await getReciept(userData.email, 'free-account');
        }
        const cust = await getCustomer(user.email);
        const isExpired = await isUserExpired(reciept, cust);
        return {
            ...userData,
            reciept: { ...reciept },
            cust: { ...cust },
            expired: isExpired,
        };
    } catch (error) {
        console.log(error);
        return rejectWithValue(`Error setting up auth listener\n${error.message}`);
    }
});

export const fetchReciept = createAsyncThunk('user/fetchReciept', async (_, { getState, rejectWithValue }) => {
    try {
        const { user: userSlice } = getState();
        const user = userSlice.user;
        var reciept = await getReciept(user.email, 'account');
        if (Object.keys(reciept).length === 0) {
            reciept = await getReciept(user.email, 'free-account');
        }
        return { ...reciept };
    } catch (error) {
        return rejectWithValue(`Error getting reciept in fetchReciept\n${error.message}`);
    }
});

export const fetchCustomer = createAsyncThunk('user/fetchCustomer', async (_, { getState, rejectWithValue }) => {
    try {
        const { user: userSlice } = getState();
        const user = userSlice.user;
        const cust = await getCustomer(user.email);
        return { ...cust };
    } catch (error) {
        return rejectWithValue(`Error getting customer in fetchCustomer\n${error.message}`);
    }
});

const userSlice = createSlice({
    name: 'user',
    initialState: initialState,
    reducers: {
        setUser(state, { payload }) {
            state.user = { ...state.user, ...payload };
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchUser.pending, state => {
            state.userLoading = true;
        });
        builder.addCase(fetchUser.fulfilled, (state, { payload }) => {
            state.user = { ...payload };
            state.userLoading = false;
        });
        builder.addCase(fetchUser.rejected, (state, { payload }) => {
            state.userLoading = false;
            console.log(`Error fetching user\n${payload}`);
        });
        builder.addCase(fetchReciept.pending, state => {
            state.receiptLoading = true;
        });
        builder.addCase(fetchReciept.fulfilled, (state, { payload }) => {
            state.user = { ...state.user, reciept: { ...payload } };
            state.receiptLoading = false;
        });
        builder.addCase(fetchReciept.rejected, state => {
            state.receiptLoading = false;
        });
        builder.addCase(fetchCustomer.pending, state => {
            state.customerLoading = true;
        });
        builder.addCase(fetchCustomer.fulfilled, (state, { payload }) => {
            state.user = { ...state.user, cust: { ...payload } };
            state.customerLoading = false;
        });
        builder.addCase(fetchCustomer.rejected, state => {
            state.customerLoading = false;
        });
    },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
