// Libray imports
import React, { useState } from 'react';
import { MDBIcon, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
import { faArrowAltCircleLeft, faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { bool, shape, string } from 'prop-types';
// Component imports
import UpgradeTooltip from '../../../../../../components/UpgradeTooltip';
import PillNav from '../../../../../../components/PillNav';
// Utils imports
import { formatOdd, shortString } from '../../../../../../utils';
// Styled components
import {
    TeamSectionContainer,
    TeamLogo,
    TeamNameWrapper,
    PowerDataContainer,
    PowerItemContainer,
    PowerRankArrow,
    PowerItemLabel,
    PowerCol,
    PowerTeamId,
    Score,
} from './styles';
import { CardTitle, HighLightedField, InnerContent, TeamRank } from '../styles';
import { LabelValueListGroup } from '../../../../styles';
// Image imports
import defaultImage from '../../../../../../img/logo-icon-gray.png';

const PowerRank = ({ demo = false, game, sport }) => {
    // Redux
    const { user } = useSelector(state => state.user);
    // Local state
    const [type, setType] = useState(sport === 'mlb' || sport === 'nhl' ? 'moneyline' : 'spread');

    const awayTeamVegasOdd = type === 'spread' ? game.awayTeam.odds?.fullGame?.spread : game.awayTeam.odds?.fullGame?.moneyline;
    const homeTeamVegasOdd = type === 'spread' ? game.homeTeam.odds?.fullGame?.spread : game.homeTeam.odds?.fullGame?.moneyline;

    const getPowerPrediction = team => {
        if (!game.prediction) return 'NA';
        const { awayScore, homeScore, powerMoneyline } = game.prediction;
        if (!awayScore && !homeScore) {
            return 'NA';
        }
        if (type === 'spread') {
            if (team === 'home') {
                return formatOdd((awayScore - homeScore).toFixed(1), 'spread');
            } else {
                return formatOdd((homeScore - awayScore).toFixed(1), 'spread');
            }
        } else {
            var oddsToFormat;
            if (team === 'home') {
                oddsToFormat = homeScore - awayScore > 0 ? powerMoneyline.favML : powerMoneyline.dogML;
            } else {
                oddsToFormat = awayScore - homeScore > 0 ? powerMoneyline.favML : powerMoneyline.dogML;
            }
            return formatOdd(oddsToFormat, 'moneyline');
        }
    };

    const calcWinPercentage = moneyline => {
        if (!moneyline || isNaN(moneyline)) return 0;
        if (!moneyline || isNaN(moneyline)) return 0;
        var probability = 0;
        if (moneyline < 0) {
            probability = (-1 * moneyline) / (-1 * moneyline + 100);
        }
        if (moneyline > 0) {
            probability = 100 / (moneyline + 100);
        }
        return Number((probability * 100).toFixed(0));
    };

    const calcSpreadDifference = (vegasSpread, powerSpread) => {
        if (!vegasSpread || isNaN(vegasSpread)) return 0;
        if (!vegasSpread || isNaN(powerSpread)) return 0;
        const difference = Number(vegasSpread - powerSpread).toFixed(2);
        return formatOdd(difference, 'spread');
    };

    const getHighlightVariant = team => {
        if (user?.reciept?.type === 'free-account') {
            return 'light';
        }
        if (type === 'moneyline') {
            if (isNaN(Number(getPowerPrediction(team)))) {
                return 'light';
            }
            if (calcWinPercentage(Number(getPowerPrediction(team))) > calcWinPercentage(game[`${team}Team`].odds?.fullGame?.moneyline)) {
                return 'success';
            } else {
                return 'danger';
            }
        }
        if (type === 'spread') {
            if (!calcSpreadDifference(game[`${team}Team`].odds?.fullGame?.spread, Number(getPowerPrediction(team)))) {
                return 'light';
            }
            if (calcSpreadDifference(game[`${team}Team`].odds?.fullGame?.spread, Number(getPowerPrediction(team))) > 0) {
                return 'success';
            } else {
                return 'danger';
            }
        }
        return 'light';
    };

    const imageLoadError = e => {
        e.target.src = defaultImage;
    };

    const generateTeamWrapper = team => {
        return (
            <TeamSectionContainer>
                <TeamNameWrapper side={game.awayTeam.teamId === team.teamId ? 'left' : 'right'}>
                    <TeamLogo src={team.logo} id={team.teamId} alt="team logo" onError={imageLoadError} />
                    <CardTitle color={team.color} responsive={true} title={team.teamName}>
                        {team.rank && <TeamRank right={String(team.teamId !== game.homeTeam.teamId)}>#{team.rank}</TeamRank>}
                        {shortString(team.teamName)}
                    </CardTitle>
                    <PowerTeamId>({team.abbr})</PowerTeamId>
                </TeamNameWrapper>
                <LabelValueListGroup silverBg="true" direction="row" responsive={true} rightJustify={team.teamId === game.homeTeam.teamId}>
                    <MDBListGroupItem>
                        <label>Record</label>
                        <span>{team.recordData ? team.recordData.record : '0-0'}</span>
                    </MDBListGroupItem>
                    <MDBListGroupItem>
                        <label>{team.teamId === game.awayTeam.teamId ? 'Away' : 'Home'}</label>
                        <span>
                            {team.recordData ? `${team.teamId === game.awayTeam.teamId ? team.recordData.homeRecord : team.recordData.awayRecord}` : '0-0'}
                        </span>
                    </MDBListGroupItem>
                    <MDBListGroupItem>
                        <label>ATS</label>
                        <span>{team.recordData?.ats ? `${team.recordData.ats}` : '0-0'}</span>
                    </MDBListGroupItem>
                </LabelValueListGroup>
            </TeamSectionContainer>
        );
    };

    return (
        <InnerContent style={{ overflow: 'hidden' }} fluid>
            <MDBRow style={{ flexWrap: 'nowrap' }}>
                <PowerCol>{generateTeamWrapper(game.awayTeam)}</PowerCol>
                <PowerCol style={{ maxWidth: '12rem' }} className="g-0">
                    <PowerDataContainer>
                        <PillNav
                            tabs={['moneyline', 'spread']}
                            activeTab={type}
                            setActiveTab={setType}
                            buttonStyle={{ fontSize: '0.75rem', padding: '0.35rem 0.45rem' }}
                            containerStyle={{ minWidth: '10rem' }}
                        />
                        <PowerItemContainer>
                            <PowerItemLabel>Vegas</PowerItemLabel>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                <HighLightedField
                                    variant={game.awayTeam.odds?.fullGame?.moneyline < game.homeTeam.odds?.fullGame?.moneyline ? 'primary' : 'light'}
                                    style={{ minWidth: '3.5rem' }}
                                >
                                    <span>{formatOdd(awayTeamVegasOdd, type)}</span>
                                    {type === 'spread' ? (
                                        <span style={{ display: 'block', fontSize: '12px' }}>Spread</span>
                                    ) : (
                                        <span style={{ display: 'block', fontSize: '12px' }}>{calcWinPercentage(awayTeamVegasOdd)}%</span>
                                    )}
                                </HighLightedField>
                                {game.awayTeam.odds?.fullGame?.moneyline < game.homeTeam.odds?.fullGame?.moneyline ? (
                                    <PowerRankArrow icon={faArrowAltCircleLeft} />
                                ) : (
                                    <PowerRankArrow icon={faArrowAltCircleRight} />
                                )}
                                <HighLightedField
                                    variant={game.awayTeam.odds?.fullGame?.moneyline > game.homeTeam.odds?.fullGame?.moneyline ? 'primary' : 'light'}
                                    style={{ minWidth: '3.5rem' }}
                                >
                                    <span>{formatOdd(homeTeamVegasOdd, type)}</span>
                                    {type === 'spread' ? (
                                        <span style={{ display: 'block', fontSize: '12px' }}>Spread</span>
                                    ) : (
                                        <span style={{ display: 'block', fontSize: '12px' }}>{calcWinPercentage(homeTeamVegasOdd)}%</span>
                                    )}
                                </HighLightedField>
                            </div>
                        </PowerItemContainer>
                        <PowerItemContainer>
                            <PowerItemLabel>Powerline</PowerItemLabel>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                                <HighLightedField variant={getHighlightVariant('away')} style={{ minWidth: '3.5rem' }}>
                                    <span>
                                        {!demo && user.reciept.type === 'free-account' ? (
                                            <UpgradeTooltip children={<MDBIcon icon="lock" />} />
                                        ) : (
                                            getPowerPrediction('away')
                                        )}
                                    </span>
                                    {user?.reciept?.type === 'free-account' ? (
                                        <></>
                                    ) : type === 'spread' ? (
                                        <span style={{ display: 'block', fontSize: '12px' }}>
                                            {calcSpreadDifference(game.awayTeam.odds?.fullGame?.spread, Number(getPowerPrediction('away')))}
                                        </span>
                                    ) : (
                                        <span style={{ display: 'block', fontSize: '12px' }}>{calcWinPercentage(Number(getPowerPrediction('away')))}%</span>
                                    )}
                                </HighLightedField>
                                {user?.reciept?.type === 'free-account' ? (
                                    <></>
                                ) : type === 'spread' ? (
                                    calcSpreadDifference(game.awayTeam.odds?.fullGame?.spread, Number(getPowerPrediction('away'))) > 0 ? (
                                        <PowerRankArrow icon={faArrowAltCircleLeft} />
                                    ) : (
                                        <PowerRankArrow icon={faArrowAltCircleRight} />
                                    )
                                ) : game.advantages && game.advantages.find(a => a.id === 'power-rank').teamId === game.awayTeam.teamId ? (
                                    <PowerRankArrow icon={faArrowAltCircleLeft} />
                                ) : (
                                    <PowerRankArrow icon={faArrowAltCircleRight} />
                                )}
                                <HighLightedField variant={getHighlightVariant('home')} style={{ minWidth: '3.5rem' }}>
                                    <span>
                                        {!demo && user.reciept.type === 'free-account' ? (
                                            <UpgradeTooltip children={<MDBIcon icon="lock" />} />
                                        ) : (
                                            getPowerPrediction('home')
                                        )}
                                    </span>
                                    {user?.reciept?.type === 'free-account' ? (
                                        <></>
                                    ) : type === 'spread' ? (
                                        <span style={{ display: 'block', fontSize: '12px' }}>
                                            {calcSpreadDifference(game.homeTeam.odds?.fullGame?.spread, Number(getPowerPrediction('home')))}
                                        </span>
                                    ) : (
                                        <span style={{ display: 'block', fontSize: '12px' }}>{calcWinPercentage(Number(getPowerPrediction('home')))}%</span>
                                    )}
                                </HighLightedField>
                            </div>
                        </PowerItemContainer>
                    </PowerDataContainer>
                </PowerCol>
                <PowerCol>{generateTeamWrapper(game.homeTeam)}</PowerCol>
            </MDBRow>
        </InnerContent>
    );
};

PowerRank.propTypes = {
    demo: bool,
    game: shape({}).isRequired,
    sport: string.isRequired,
};

export default PowerRank;
