import styled from 'styled-components';

export const PlayerName = styled.label`
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: 800 !important;
    color: ${({ theme }) => theme.colors.black} !important;
`;

export const InjuryStatus = styled.span`
    display: block;
    font-family: ${({ theme }) => theme.fonts.body};
    font-weight: 500 !important;
    color: ${({ theme }) => theme.colors.darkGray} !important;
`;
