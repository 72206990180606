// Library imports
import React, { useState } from 'react';
import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Calendar } from 'react-calendar';
import { arrayOf, element, string } from 'prop-types';
import { useTheme } from 'styled-components';
// Compnent imports
import BarChart from '../Graphs/BarChart';
import GameCard from '../../app_pages/App/GameCards/GameCard';
import SportsNav from '../SportsNav';
// Mock data imports
import gameData from '../../utils/mock_data/ExampleGameData';
import trendData from '../../utils/mock_data/ExampleTrendData';
import { dailyUnitData, calendarData } from '../../utils/mock_data/ExampleVIPData';
// Styled components
import { ButtonContainer, Description, Heading, SectionContainer, TopLine, SectionImage, ContentColumn } from './styles';

const Section = ({
    backgroundColor = 'white',
    topLine = '',
    headline = '',
    description = '',
    buttons = [],
    imgSrc = '',
    contentSide = '',
    contentType = 'image',
    defaultCardWindow = 'power-rank',
}) => {
    const theme = useTheme();
    // Local state
    const [sport, setSport] = useState(defaultCardWindow === 'prediction' ? 'nhl' : 'mlb');

    const generateContent = () => {
        if (contentType === 'card') {
            return (
                <>
                    <SportsNav all={false} click={setSport} activeItem={sport} demo={true} />
                    <GameCard game={gameData[sport]} sport={sport} demo={true} vipPlay={true} defaultWindow={defaultCardWindow} />
                </>
            );
        }
        if (contentType === 'trend') {
            return (
                <MDBCard style={{ marginTop: '15px', width: '100%' }}>
                    <MDBCardBody>
                        <MDBCardTitle>{trendData.title}</MDBCardTitle>
                        <MDBCardText>{trendData.description}</MDBCardText>
                        <BarChart
                            xAxisLabel="Factor"
                            yAxisLabel="Win %"
                            yAxisKey="winPercent"
                            symbol="%"
                            data={trendData.data.map(factor => {
                                if (factor['moneyline'].entries !== 0) {
                                    return {
                                        Factor: factor.label,
                                        winPercent: (factor['moneyline'].wins / factor['moneyline'].entries) * 100,
                                    };
                                }
                                return { Factor: factor.label, winPercent: 0 };
                            })}
                        />
                    </MDBCardBody>
                </MDBCard>
            );
        }
        if (contentType === 'vip-stats') {
            return (
                <MDBCard style={{ marginTop: '15px', width: '100%' }}>
                    <MDBCardBody>
                        <MDBCardTitle>August, 2024</MDBCardTitle>
                        <Calendar
                            view="month"
                            showNavigation={false}
                            activeStartDate={new Date(2024, 7, 1)}
                            formatDay={(locale, date) => {
                                const dateObj = new Date(date);
                                const units = dailyUnitData[dateObj.toDateString()] ? dailyUnitData[dateObj.toDateString()] : 0;
                                const formatClass = units === 0 ? 'alert-light' : units > 0 ? 'alert-success' : 'alert-danger';
                                return (
                                    <div className={formatClass} style={{ display: 'flex', flexDirection: 'column', padding: '10px 0px' }}>
                                        <span className="day-number">{dateObj.getDate()}</span>
                                        <span className="unit-total">{`${units > 0 ? `+${units.toFixed(1)}` : units.toFixed(1)}u`}</span>
                                    </div>
                                );
                            }}
                        />
                    </MDBCardBody>
                </MDBCard>
            );
        }
        if (contentType === 'vip-stats-yearly') {
            return (
                <MDBCard style={{ marginTop: '15px', width: '100%' }}>
                    <MDBCardBody>
                        <MDBCardTitle>
                            245.23u (2023) <MDBIcon style={{ color: theme.colors.green }} icon="arrow-up" />
                        </MDBCardTitle>
                        <BarChart xAxisLabel="Month" yAxisLabel="Units" yAxisKey="Units" data={calendarData} symbol="u" fixedAmount={1} />
                    </MDBCardBody>
                </MDBCard>
            );
        }
        return <SectionImage src={imgSrc} />;
    };

    return (
        <SectionContainer fluid variant={backgroundColor}>
            <MDBRow style={{ flexDirection: contentSide === 'left' ? 'row-reverse' : 'row' }}>
                <ContentColumn md={12} lg={6} className="mb-3 mb-lg-0">
                    <TopLine>{topLine}</TopLine>
                    <Heading
                        main={headline.includes('Data, algorithms, & experts focused on winning') ? 'true' : 'false'}
                        variant={backgroundColor === 'dark' ? 'light' : 'dark'}
                    >
                        {headline}
                    </Heading>
                    <Description variant={backgroundColor === 'dark' ? 'light' : 'dark'}>{description}</Description>
                    <ButtonContainer>{buttons}</ButtonContainer>
                </ContentColumn>
                <ContentColumn md={12} lg={6}>
                    {generateContent()}
                </ContentColumn>
            </MDBRow>
        </SectionContainer>
    );
};

Section.propTypes = {
    backgroundColor: string,
    topLine: string,
    headline: string,
    description: string,
    buttons: arrayOf(element),
    imgSrc: string,
    contentSide: string,
    contentType: string,
    defaultCardWindow: string,
};

export default Section;
