// Component imports
import React, { useState } from 'react';
import { useElements, useStripe, PaymentElement } from '@stripe/react-stripe-js';
// Styled components
import { StripeFormWrapper, PurchaseButton } from './styles';

const StripePaymentForm = ({ handleSubmit, setError }) => {
    const stripe = useStripe();
    const elements = useElements();
    // Local state
    const [loading, setLoading] = useState(false);

    return (
        <StripeFormWrapper
            onSubmit={async e => {
                e.preventDefault();
                try {
                    setLoading(true);
                    const { paymentIntent } = await stripe.confirmPayment({
                        elements,
                        redirect: 'if_required',
                    });
                    if (paymentIntent?.status === 'succeeded') {
                        const id = paymentIntent.payment_method;
                        await handleSubmit(id);
                    } else {
                        throw new Error(
                            `${
                                paymentIntent?.last_payment_error
                                    ? paymentIntent.last_payment_error
                                    : 'Payment intent is not defined, or the payment did not succeed'
                            }`,
                        );
                    }
                } catch (error) {
                    console.log(`Error creating payment intent\n${error}`);
                    setError(`[Error] Oops something went wrong.  Please refresh and try again.`);
                } finally {
                    setLoading(false);
                }
            }}
        >
            <PaymentElement />
            <PurchaseButton type="submit" disabled={loading}>
                Purchase
            </PurchaseButton>
        </StripeFormWrapper>
    );
};

export default StripePaymentForm;
