// Library imports
import React, { useState } from 'react';
import { shape } from 'prop-types';
import { MDBCard, MDBCol, MDBContainer, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
// Component imports
import LineChart from '../../../components/Graphs/LineChart';
import PieChart from '../../../components/Graphs/PieChart';
import Dropdown from '../../../components/DropDown';
// Styled components
import { useTheme } from 'styled-components';
import { LabelValueListGroup } from '../styles';

const StatGraphs = ({ algoData }) => {
    const theme = useTheme();
    // Local state
    const [activeGraph, setActiveGraph] = useState('moneyline');
    const [activeGraphType, setActiveType] = useState('line');

    const data = algoData[activeGraph];

    const graphDescriptions = {
        moneyline:
            'The win/loss percentage of the Line Prophet generated moneyline.  If our "powerline" shows a team winning and they do it counts as a win on this chart.',
        powerLine:
            'The win/loss percentage of the Line Prophet generated powerline.  If our "powerline" shows a team winning and they do it counts as a win on this chart.',
        spread: "Line Prophet's predicted score gives an alternate spread. If the alternate spread is more accurate than the one Vegas uses that is considered a win for the system.",
        dogs: "If the Line Prophet predicted score shows what Vegas considers a 'dog' winning outright, and then it does, that is considered a win for the system.",
    };

    const generatePieData = () => {
        return [
            {
                x: `Wins: ${Math.trunc((data.wins / (data.wins + data.losses)) * 100)}%`,
                y: Math.trunc((data.wins / (data.wins + data.losses)) * 100),
            },
            {
                x: `Loses: ${Math.trunc((data.losses / (data.wins + data.losses)) * 100)}%`,
                y: Math.trunc((data.losses / (data.wins + data.losses)) * 100),
            },
        ];
    };

    const generateLineData = () => {
        const lineData = [{ id: activeGraph, data: [] }];
        if (!Object.keys(data.byDate).length) return lineData;
        // Take the last 10 days (if available)
        const last10Days = Object.keys(data.byDate).slice(-10);
        // Loop through the last 10 days and populate the lineData
        last10Days.forEach(date => {
            const winPercent = (data.byDate[date].wins / (data.byDate[date].wins + data.byDate[date].losses)) * 100;
            lineData[0].data.push({ x: date, y: Number(winPercent.toFixed(0)) });
        });
        return lineData;
    };

    return (
        /* This padding matches the <Grid /> padding, we wrap in a container because it is not wrapped in parent component.  
        We can not add the padding to the card */
        <MDBContainer fluid className="p-3">
            <MDBCard>
                <MDBRow>
                    <MDBCol sm={12} md={6}>
                        <LabelValueListGroup direction="column">
                            <MDBListGroupItem noBorders>
                                <label>Algorithm Type</label>
                                <Dropdown
                                    menuItems={['moneyline', 'powerLine', 'spread', 'dogs']}
                                    value={activeGraph}
                                    setValue={setActiveGraph}
                                    dropdownStyle={{ width: '250px' }}
                                    toggleStyle={{ border: `1px solid ${theme.colors.borderColor}` }}
                                />
                            </MDBListGroupItem>
                        </LabelValueListGroup>
                        <LabelValueListGroup direction="column">
                            <MDBListGroupItem noBorders>
                                <label>Graph Type</label>
                                <Dropdown
                                    menuItems={['line', 'pie']}
                                    value={activeGraphType}
                                    setValue={setActiveType}
                                    dropdownStyle={{ width: '250px' }}
                                    toggleStyle={{ border: `1px solid ${theme.colors.borderColor}` }}
                                />
                            </MDBListGroupItem>
                        </LabelValueListGroup>
                    </MDBCol>
                    <MDBCol sm={12} md={6}>
                        <LabelValueListGroup direction="column">
                            <MDBListGroupItem noBorders>
                                <label>Record</label>
                                <span>{`(${data.total?.wins}-${data.total?.losses})`}</span>
                            </MDBListGroupItem>
                            <MDBListGroupItem noBorders>
                                <label>Description</label>
                                <span>{graphDescriptions[activeGraph]}</span>
                            </MDBListGroupItem>
                        </LabelValueListGroup>
                    </MDBCol>
                </MDBRow>
                {activeGraphType === 'line' ? <LineChart height="35rem" symbol="%" data={generateLineData()} /> : <PieChart data={generatePieData()} />}
            </MDBCard>
        </MDBContainer>
    );
};

StatGraphs.proptTypes = {
    algoData: shape({}).isRequired,
};

export default StatGraphs;
