import styled from 'styled-components';

export const StyledDetails = styled.div`
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    border: 1px solid #e9ecef;
`;

export const DetailRow = styled.div`
    margin-bottom: 1rem;
`;

export const Label = styled.span`
    display: block;
    font-size: 0.9rem;
    color: #555;
`;

export const Value = styled.span`
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
`;

export const AutoPayWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem;
    background-color: #f8f9fa;
    border-radius: 8px;
    gap: 1rem;
    border: 1px solid #e9ecef;
`;
