// Library imports
import styled from 'styled-components';
import { MDBBtn, MDBCard, MDBCardTitle, MDBContainer } from 'mdb-react-ui-kit';
// Util imports
import { XL_SCREEN_WIDTH } from '../../utils/constants';

export const ContentContainer = styled(MDBContainer)`
    background-color: ${({ theme }) => theme.colors.silver};
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 6rem 10rem;

    @media all and (max-width: ${XL_SCREEN_WIDTH}) {
        padding: 6rem 2rem;
    }
`;

export const LoginFormContainer = styled(MDBCard)`
    padding: 1.5rem;
    max-width: 600px;
    width: 100%;
`;

export const FormTitle = styled(MDBCardTitle)`
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: 900;
    font-size: 1.75rem;
`;

export const HelperButton = styled(MDBBtn)`
    margin-top: 1rem;
    text-align: center;
    width: 100%;
`;

export const RightsText = styled.span`
    text-align: center;
    color: ${({ theme }) => theme.colors.darkGray};
    display: block;
    font-size: 0.85rem;
    margin-top: 1rem;
`;
