// Library imports
import styled from 'styled-components';
import { MDBIcon } from 'mdb-react-ui-kit';

export const FooterContainer = styled.footer`
    background-color: ${({ theme }) => theme.colors.primaryDark};
    color: ${({ theme }) => theme.colors.white};
    padding: 3rem 2rem;
    font-family: ${({ theme }) => theme.fonts.body};
`;

export const FooterTitle = styled.h5`
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 1rem;
    font-family: ${({ theme }) => theme.fonts.heading};
    color: ${({ theme }) => theme.colors.primaryLight};
`;

export const FooterText = styled.p`
    font-weight: 500;
    color: ${({ theme }) => theme.colors.white};
    margin-bottom: 1rem;
    font-size: 1rem;
`;

export const FooterLink = styled.a`
    color: ${({ theme }) => theme.colors.primaryLight};
    text-decoration: none;
    font-weight: 500;
    &:hover {
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const SocialIcon = styled(MDBIcon)`
    font-size: 1.5rem;
    margin-right: 0.75rem;
    color: ${({ theme }) => theme.colors.primaryLight};
    &:hover {
        color: ${({ theme }) => theme.colors.white};
    }
`;

export const FooterBottom = styled.div`
    text-align: center;
    padding-top: 1rem;
    border-top: 1px solid ${({ theme }) => theme.colors.gray};
    margin-top: 2rem;
`;
