// Library imports
import styled from 'styled-components';

export const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
`;

export const Main = styled.div`
    flex: 1;
    max-width: none;
`;
