// Library imports
import React, { useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { MDBCard, MDBCardBody } from 'mdb-react-ui-kit';
// Component imports
import TwoColumnView from '../../../../../components/TwoColumnView';
import NoData from '../../../../../components/NoData';
import TabNav from '../../../../../components/TabNav';
// Styled imports
import { CardTitle, InnerContent } from './styles';

const PublicMoneyPercent = ({ sport, game }) => {
    const [type, setType] = useState(sport === 'mlb' || sport === 'nhl' ? 'moneyline' : 'spread');

    return (
        <InnerContent fluid>
            <TabNav tabs={['moneyline', 'spread']} activeTab={type} setTab={setType} containerStyle={{ marginBottom: '1rem' }} />
            <TwoColumnView
                columnOneJSX={
                    <MDBCard>
                        <CardTitle>Ticket %</CardTitle>
                        <MDBCardBody style={{ height: '165px', padding: 0 }}>
                            {(type === 'moneyline' && !game.awayTeam?.mlPercent && !game.homeTeam?.mlPercent) ||
                            (type === 'spread' && !game.awayTeam?.spreadPercent && !game.homeTeam?.spreadPercent) ? (
                                <NoData
                                    title="No data"
                                    message="There is currently no % data."
                                    headerFontSize={14}
                                    messageFontSize={12}
                                    variant="light"
                                    containerStyle={{ textAlign: 'center' }}
                                />
                            ) : (
                                <ResponsivePie
                                    data={[
                                        {
                                            id: game.homeTeam.abbr,
                                            label: game.homeTeam.abbr,
                                            value: type === 'moneyline' ? game.homeTeam.mlPercent : game.homeTeam.spreadPercent,
                                        },
                                        {
                                            id: game.awayTeam.abbr,
                                            label: game.awayTeam.abbr,
                                            value: type === 'moneyline' ? game.awayTeam.mlPercent : game.awayTeam.spreadPercent,
                                        },
                                    ]}
                                    colors={[game.homeTeam.color, game.awayTeam.color]}
                                    margin={{
                                        top: 5,
                                        right: 2,
                                        bottom: 5,
                                        left: 2,
                                    }}
                                    padAngle={3}
                                    innerRadius={0.2}
                                    borderWidth={2}
                                    enableArcLinkLabels={false}
                                    arcLabel={e => e.id + ' ' + e.value + '%'}
                                    arcLabelsSkipAngle={10}
                                    arcLabelsTextColor="#ffffff"
                                />
                            )}
                        </MDBCardBody>
                    </MDBCard>
                }
                columnTwoJSX={
                    <MDBCard>
                        <CardTitle>Money %</CardTitle>
                        <MDBCardBody style={{ height: '165px', padding: 0 }}>
                            {(type === 'moneyline' && !game.awayTeam?.mlMoneyPercent && !game.homeTeam?.mlMoneyPercent) ||
                            (type === 'spread' && !game.awayTeam?.spreadMoneyPercent && !game.homeTeam?.spreadMoneyPercent) ? (
                                <NoData
                                    title="No data"
                                    message="There is currently no % data."
                                    headerFontSize={14}
                                    messageFontSize={12}
                                    variant="light"
                                    containerStyle={{ textAlign: 'center' }}
                                />
                            ) : (
                                <ResponsivePie
                                    data={[
                                        {
                                            id: game.homeTeam.abbr,
                                            label: game.homeTeam.abbr,
                                            value: type === 'moneyline' ? game.homeTeam.mlMoneyPercent : game.homeTeam.spreadMoneyPercent,
                                        },
                                        {
                                            id: game.awayTeam.abbr,
                                            label: game.awayTeam.abbr,
                                            value: type === 'moneyline' ? game.awayTeam.mlMoneyPercent : game.awayTeam.spreadMoneyPercent,
                                        },
                                    ]}
                                    colors={[game.homeTeam.color, game.awayTeam.color]}
                                    margin={{
                                        top: 5,
                                        right: 2,
                                        bottom: 5,
                                        left: 2,
                                    }}
                                    padAngle={3}
                                    innerRadius={0.2}
                                    borderWidth={2}
                                    enableArcLinkLabels={false}
                                    arcLabel={e => e.id + ' ' + e.value + '%'}
                                    arcLabelsSkipAngle={10}
                                    arcLinkLabelsDiagonalLength={1}
                                    arcLabelsTextColor="#ffffff"
                                />
                            )}
                        </MDBCardBody>
                    </MDBCard>
                }
            />
        </InnerContent>
    );
};

export default PublicMoneyPercent;
