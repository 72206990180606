// Library imports
import React from 'react';
import { MDBBtn, MDBCard, MDBCardBody, MDBCardHeader, MDBIcon } from 'mdb-react-ui-kit';
import { useSelector } from 'react-redux';
// Styled components
import { AccessWrapper, AlertContainer, AlertHeading, InfoRow, InfoLabel, InfoValue } from './styles';

const Access = () => {
    // Redux
    const { data } = useSelector(state => state.register);

    return (
        <AccessWrapper>
            <AlertContainer>
                <AlertHeading>
                    <MDBIcon fas icon="check-circle" className="me-2" />
                    Congratulations!
                </AlertHeading>
                <p>
                    Your purchase of the <b>{data.plan.name}</b> was successful!
                </p>
                <MDBBtn color="light" onClick={() => (window.location.href = '/login')}>
                    Log In
                </MDBBtn>
            </AlertContainer>
            <MDBCard>
                <MDBCardHeader className="text-center fw-bold">What's Next?</MDBCardHeader>
                <MDBCardBody>
                    <InfoRow>
                        <InfoLabel>Email:</InfoLabel>
                        <InfoValue>{data.email}</InfoValue>
                    </InfoRow>
                    <InfoRow>
                        <InfoLabel>Package:</InfoLabel>
                        <InfoValue>{data.plan.name}</InfoValue>
                    </InfoRow>
                    <InfoRow>
                        <InfoLabel>Next Steps:</InfoLabel>
                        <InfoValue>
                            {data.plan.id.includes('access')
                                ? 'Check your email for more information.  Please follow the instructions in the email to complete onboarding.'
                                : 'Check your email in the next few hours for your play(s). Play(s) will be delivered daily.'}
                        </InfoValue>
                    </InfoRow>
                    <InfoRow>
                        <InfoLabel>Assistance:</InfoLabel>
                        <InfoValue>
                            Please reach out to us via email, <b>Twitter</b>, or <b>Instagram</b> for support. You can also email us at{' '}
                            <b>admin@lineprophet.com</b>
                        </InfoValue>
                    </InfoRow>
                </MDBCardBody>
            </MDBCard>
        </AccessWrapper>
    );
};

export default Access;
