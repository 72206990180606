// Library imports
import styled from 'styled-components';

const theme = {
    colors: {
        light: {
            background: '#f8f9fa', // A light gray background for the alert
            color: '#212529', // Dark text color for good contrast
            border: '#d6d8db', // A subtle light gray border to frame the alert
        },
        primary: {
            background: '#dfe7f6',
            color: '#2c58a0',
            border: '#c4d4ef',
        },
        success: {
            background: '#d6f0e0',
            color: '#0d6832',
            border: '#b9e4ca',
        },
        info: {
            background: '#def1f7',
            color: '#1c657d',
            border: '#cce9f2',
        },
        warning: {
            background: '#fbf0da',
            color: '#73510d',
            border: '#f7e3bb',
        },
        danger: {
            background: '#f9e1e5',
            color: '#af233a',
            border: '#f5c9d1',
        },
    },
};

export const AlertWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.65rem;
    border-radius: 0.25rem;
    margin: 1.25rem 0;
    font-size: 0.75rem;
    position: relative;
    background-color: ${({ variant }) => theme.colors[variant].background};
    color: ${({ variant }) => theme.colors[variant].color};
    border: 0px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

    .icon {
        margin-right: 1rem;
    }
`;

export const CloseButton = styled.button`
    background: transparent;
    border: none;
    color: inherit;
    font-size: 1.5rem;
    cursor: pointer;
    position: absolute;
    right: 0.5rem;

    &:hover {
        color: #f5f5f5;
    }
`;
