// Library imports
import styled from 'styled-components';
import { MDBBtn, MDBCard, MDBCardTitle } from 'mdb-react-ui-kit';

export const RegisterCard = styled(MDBCard)`
    width: 100%;
    max-width: 990px;
    padding: 15px;
    background-color: #fdfdfd;
    margin: 3% auto;
`;

export const StageTitle = styled(MDBCardTitle)`
    font-weight: bold !important;
`;

export const StageBackButton = styled(MDBBtn)`
    position: absolute;
    right: 15px;
    padding: 0px !important;
`;

export const ButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px;
`;
