// Library and Component imports
import React, { useState } from 'react';
import ReactJson from 'react-json-view';
import { Row, Col, Button, Alert } from 'react-bootstrap';
// Component imports
import LoadingSpinner from '../../../components/LoadingSpinner';
// Controller imports
import { updateMatchupData, updateTeamData, updateLeagueData, updatePropData } from '../../../controllers/UpdateDataController';
// Util imports
import { ACTIVE_SPORTS } from '../../../utils/constants';
// CSS imports
import '../../../css/APIAdmin.css';
// Styled components
import { ProfileContentContainer } from '../styles';

const APIAdmin = () => {
    const [data, setData] = useState({});
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const allData = async (type = 'all') => {
        try {
            setLoading(true);
            for (let sport of ACTIVE_SPORTS) {
                if (type === 'games') {
                    await updateMatchupData(sport);
                    continue;
                }
                if (type === 'teams') {
                    await updateTeamData(sport);
                    continue;
                }
                if (type === 'props') {
                    await updatePropData(sport);
                    continue;
                }
                if (type === 'leagues') {
                    await updateLeagueData(sport);
                    continue;
                }
                await updateLeagueData(sport);
                await updateTeamData(sport);
                await updateMatchupData(sport);
            }
            setError('');
        } catch (error) {
            console.log(error.message);
            setError('Error updating matchup data');
        } finally {
            setData([]);
            setLoading(false);
        }
    };

    const gameData = async e => {
        var sport = e.currentTarget.id;
        try {
            setLoading(true);
            const res = await updateMatchupData(sport);
            setData(res);
            setError('');
        } catch (error) {
            console.log(error.message);
            setError('Error updating matchup data');
        } finally {
            setLoading(false);
        }
    };

    const teamData = async e => {
        var sport = e.currentTarget.id;
        try {
            setLoading(true);
            const res = await updateTeamData(sport);
            setData(res);
            setError('');
        } catch (error) {
            console.log(error.message);
            setError('Error updating team data');
        } finally {
            setLoading(false);
        }
    };

    const leagueData = async e => {
        var sport = e.currentTarget.id;
        try {
            setLoading(true);
            const res = await updateLeagueData(sport);
            setData(res);
            setError('');
        } catch (error) {
            console.log(error.message);
            setError('Error updating league data');
        } finally {
            setLoading(false);
        }
    };

    const propData = async e => {
        var sport = e.currentTarget.id;
        try {
            setLoading(true);
            const res = await updatePropData(sport);
            setData(res);
            setError('');
        } catch (error) {
            console.log(error.message);
            setError('Error updating team data');
        } finally {
            setLoading(false);
        }
    };

    return (
        <ProfileContentContainer>
            <Row>
                <Col className="api-update-buttons-wrapper" sm={12} md={12} lg={4}>
                    <div>
                        <h3>Active Sports</h3>
                        <Button onClick={() => allData('games')} variant="primary">
                            Games
                        </Button>
                        <Button onClick={() => allData('teams')} variant="primary">
                            Teams
                        </Button>
                        <Button onClick={() => allData('props')} variant="primary">
                            Props
                        </Button>
                        <Button onClick={() => allData('leagues')} variant="primary">
                            Leagues
                        </Button>
                        <Button onClick={() => allData('all')} variant="primary">
                            All
                        </Button>
                    </div>
                    {ACTIVE_SPORTS.map(sport => {
                        return (
                            <div style={{ marginTop: '15px' }}>
                                <h3>{String(sport).toUpperCase()}</h3>
                                <Button onClick={gameData} id={sport} variant="primary">
                                    Games
                                </Button>
                                <Button onClick={teamData} id={sport} variant="primary">
                                    Teams
                                </Button>
                                <Button onClick={propData} id={sport} variant="primary">
                                    Props
                                </Button>
                                <Button onClick={leagueData} id={sport} variant="primary">
                                    League
                                </Button>
                            </div>
                        );
                    })}
                </Col>
                <Col sm={12} md={12} lg={8}>
                    {loading ? <LoadingSpinner /> : error === '' ? <ReactJson collapsed={true} src={data} /> : <Alert variant="danger">{error}</Alert>}
                </Col>
            </Row>
        </ProfileContentContainer>
    );
};

export default APIAdmin;
