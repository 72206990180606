// Library imports
import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Nav } from 'react-bootstrap';
// Component imports
import NoData from '../../../../../components/NoData';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import CompareList from '../../../../../components/CompareList';
// Util imports
import { insert } from '../../../../../utils';
import { keyFactorData } from './KeyFactors/utils';
// Styled components
import { InnerContent } from './styles';

const Pitchers = ({ sport, game }) => {
    const [tab, setTab] = useState(game.awayTeam.pitcher && game.homeTeam.pitcher ? 'pitcher' : 'pitching');
    const [dataFields, setDataFields] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setLoading(true);
                const html = await keyFactorData(sport, game.awayTeam, game.homeTeam, tab);
                if (tab === 'pitcher') {
                    const awayPitcherEra = game.awayTeam.pitcher?.splits?.away?.era ? game.awayTeam.pitcher.splits.away.era.toFixed(2) : 0.0;
                    const homePitcherEra = game.homeTeam.pitcher?.splits?.home?.era ? game.homeTeam.pitcher.splits.home.era.toFixed(2) : 0.0;
                    const awayPitcherWalks = game.awayTeam.pitcher?.splits?.away?.walks ? game.awayTeam.pitcher.splits.away.walks : 0.0;
                    const homePitcherWalks = game.homeTeam.pitcher?.splits?.home?.walks ? game.homeTeam.pitcher.splits.home.walks : 0.0;
                    html[0] = insert(html[0], 1, {
                        value: awayPitcherEra,
                        class: awayPitcherEra === homePitcherEra ? 'light' : awayPitcherEra < homePitcherEra ? 'success' : 'danger',
                    });
                    html[0] = insert(html[0], 2, {
                        value: awayPitcherWalks,
                        class: awayPitcherWalks === homePitcherWalks ? 'light' : awayPitcherWalks < homePitcherWalks ? 'success' : 'danger',
                    });
                    html[1] = insert(html[1], 1, {
                        value: homePitcherEra,
                        class: awayPitcherEra === homePitcherEra ? 'light' : homePitcherEra < awayPitcherEra ? 'success' : 'danger',
                    });
                    html[1] = insert(html[1], 2, {
                        value: homePitcherWalks,
                        class: awayPitcherWalks === homePitcherWalks ? 'light' : homePitcherWalks < awayPitcherWalks ? 'success' : 'danger',
                    });
                    html[2] = insert(html[2], 1, 'A/H ERA');
                    html[2] = insert(html[2], 2, 'A/H BB');
                }
                setDataFields(html);
            } catch (error) {
                console.log(`Error getting key factor objects for: ${sport}\n${error.message}`);
            } finally {
                setLoading(false);
            }
        }
        fetchData();
    }, [tab]);

    if (!game.awayTeam.pitcher || !game.homeTeam.pitcher) {
        return <NoData title="No data" message="There is currently no pitching data for this game.  Check back soon." />;
    }
    return (
        <InnerContent>
            <Container className="mt-2" fluid>
                <Row>
                    <Col>
                        <Nav variant="tabs" className="mb-2" activeKey={tab}>
                            <Nav.Item>
                                <Nav.Link onClick={e => setTab('pitcher')} eventKey="pitcher">
                                    Starters
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link onClick={e => setTab('pitching')} eventKey="pitching">
                                    Team
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                {loading ? (
                    <LoadingSpinner />
                ) : (
                    <CompareList
                        labels={dataFields[2]}
                        dataSetOneTitle={tab === 'pitcher' ? `${game.awayTeam.pitcher.firstName} ${game.awayTeam.pitcher.lastName}` : game.awayTeam.teamName}
                        dataSetOneTitleColor={game.awayTeam.color}
                        dataSetOne={dataFields[0]}
                        dataSetTwoTitle={tab === 'pitcher' ? `${game.homeTeam.pitcher.firstName} ${game.homeTeam.pitcher.lastName}` : game.homeTeam.teamName}
                        dataSetTwoTitleColor={game.homeTeam.color}
                        dataSetTwo={dataFields[1]}
                    />
                )}
            </Container>
        </InnerContent>
    );
};

export default Pitchers;
