// Library imports
import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { useTheme } from 'styled-components';
import { arrayOf, number, shape, string } from 'prop-types';
// Constant imports
import { customTheme } from './constants';

export const BarChart = ({ data = [], xAxisLabel = '', yAxisLabel = '', yAxisKey, symbol, fixedAmount = 0 }) => {
    const theme = useTheme();

    // Calculate the minimum and maximum values for the y-axis
    const minValue = Math.min(...data.map(d => d[yAxisKey]));
    const maxValue = Math.max(...data.map(d => d[yAxisKey]));

    return (
        <div style={{ height: '25rem' }}>
            <ResponsiveBar
                data={data}
                padding={0.3} // Adjust bar width
                margin={{ top: 20, right: 20, bottom: 50, left: 50 }} // Adjust margins as needed
                layout="vertical" // You can change this to 'horizontal' if you want horizontal bars
                colors={theme.colors.primary} // Use a color scheme
                indexBy={xAxisLabel}
                keys={[yAxisKey]}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 10,
                    tickRotation: -35, // Adjust tick rotation if needed
                    legend: '',
                    legendOffset: 40,
                    legendPosition: 'middle',
                }}
                axisLeft={{
                    tickSize: 10,
                    tickPadding: 5,
                    legend: yAxisLabel,
                    legendOffset: -40,
                    legendPosition: 'middle',
                    domain: [minValue, maxValue],
                }}
                label={d => <tspan y={-10}>{`${d.value.toFixed(2)}${symbol}`}</tspan>}
                theme={customTheme} // Custom theme if needed
                tooltip={({ id, value, color }) => (
                    <div
                        style={{
                            padding: '5px 10px',
                            color: '#333333',
                            background: '#ffffff',
                            border: `1px solid ${color}`,
                        }}
                    >
                        <strong>{id}: </strong> {value.toFixed(fixedAmount)}%
                    </div>
                )}
            />
        </div>
    );
};

BarChart.propTypes = {
    data: arrayOf(shape({})),
    xAxisLabel: string.isRequired,
    yAxisLabel: string.isRequired,
    yAxisKey: string.isRequired,
    symbol: string,
    fixedAmount: number,
};

export default BarChart;
