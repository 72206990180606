// Library imports
import React from 'react';
import { node } from 'prop-types';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';

const TwoColumnView = ({ columnOneJSX, columnTwoJSX }) => {
    return (
        <MDBRow className="gx-1">
            <MDBCol size={6}>{columnOneJSX}</MDBCol>
            <MDBCol size={6}>{columnTwoJSX}</MDBCol>
        </MDBRow>
    );
};

TwoColumnView.propTypes = {
    columnOneJSX: node,
    columnTwoJSX: node,
};

export default TwoColumnView;
