// Library imports
import React from 'react';
import { MDBIcon, MDBTabs, MDBTabsItem, MDBTabsLink } from 'mdb-react-ui-kit';
import { bool, func, string } from 'prop-types';
import { useTheme } from 'styled-components';

// Define the SportsNav component
const SportsNav = ({ click, all = true, demo = false, activeItem, inActiveBgColor = '#fff' }) => {
    const theme = useTheme();

    const navItems = [
        { id: 'all', label: 'All', icon: 'fa-solid fa-list', show: all },
        { id: 'mlb', label: 'MLB', icon: 'fa-solid fa-baseball', show: true },
        { id: 'ncaa-baseball', label: 'NCAA Baseball', icon: 'fa-solid fa-baseball-ball', show: !demo, fontSize: '16px' },
        { id: 'nfl', label: 'NFL', icon: 'fa-solid fa-football', show: true },
        { id: 'cfb', label: 'CFB', icon: 'fa-solid fa-football', disabled: demo },
        { id: 'nba', label: 'NBA', icon: 'fa-solid fa-basketball-ball', show: true },
        { id: 'cbb', label: 'CBB', icon: 'fa-solid fa-basketball', show: true },
        { id: 'nhl', label: 'NHL', icon: 'fa-solid fa-hockey-puck', show: true },
    ];

    return (
        <MDBTabs pills className="mb-3">
            {navItems.map(({ id, label, show = true, fontSize = '16px', disabled = false, icon }) =>
                show ? (
                    <MDBTabsItem key={id}>
                        <MDBTabsLink
                            id={id}
                            active={activeItem === id}
                            onClick={e => click(e.currentTarget.id)}
                            disabled={disabled}
                            style={{
                                fontSize,
                                padding: '0.75rem 1.5rem',
                                border: activeItem === id ? `1px solid ${theme.colors.primary}` : `1px solid ${theme.colors.gray}`,
                                backgroundColor: activeItem === id ? theme.colors.primary : inActiveBgColor,
                                color: activeItem === id ? theme.colors.white : theme.colors.primary,
                                fontWeight: '600',
                                transition: 'all 0.3s ease',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <MDBIcon icon={icon} className="me-2" /> {label}
                        </MDBTabsLink>
                    </MDBTabsItem>
                ) : null,
            )}
        </MDBTabs>
    );
};

SportsNav.propTypes = {
    click: func.isRequired,
    all: bool,
    demo: bool,
    activeItem: string.isRequired,
    inActiveBgColor: string,
};

export default SportsNav;
