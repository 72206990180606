export const REVIEWS = [
    {
        name: '@LawlessGambler',
        title: 'Great Platform!',
        quote: '...Just wanted to give you some praise on the work you put in. I found your account on here in April and in my two Vegas trips since, have come out plus money!',
        rating: 5,
    },
    {
        name: '@Adam_Aguilar95r',
        title: 'Highly Recommend!',
        quote: "Join the site, hes killing it and he's got the data everyone wants. It's a great investment for sports bettors",
        rating: 4,
    },
    {
        name: '@rkennedy2622',
        title: 'Awesome Service',
        quote: 'Glad I have a lifetime membership. Already referred a handful of friends to the site. Too much info available for you not to buy a subscription.',
        rating: 5,
    },
    {
        name: '@tunacancrew',
        title: 'Best in the Business',
        quote: "VIPs got this [play] last night. If you don't subscribe, you shouldn't bet on sports",
        rating: 4,
    },
    { name: 'Daniel Kim', title: 'Impressed!', quote: "I've been following the models on this platform and have seen great results!", rating: 4 },
    { name: 'Rachel Adams', title: 'Fantastic!', quote: "PicksLocker gives me access to exclusive picks that I can't find anywhere else.", rating: 5 },
];
