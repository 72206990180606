// Library imports
import styled from 'styled-components';
import { MDBBtn } from 'mdb-react-ui-kit';

export const StripeFormWrapper = styled.form`
    border: 1px solid ${({ theme }) => theme.colors.gray};
    padding: 1.5rem;
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export const PurchaseButton = styled(MDBBtn)`
    width: 100%;
    margin-top: 1.5rem;
    padding: 0.75rem;
    font-size: 1rem;
    background-color: ${({ theme }) => theme.colors.primary};
    &:disabled {
        background-color: ${({ theme }) => theme.colors.gray};
        cursor: not-allowed;
    }
`;
