// Library imports
import React, { useState } from 'react';
// Component imports
import OddsBoard from './OddsBoard';
import Confirm from './Confirm';
// Util imports
import { PICK_OBJ } from '../../../../../../utils/constants';
// Styled components
import { InnerContent } from '../styles';

const PickOdds = ({ game }) => {
    // Local state
    const [view, setView] = useState('odds');
    const [pickData, setPickData] = useState({ ...PICK_OBJ });

    return (
        <InnerContent fluid>
            {view === 'confirm' ? (
                <Confirm game={game} setView={setView} pickData={pickData} setPickData={setPickData} />
            ) : (
                <OddsBoard game={game} setView={setView} pickData={pickData} setPickData={setPickData} />
            )}
        </InnerContent>
    );
};

export default PickOdds;
