export const HOW_IT_WORKS = [
    {
        title: 'Select Package',
        description:
            'Choose a subscription package that suits your needs. Each package grants access to exclusive Line Prophet picks, data, and community features tailored to help you succeed.',
        icon: 'fa-solid fa-gift', // Represents selecting a package or gift
    },
    {
        title: 'Create an Account',
        description:
            'Create an account to manage your subscription, track your picks, and access premium content. Your account will serve as your gateway to all Line Prophet services.',
        icon: 'fa-solid fa-user-plus', // User icon for account creation
    },
    {
        title: 'Login to View Models, Picks, and Data',
        description:
            'Log in to access detailed sports models, expert picks, and comprehensive data. This step allows you to unlock all the tools and insights to enhance your betting strategy.',
        icon: 'fa-solid fa-sign-in-alt', // Login icon
    },
    {
        title: 'Join VIP Twitter and Discord',
        description:
            'Gain exclusive access to our VIP community on Twitter and Discord. Stay up to date with real-time updates, expert discussions, and insider tips to help you stay ahead.',
        icon: 'x-twitter', // Represents Twitter, which is used for VIP access
    },
];

export const FEATURE_LIST = {
    access: [
        {
            title: 'Expert Picks',
            description:
                'Get access to expert picks from our team of sports betting professionals.  Our experts have years of experience and are here to help you win.',
            icon: 'thumbs-up',
        },
        {
            title: 'Model Predictions',
            description:
                'Get access to our proprietary sports models that have been developed by our team of experts.  Our models provide you with the best data and insights to help you win.',
            icon: 'calculator',
        },
        {
            title: 'Matchup Insights',
            description:
                'Our team of experts has put together a unique set of matchup insights for each game.  Dive into the stats and trends to help you make the best decision.',
            icon: 'chart-line',
        },
        {
            title: 'VIP Twitter',
            description:
                'Get access to our VIP Twitter feed where our experts share their plays in real time.  Never miss a play with our automatic tweet notifications.',
            icon: 'x-twitter',
        },
        {
            title: 'Private Discord',
            description:
                'Join our private Discord server where our experts and community are there to answer all of your questions.  Get access to exclusive content and insights.',
            icon: 'comments',
        },
    ],
    picks: [
        {
            title: 'Expert Picks',
            description:
                'Get access to expert picks from our team of sports betting professionals.  Our experts have years of experience and are here to help you win.',
            icon: 'thumbs-up',
        },
        {
            title: 'Sent via Email',
            description: 'Get our expert picks sent directly to your email inbox.  Never miss a play with our automatic email notifications.',
            icon: 'thumbs-up',
        },
    ],
};
