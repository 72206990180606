// Component imports</div>
import React from 'react';
import { MDBCard, MDBCardBody, MDBCardText, MDBCardTitle, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { useTheme } from 'styled-components';
// Styled components
import FeatureSection from '../FeatureSection';
// Constant imports
import { REVIEWS } from './constants';

const Testimonials = () => {
    const theme = useTheme();

    return (
        <FeatureSection
            bgColor={theme.colors.white}
            title="What are our Customers Saying?"
            subTitle="Don't take it from us. Listen to what our verified users have to say!"
        >
            <MDBRow>
                {REVIEWS.map((review, index) => (
                    <MDBCol md="4" className="mb-4" key={index}>
                        <MDBCard>
                            <MDBCardBody>
                                <MDBCardTitle style={{ fontFamily: theme.fonts.heading, fontWeight: '700', fontSize: '1.25rem' }}>{review.title}</MDBCardTitle>
                                <MDBCardText style={{ fontFamily: theme.fonts.body, fontWeight: '500', fontSize: '1.15rem' }}>
                                    <q>{review.quote}</q>
                                </MDBCardText>
                                <MDBCardText className="text-muted" style={{ fontFamily: theme.fonts.body, fontWeight: '500', fontSize: '1rem' }}>
                                    - {review.name}
                                </MDBCardText>
                                <div className="d-flex justify-content-center">
                                    {[...Array(5)].map((_, i) => (
                                        <MDBIcon key={i} fas icon="star" style={{ color: i < review.rating ? theme.colors.green : theme.colors.gray }} />
                                    ))}
                                </div>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                ))}
            </MDBRow>
        </FeatureSection>
    );
};

export default Testimonials;
