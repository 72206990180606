// Library imports
import React, { useState } from 'react';
import { MDBCard, MDBListGroupItem } from 'mdb-react-ui-kit';
// Component imports
import TwoColumnView from '../../../../../components/TwoColumnView';
import NoData from '../../../../../components/NoData';
import TabNav from '../../../../../components/TabNav';
import PillNav from '../../../../../components/PillNav';
// Util imports
import { addPlusSign, formatOdd, shortName } from '../../../../../utils';
// Styled components
import { HighLightedField, InnerContent, CardTitle } from './styles';
import { LabelValueListGroup } from '../../../styles';

const LineData = ({ game, sport }) => {
    const [type, setType] = useState(sport === 'mlb' || sport === 'nhl' ? 'moneyline' : 'spread');
    const [bookType, setBookType] = useState(sport === 'mlb' || sport === 'nhl' ? 'moneylines' : 'spreads');

    const calcAverageMoneyline = team => {
        if (!team.odds) {
            return 0;
        }
        if (team.odds.fullGame.moneyline > 0) {
            return formatOdd(team.averageMoneylines?.dog.avgML.toFixed(0), 'moneyline');
        } else {
            return formatOdd(team.averageMoneylines?.favorite.avgML.toFixed(0), 'moneyline');
        }
    };

    const calcOddMovementVariant = team => {
        if (type === 'moneyline') {
            return team.odds?.fullGame?.moneyline > team.openMoneyLine ? 'success' : team.odds?.fullGame?.moneyline < team.openMoneyLine ? 'danger' : 'light';
        } else {
            const openingSpread = team.odds?.fullGame?.spread < 0 ? game.openingSpread * -1 : game.openingSpread;
            return team.odds?.fullGame?.spread > openingSpread ? 'success' : team.odds?.fullGame?.spread < openingSpread ? 'danger' : 'light';
        }
    };

    const createLineDataJSX = team => {
        return (
            <MDBCard>
                <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                <LabelValueListGroup direction="row">
                    <MDBListGroupItem>
                        <label>Open</label>
                        <HighLightedField variant="light">
                            {type === 'moneyline'
                                ? formatOdd(team.openMoneyLine, 'moneyline')
                                : formatOdd(team.odds?.fullGame?.spread < 0 ? game.openingSpread * -1 : game.openingSpread)}
                        </HighLightedField>
                    </MDBListGroupItem>
                    <MDBListGroupItem>
                        <label>Now</label>
                        <HighLightedField variant={calcOddMovementVariant(team)}>
                            {type === 'moneyline' ? formatOdd(team.odds?.fullGame?.moneyline, 'moneyline') : formatOdd(team.odds?.fullGame?.spread)}
                        </HighLightedField>
                    </MDBListGroupItem>
                    {type === 'moneyline' && (
                        <MDBListGroupItem>
                            <label>Avg.</label>
                            <HighLightedField variant="light">{calcAverageMoneyline(team)}</HighLightedField>
                        </MDBListGroupItem>
                    )}
                </LabelValueListGroup>
            </MDBCard>
        );
    };

    const bookDataJSX = team => {
        const lineType = team.teamId === game.awayTeam.teamId ? 'awayLine' : 'homeLine';
        const bookData = game.bookData[bookType].filter(book => !isNaN(book[lineType])).map(book => book[lineType]);
        const areAllValuesSame = bookData.every(value => value === bookData[0]);
        return (
            <MDBCard>
                <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                <LabelValueListGroup direction="row" coloredList="true">
                    {game.bookData[bookType] &&
                        game.bookData[bookType].map((book, i) => {
                            var color = 'transparent';
                            if (!areAllValuesSame) {
                                if (book[lineType] === Math.min(...bookData)) {
                                    color = 'success';
                                } else if (book[lineType] === Math.max(...bookData)) {
                                    color = 'danger';
                                }
                            }
                            return (
                                <MDBListGroupItem key={`${book.label}-${i}`} color={color}>
                                    <label>{book.label}</label>
                                    <span>{addPlusSign(book[lineType])}</span>
                                </MDBListGroupItem>
                            );
                        })}
                </LabelValueListGroup>
            </MDBCard>
        );
    };

    return (
        <InnerContent fluid>
            <TabNav tabs={['moneyline', 'spread', 'books']} activeTab={type} setTab={setType} containerStyle={{ marginBottom: '1rem' }} />
            {(type === 'books' && !game.bookData?.[bookType]) || (type === 'moneyline' && !game.awayTeam.odds?.fullGame?.moneyline) ? (
                <NoData title="No data" message="There is currently no line data for this game.  Check back soon." />
            ) : type === 'moneyline' || type === 'spread' ? (
                <TwoColumnView columnOneJSX={createLineDataJSX(game.awayTeam)} columnTwoJSX={createLineDataJSX(game.homeTeam)} />
            ) : (
                <div>
                    <PillNav
                        tabs={['moneylines', 'spreads']}
                        setActiveTab={setBookType}
                        activeTab={bookType}
                        containerStyle={{ maxWidth: '15rem', margin: '0 auto 1rem auto' }}
                    />
                    <TwoColumnView columnOneJSX={bookDataJSX(game.awayTeam)} columnTwoJSX={bookDataJSX(game.homeTeam)} />
                </div>
            )}
        </InnerContent>
    );
};

export default LineData;
