// Library imports
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { func, shape } from 'prop-types';
import { MDBBtn, MDBCard } from 'mdb-react-ui-kit';
import { useForm } from 'react-hook-form';
// Component imports
import TextInput from '../../../../../../components/InputLibrary/TextInput';
// Controller imports
import { addPick } from '../../../../../../controllers/UserController';
// Util imports
import { createPickId } from '../../../../../../utils';
import { PICK_OBJ } from '../../../../../../utils/constants';
// Styled components
import { CardTitle } from '../styles';
import { FormWrapper } from './styles';
// Action imports
import { setUser } from '../../../../../../store/slices/userSlice';
import { addLoader, removeLoader } from '../../../../../../store/slices/appSlice';

const Confirm = ({ game, setView, pickData, setPickData }) => {
    // Redux
    const { user } = useSelector(state => state.user);
    const { sport, loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();

    const {
        handleSubmit,
        control,
        formState: { isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            pickValue: pickData.pickValue,
            pickJuice: pickData.pickJuice,
            units: pickData.units,
        },
    });

    const resetPickData = () => {
        setPickData({ ...PICK_OBJ });
        setView('odds');
    };

    const handleAddPick = async data => {
        try {
            dispatch(addLoader('add-pick'));
            const newPicks = await addPick({
                ...pickData,
                ...data,
                userId: user.uid,
                sport: sport,
                gameId: game.gameId,
                pickId: createPickId(),
                gameTime: game.dateTime,
            });
            dispatch(setUser({ picks: newPicks }));
            resetPickData();
        } catch (error) {
            console.log(`Error saving pick\n${error}`);
        } finally {
            dispatch(removeLoader('add-pick'));
        }
    };

    return (
        <MDBCard>
            <CardTitle>{pickData.teamName}</CardTitle>
            <MDBBtn style={{ position: 'absolute', right: 0 }} color="link" onClick={resetPickData}>
                Cancel
            </MDBBtn>
            <FormWrapper onSubmit={handleSubmit(handleAddPick)}>
                <TextInput
                    name="pickValue"
                    control={control}
                    rules={{
                        validate: {
                            isNumber: value => {
                                if (isNaN(value) || value === '') {
                                    return 'Please enter a valid number';
                                }
                                return true;
                            },
                        },
                    }}
                    required={true}
                    label="Value"
                />
                <TextInput
                    name="pickJuice"
                    disabled={pickData.pickType === 'moneyline'}
                    control={control}
                    rules={{
                        validate: {
                            isNumber: value => {
                                if (isNaN(value) || value === '') {
                                    return 'Please enter a valid number';
                                }
                                return true;
                            },
                        },
                    }}
                    required={true}
                    label="Juice"
                />
                <TextInput
                    name="units"
                    control={control}
                    rules={{
                        validate: {
                            isNumber: value => {
                                if (isNaN(value) || value === '') {
                                    return 'Please enter a valid number';
                                }
                                return true;
                            },
                        },
                    }}
                    required={true}
                    label="Units"
                />
                <MDBBtn type="submit" color="success" disabled={!isValid || loaders.includes('add-pick')}>
                    Confirm
                </MDBBtn>
            </FormWrapper>
        </MDBCard>
    );
};

Confirm.propTypes = {
    game: shape({}).isRequired,
    setView: func.isRequired,
    pickData: shape({}).isRequired,
    setPickData: func.isRequired,
};

export default Confirm;
