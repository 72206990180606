// Library imports
import React from 'react';
import { BeatLoader } from 'react-spinners';
import { MDBBtn } from 'mdb-react-ui-kit';
import { func, shape, string } from 'prop-types';
import { useTheme } from 'styled-components';
// Component imports
import { SpinnerWrapper } from './styles';

const LoadingSpinner = ({ text = '', onClick = undefined, containerStyle = {} }) => {
    const theme = useTheme();

    return (
        <SpinnerWrapper style={{ ...containerStyle }}>
            <BeatLoader color={theme.colors.primary} loading={true} />
            {text && <h5 style={{ textAlign: 'center', margin: '15px 0px' }}>{text}</h5>}
            {onClick && (
                <MDBBtn color="light" onClick={onClick}>
                    Cancel
                </MDBBtn>
            )}
        </SpinnerWrapper>
    );
};

LoadingSpinner.propTypes = {
    text: string,
    onClick: func,
    containerStyle: shape({}),
};

export default LoadingSpinner;
