export const PROFILE_PAGES = [
    {
        title: 'Picks',
        id: 'picks',
        free: true,
        fullScreen: false,
        icon: 'fas fa-bolt', // Icon for something dynamic or quick, like picks
    },
    {
        title: 'Portfolio',
        id: 'investor',
        free: false,
        fullScreen: false,
        icon: 'fas fa-briefcase', // A briefcase symbolizes portfolio or investments
    },
    {
        title: 'Settings',
        id: 'settings',
        free: true,
        fullScreen: false,
        icon: 'fas fa-cogs', // Settings with gears
    },
    {
        title: 'Create Pick',
        id: 'create-pick',
        free: false,
        fullScreen: false,
        admin: true,
        icon: 'fas fa-plus-circle', // Icon for creating something new (Add)
    },
    {
        title: 'VIPs',
        id: 'vips',
        free: false,
        fullScreen: false,
        admin: true,
        icon: 'fas fa-star', // Stars are commonly used to represent VIPs or special users
    },
    {
        title: 'API',
        id: 'api',
        free: false,
        fullScreen: false,
        admin: true,
        icon: 'fas fa-plug', // Plug icon for APIs or connections
    },
    {
        title: 'Data',
        id: 'data',
        free: false,
        fullScreen: false,
        admin: true,
        icon: 'fas fa-database', // Represents data storage or management
    },
    {
        title: 'Logout',
        id: 'logout',
        free: true,
        fullScreen: false,
        icon: 'fas fa-sign-out-alt', // Icon for logout (sign-out)
    },
];
