import styled from 'styled-components';

export const TotalContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
    gap: 0.35rem;
`;

export const TotalLabel = styled.span`
    font-size: 1.25rem;
`;

export const TotalText = styled.span`
    font-family: ${({ theme }) => theme.fonts.heading};
    font-size: 2.25rem;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 700;
`;

export const ChartContainer = styled.div`
    height: 7rem;
`;
