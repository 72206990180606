// Library imports
import React, { useState } from 'react';
// Component imports
import ManageUserData from './ManageUserData';
import ManagePackage from './ManagePackage';
import TabNav from '../../../components/TabNav';
// Styled components
import { ProfileContentContainer } from '../styles';

const Settings = () => {
    const [subpage, setSubpage] = useState('subscription');

    return (
        <ProfileContentContainer>
            <TabNav tabs={['subscription', 'profile']} activeTab={subpage} setTab={setSubpage} containerStyle={{ marginBottom: '1rem' }} />
            {subpage === 'subscription' && <ManagePackage />}
            {subpage === 'profile' && <ManageUserData />}
        </ProfileContentContainer>
    );
};

export default Settings;
