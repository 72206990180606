// Library imports
import React from 'react';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
// Component imports
import VIPDrawer from './drawers/VIPDrawer';
import StandingsDrawer from './drawers/StandingsDrawer';
// Styled components
import { DrawerHeader, DrawerTitle, DrawerContainer } from './styles';

export const Drawer = ({ closeDrawers, id, title }) => {
    return (
        <DrawerContainer initial={{ translateX: '100%' }} animate={{ translateX: 0 }} transition={{ ease: 'easeOut', duration: 0.75 }} className={`${id}`}>
            <DrawerHeader>
                <DrawerTitle>{title}</DrawerTitle>
                <MDBBtn color="link" onClick={closeDrawers}>
                    <MDBIcon size="2x" icon="arrow-right" />
                </MDBBtn>
            </DrawerHeader>
            {id === 'vip' && <VIPDrawer />}
            {id === 'standings' && <StandingsDrawer />}
        </DrawerContainer>
    );
};
