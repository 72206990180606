// Library imports
import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Form, Button, Card, FormSelect, Alert, Modal, ListGroup } from 'react-bootstrap';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
// Component imports
import Table from '../../../../components/TableV2';
import LoadingSpinner from '../../../../components/LoadingSpinner';
// Controller imports
import { updateReciept } from '../../../../controllers/PaymentController';
import { sendEmail, deleteProfile } from '../../../../controllers/UserController';
// Styled components
import { ProfileContentContainer } from '../../styles';
import { Picker, PickerContainer } from './styles';
import { LabelValueListGroup } from '../../../App/styles';
// Util imports
import { EMAIL_TEMPLATES } from '../../../../utils/constants';
import { scrollToTop } from '../../../../utils';
// Action imports
import { fetchUsers } from '../../../../store/slices/appSlice';

const vipFilters = {
    pickpackages: {
        id: 'pickpackages',
        label: 'Pick Packages',
        filter: rec =>
            rec.status !== 'complete' &&
            (rec.items[rec.items.length - 1].id === '5050' ||
                rec.items[rec.items.length - 1].id === 'daily' ||
                rec.items[rec.items.length - 1].id === 'play-of-day' ||
                rec.items[rec.items.length - 1].id === 'daily-parlay'),
    },
    'free-access': {
        id: 'free-access',
        label: 'Free Accounts',
        filter: rec => rec.type === 'free-account',
    },
    'daily-access': {
        id: 'daily-access',
        label: 'Daily Members',
        filter: rec => rec.items[rec.items.length - 1].id === 'daily-access' && rec.type !== 'free-account',
    },
    'weekly-access': {
        id: 'weekly-access',
        label: 'Weekly Members',
        filter: rec => rec.items[rec.items.length - 1].id === 'weekly-access' && rec.type !== 'free-account',
    },
    'monthly-access': {
        id: 'monthly-access',
        label: 'Monthly Members',
        filter: rec => rec.items[rec.items.length - 1].id === 'monthly-access' && rec.type !== 'free-account',
    },
    'yearly-access': {
        id: 'yearly-access',
        label: 'Yearly Members',
        filter: rec => rec.items[rec.items.length - 1].id === 'yearly-access' && rec.type !== 'free-account',
    },
    'lifetime-access': {
        id: 'lifetime-access',
        label: 'Lifetime Members',
        filter: rec => rec.items[rec.items.length - 1].id === 'access' && rec.type !== 'free-account',
    },
    complete: {
        id: 'complete',
        label: 'Completed Packages',
        filter: rec => rec.status === 'complete',
    },
    expired: {
        id: 'expired',
        label: 'Expired Packages',
        filter: rec => (rec.endDate && rec.endDate !== 'never' ? moment(rec.endDate).isSameOrBefore(moment()) : false),
    },
};

const VIPs = () => {
    // Redux
    const { users, loaders } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [emailTemplate, setEmailTemplate] = useState('renewal');
    const [vipType, setVIPType] = useState(vipFilters['pickpackages']);
    const [deleteConfirm, setDeleteConfirm] = useState('');
    const [uid, setUID] = useState('');
    const [error, setError] = useState('');
    const [modalShow, setModalShow] = useState(false);

    const filteredUsers = users.filter(user => vipType.filter(user));

    useEffect(() => {
        dispatch(fetchUsers());
    }, []);

    useMemo(() => {
        if (deleteConfirm) {
            setModalShow(true);
        }
    }, [deleteConfirm]);

    const columnConfig = useMemo(() => {
        var config = [];
        if (vipType.id === 'free-access') {
            config = [
                { header: 'Email', dataKey: 'email', flexGrow: 1 },
                { header: 'Twitter', dataKey: 'twitter', flexGrow: 1 },
                { header: 'Package', dataKey: 'package', flexGrow: 1 },
                { header: 'Send Email', dataKey: 'send-email', flexGrow: 1 },
                { header: 'Delete', dataKey: 'delete' },
            ];
            return config; // Must return here or it will fall to the next if statement
        }
        if (vipType.id.indexOf('access') > -1 || vipType.id.indexOf('expired') > -1) {
            config = [
                { header: 'End Date', dataKey: 'end-date', flexGrow: 1 },
                { header: 'Email', dataKey: 'email', flexGrow: 1 },
                { header: 'Twitter', dataKey: 'twitter', flexGrow: 1 },
                { header: 'Package', dataKey: 'package', flexGrow: 1 },
                { header: 'Send Email', dataKey: 'send-email', flexGrow: 1 },
                { header: 'Demote', dataKey: 'demote', flexGrow: 1 },
                { header: 'Delete', dataKey: 'delete' },
            ];
            return config;
        }
        return [
            { header: 'Email', dataKey: 'email', flexGrow: 1 },
            { header: 'Twitter', dataKey: 'twitter', flexGrow: 1 },
            { header: 'Package', dataKey: 'package', flexGrow: 1 },
            { header: 'Status', dataKey: 'status', flexGrow: 1 },
            { header: 'Send Email', dataKey: 'send-email', flexGrow: 1 },
            { header: 'Delete', dataKey: 'delete' },
        ];
    }, [vipType.id]);

    const handleCloseModal = () => {
        setDeleteConfirm('');
        setModalShow(false);
    };

    const handleSendReminder = async email => {
        try {
            await sendEmail(email, emailTemplate);
            setError('');
        } catch (error) {
            console.log(`Error sending email\n${error}`);
            setError(error.message); // Objects are not valid react children (error is an object)
            scrollToTop();
        }
    };

    const configureColumns = () => {
        const data = filteredUsers
            .sort((a, b) => {
                var aDate = new Date(a.endDate);
                var bDate = new Date(b.endDate);
                return aDate - bDate;
            })
            .map(user => {
                const rowObj = { email: user.email, twitter: user.twitter, package: user.items[user.items.length - 1].id, userId: user.email };
                // Active access packages (not free accounts)
                if ((vipType.id.indexOf('access') > -1 || vipType.id.indexOf('expired') > -1) && vipType.id !== 'free-access') {
                    rowObj['end-date'] = moment(user.endDate).format('MM/DD/YYYY');
                    rowObj['demote'] = (
                        <Button
                            variant="warning"
                            onClick={async () => {
                                await updateReciept(user._id, 'type', 'free-account');
                                await updateReciept(user._id, 'endDate', 'never');
                                dispatch(fetchUsers());
                            }}
                        >
                            Demote
                        </Button>
                    );
                }
                // Pick packages
                if (vipType.id === 'pickpackages') {
                    rowObj['status'] = (
                        <FormSelect
                            value={user.status ? user.status : 'active'}
                            onChange={async e => {
                                await updateReciept(user._id, 'status', e.target.value);
                                dispatch(fetchUsers());
                            }}
                            variant={user.status === 'complete' ? 'secondary' : 'success'}
                        >
                            <option value="active">Active</option>
                            <option value="complete">Complete</option>
                        </FormSelect>
                    );
                }
                rowObj['send-email'] = (
                    <Button variant="primary" onClick={() => handleSendReminder(user.email)}>
                        Email
                    </Button>
                );
                return rowObj;
            });
        return data;
    };

    if (loaders.find(loader => loader === 'users-data')) {
        return (
            <ProfileContentContainer>
                <LoadingSpinner />
            </ProfileContentContainer>
        );
    }

    return (
        <ProfileContentContainer>
            {error && <Alert variant="danger">{error}</Alert>}
            <PickerContainer>
                <Picker as="select" value={vipType.id} onChange={e => setVIPType(vipFilters[e.target.value])}>
                    {Object.keys(vipFilters).map((key, i) => {
                        return (
                            <option key={i} value={vipFilters[key].id}>
                                {vipFilters[key].label}
                            </option>
                        );
                    })}
                </Picker>
                <Picker as="select" onChange={e => setEmailTemplate(e.target.value)}>
                    {EMAIL_TEMPLATES.map((template, i) => {
                        return (
                            <option key={i} value={template}>
                                {template}
                            </option>
                        );
                    })}
                </Picker>
                <Button onClick={() => handleSendReminder(filteredUsers.map(rec => rec.email))}>Email All</Button>
            </PickerContainer>
            <Row>
                <Col xs={12} sm={12} md={4}>
                    <Card className="border-0">
                        <Card.Body className="d-flex align-items-end">
                            <label style={{ marginBottom: '0px', color: '#8f959c' }}>members</label>
                            <span style={{ fontSize: '38px', marginLeft: '5px', lineHeight: '30px', fontWeight: 'bold' }}>{filteredUsers.length}</span>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={12} md={4}>
                    <Card className="border-0">
                        <Card.Body className="d-flex align-items-end">
                            <label style={{ marginBottom: '0px', color: '#8f959c' }}>revenue</label>
                            <span style={{ fontSize: '38px', marginLeft: '5px', lineHeight: '30px', fontWeight: 'bold' }}>$0</span>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xs={12} sm={12} md={4}>
                    <Card className="border-0">
                        <Card.Body className="d-flex align-items-end">
                            <label style={{ marginBottom: '0px', color: '#8f959c' }}>expired</label>
                            <span style={{ fontSize: '38px', marginLeft: '5px', lineHeight: '30px', fontWeight: 'bold' }}>
                                {users.filter(rec => (rec.endDate ? moment(rec.endDate).isSameOrBefore(moment()) : false)).length}
                            </span>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Table
                loading={loaders.find(loading => loading === 'users-data')}
                dataList={configureColumns()}
                autoHeight={true}
                bordered
                cellBordered
                handleDeleteRow={email => setDeleteConfirm(email)}
                columns={columnConfig}
            />
            <Modal show={modalShow} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">User Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <LabelValueListGroup direction="row" variant="flush">
                        <ListGroup.Item>
                            <label>Email:</label>
                            <span>{deleteConfirm}</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <label>Uid:</label>
                            <span>
                                <Form.Group as={Col} controlId="uid">
                                    <Form.Control required onChange={e => setUID(e.currentTarget.value)} placeholder="UID to delete..." />
                                </Form.Group>
                            </span>
                        </ListGroup.Item>
                    </LabelValueListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-primary" onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <Button
                        disabled={loaders.find(loader => loader === 'users-data')}
                        variant="danger"
                        onClick={async () => {
                            try {
                                await deleteProfile(uid, deleteConfirm);
                                dispatch(fetchUsers());
                                handleCloseModal();
                                setError('');
                            } catch (error) {
                                setError(`Error deleting profile\n${error}`);
                                console.log(`Error deleting profile\n${error}`);
                            }
                        }}
                    >
                        Delete User
                    </Button>
                </Modal.Footer>
            </Modal>
        </ProfileContentContainer>
    );
};

export default VIPs;
