// Library imports
import styled from 'styled-components';
import { MDBCard } from 'mdb-react-ui-kit';
import { SMALL_SCREEN_WIDTH } from '../../../../utils/constants';

export const SubscriptionCard = styled(MDBCard)`
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-radius: 5px;
    padding: 0.5rem;
    width: 450px;
    @media all and (max-width: ${SMALL_SCREEN_WIDTH}) {
        width: 300px;
    }
`;

export const StatusText = styled.span`
    position: absolute;
    right: 1rem;
    top: 1rem;
    background-color: ${({ active }) => (active ? '#28a745' : '#dc3545')};
    color: ${({ theme }) => theme.colors.white};
    padding: 0.25rem 0.65rem;
    border-radius: 20px;
    font-size: 0.9rem;
    font-weight: 500;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    margin-top: 20px;
`;
