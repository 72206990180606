// Library imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// Controller imports
import { getAllGameData, getAllTeamData, getLeagueData, getPropData } from '../../controllers/GetDataController';
import { getReciepts } from '../../controllers/PaymentController';

const initialState = {
    sport: 'mlb',
    subPage: 'games',
    filters: ['Upcoming'],
    openDrawer: '',
    loaders: ['game-data', 'users-data', 'team-data', 'league-data', 'prop-data'],
    propData: null,
    propCategory: 'playerProps',
    propName: '',
    leagueData: null,
    gameData: [],
    teams: [],
    users: [],
    error: '',
};

export const fetchProps = createAsyncThunk('app/fetchProps', async (_, { getState, rejectWithValue }) => {
    try {
        const { app } = getState();
        const data = await getPropData(app.sport);
        return data;
    } catch (error) {
        console.log(`Error getting prop data\n${error.message}`);
        return rejectWithValue(`Error getting prop data\n${error.message}`);
    }
});

export const fetchLeague = createAsyncThunk('app/fetchLeague', async (_, { getState, rejectWithValue }) => {
    try {
        const { app } = getState();
        const data = await getLeagueData(app.sport);
        return data;
    } catch (error) {
        console.log(`Error getting league data\n${error.message}`);
        return rejectWithValue(`Error getting league data\n${error.message}`);
    }
});

export const fetchGames = createAsyncThunk('app/fetchGames', async (_, { getState, rejectWithValue }) => {
    try {
        const { app, user: userState } = getState();
        const accountType = userState.user.reciept ? userState.user.reciept.type : 'access';
        const gameInfo = await getAllGameData(app.sport, accountType);
        return gameInfo;
    } catch (error) {
        console.log(`Error getting game data.\n${error.message}`);
        return rejectWithValue(`Error getting game data.\n${error.message}`);
    }
});

export const fetchTeams = createAsyncThunk('app/fetchTeams', async (_, { getState, rejectWithValue }) => {
    try {
        const { app } = getState();
        const teamsRes = await getAllTeamData(app.sport);
        return teamsRes;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const fetchUsers = createAsyncThunk('app/fetchUsers', async (_, { rejectWithValue }) => {
    try {
        const data = await getReciepts();
        return data;
    } catch (err) {
        console.log(err);
        return rejectWithValue([], err);
    }
});

const appSlice = createSlice({
    name: 'app',
    initialState: initialState,
    reducers: {
        setSport(state, { payload }) {
            state.sport = payload;
            state.subPage = 'games';
        },
        setSubPage(state, { payload }) {
            state.subPage = payload;
        },
        setFilters(state, { payload }) {
            if (payload === 'clear') {
                state.filters = [];
            } else {
                state.filters = [payload];
            }
        },
        setDrawer(state, { payload }) {
            state.openDrawer = payload;
        },
        addLoader(state, { payload }) {
            state.loaders = [...state.loaders, payload];
        },
        removeLoader(state, { payload }) {
            state.loaders = state.loaders.filter(loader => loader !== payload);
        },
        setPropName(state, { payload }) {
            state.propName = payload;
        },
        setPropCategory(state, { payload }) {
            state.propCategory = payload;
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchProps.pending, state => {
            state.loaders = [...state.loaders, 'prop-data'];
        });
        builder.addCase(fetchProps.fulfilled, (state, { payload }) => {
            state.propData = payload;
            state.propName = Object.keys(payload[state.propCategory])[0];
            state.loaders = state.loaders.filter(loader => loader !== 'prop-data');
        });
        builder.addCase(fetchProps.rejected, (state, { payload }) => {
            state.error = `Error loading prop data.\n${payload}`;
            state.loaders = state.loaders.filter(loader => loader !== 'prop-data');
        });
        builder.addCase(fetchLeague.pending, state => {
            state.loaders = [...state.loaders, 'league-data'];
        });
        builder.addCase(fetchLeague.fulfilled, (state, { payload }) => {
            state.leagueData = payload;
            state.loaders = state.loaders.filter(loader => loader !== 'league-data');
        });
        builder.addCase(fetchLeague.rejected, (state, { payload }) => {
            state.error = `Error loading league data.\n${payload}`;
            state.loaders = state.loaders.filter(loader => loader !== 'league-data');
        });
        builder.addCase(fetchGames.pending, state => {
            state.loaders = [...state.loaders, 'game-data'];
        });
        builder.addCase(fetchGames.fulfilled, (state, { payload }) => {
            state.gameData = payload;
            state.loaders = state.loaders.filter(loader => loader !== 'game-data');
        });
        builder.addCase(fetchGames.rejected, (state, { payload }) => {
            state.error = `Error loading games.\n${payload}`;
            state.loaders = state.loaders.filter(loader => loader !== 'game-data');
        });
        builder.addCase(fetchTeams.pending, state => {
            state.loaders = [...state.loaders, 'team-data'];
        });
        builder.addCase(fetchTeams.fulfilled, (state, { payload }) => {
            state.teams = payload;
            state.loaders = state.loaders.filter(loader => loader !== 'team-data');
        });
        builder.addCase(fetchTeams.rejected, (state, { payload }) => {
            state.error = `Error getting team information. ${payload}`;
            state.loaders = state.loaders.filter(loader => loader !== 'team-data');
        });
        builder.addCase(fetchUsers.pending, state => {
            state.loaders = [...state.loaders, 'users-data'];
        });
        builder.addCase(fetchUsers.fulfilled, (state, { payload }) => {
            state.users = payload;
            state.loaders = state.loaders.filter(loader => loader !== 'users-data');
        });
        builder.addCase(fetchUsers.rejected, (state, { payload }) => {
            state.error = `Error fetching users.\n${payload}`;
            state.loaders = state.loaders.filter(loader => loader !== 'users-data');
        });
    },
});

export const { setSport, setFilters, setDrawer, addLoader, removeLoader, setPropName, setPropCategory, setSubPage } = appSlice.actions;

export default appSlice.reducer;
