export const WINDOW_DATA = [
    {
        id: 'power-rank',
        title: 'Power Ranks',
    },
    {
        id: 'line-movement',
        title: 'Line Movement',
    },
    {
        id: 'percent',
        title: 'Ticket Percents',
    },
    {
        id: 'prediction',
        title: 'Prediction',
    },
    {
        id: 'headlines',
        title: 'Headlines',
    },
    {
        id: 'injuries',
        title: 'Injury Report',
    },
    {
        id: 'schedule',
        title: 'Schedule',
    },
    {
        id: 'pick-odds',
        title: 'Pick Odds',
    },
    {
        id: 'key-factor',
        title: 'Key Factors',
    },
    {
        id: 'pitchers',
        title: 'Pitchers',
    },
    {
        id: 'totals',
        title: 'Total Data',
    },
    {
        id: 'search',
        title: 'Advanced Search',
    },
    {
        id: 'situations',
        title: 'Situations',
    },
];
