// Library imports
import styled from 'styled-components';

export const GameDetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-right: auto;
`;

export const TeamName = styled.span`
    font-family: ${({ theme }) => theme.fonts.heading};
`;

export const GameDate = styled.span`
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 0.75rem !important;
`;
