export default {
    mlb: {
        awayTeam: {
            teamName: 'Baltimore',
            teamId: 7654,
            abbr: 'BAL',
            mascot: 'Orioles',
            fullName: 'Baltimore Orioles',
            shortName: '',
            pitcherData: 'https://www.espn.com/mlb/player/_/id/38295',
            color: '#DF4601',
            pitcher: {
                firstName: 'Dean',
                lastName: 'Kremer',
                stats: {
                    'Wins-Losses': 4,
                    'Earned Run Average': 4.39,
                    Strikeouts: 75,
                    'Walks Plus Hits Per Inning Pitched': 1.25,
                    xfip: 4.43,
                    kpercent: 21.7,
                    war: 0.5,
                    babip: 0.247,
                },
                throws: 'Right',
                splits: {
                    away: {
                        era: 3.53,
                        walks: 15,
                    },
                    home: {
                        era: 5.4,
                        walks: 16,
                    },
                },
            },
            odds: {
                fullGame: {
                    spread: -1.5,
                    moneyline: -110,
                },
            },
            spreadPercent: 83,
            mlPercent: 64,
            mlMoneyPercent: 64,
            spreadMoneyPercent: 83,
            injuries: [
                {
                    player: 'Grayson Rodriguez',
                    position: 'SP',
                    date: 'Aug 19',
                    status: '15-Day-IL',
                    desc: 'Aug 7: Manager Brandon Hyde said Wednesday that Rodriguez has been diagnosed with a mild right lat strain, Andy Kostka of TheBaltimoreBanner.com reports.',
                    value: 0,
                },
                {
                    player: 'Jacob Webb',
                    position: 'RP',
                    date: 'Aug 19',
                    status: '15-Day-IL',
                    desc: 'Aug 7: Webb (1-5) allowed a run on a hit and a walk over one inning, taking the loss Wednesday versus the Marlins.',
                    value: 0,
                },
                {
                    player: 'Heston Kjerstad',
                    position: 'LF',
                    date: 'Aug 15',
                    status: '10-Day-IL',
                    desc: 'Aug 7: The Orioles optioned Kjerstad to Triple-A Norfolk on Thursday.',
                    value: 0,
                },
                {
                    player: 'Jordan Westburg',
                    position: '3B',
                    date: 'Sep 13',
                    status: '10-Day-IL',
                    desc: "Aug 1: Orioles manager Brandon Hyde said that Westburg has been diagnosed with a fractured right hand after exiting Wednesday's 10-4 win over the Blue Jays, Jacob Calvin Meyer of The Baltimore Sun reports.",
                    value: 0,
                },
                {
                    player: 'Jorge Mateo',
                    position: '2B',
                    date: 'Sep 24',
                    status: '60-Day-IL',
                    desc: 'Jul 30: Orioles manager Brandon Hyde said Thursday that Mateo (elbow) will miss "a significant amount of time," Paige Leckie of MLB.com reports.',
                    value: 0,
                },
                {
                    player: 'Danny Coulombe',
                    position: 'RP',
                    date: 'Sep 1',
                    status: '60-Day-IL',
                    desc: 'Jul 13: The Orioles transferred Coulombe (elbow) to the 60-day injured list Saturday.',
                    value: 0,
                },
                {
                    player: 'Kyle Bradish',
                    position: 'SP',
                    date: 'Sep 1',
                    status: '60-Day-IL',
                    desc: 'Jul 11: The Orioles transferred Bradish (elbow) to the 60-day injured list Thursday, Dan Connolly of The Athletic reports.',
                    value: 0,
                },
                {
                    player: 'Tyler Wells',
                    position: 'SP',
                    date: 'May 1',
                    status: '60-Day-IL',
                    desc: 'Jun 19: Wells (elbow) underwent a UCL revision and internal brace augmentation surgery Monday, Roch Kubatko of MASNSports.com reports.',
                    value: 0,
                },
                {
                    player: 'John Means',
                    position: 'SP',
                    date: 'Sep 1',
                    status: '60-Day-IL',
                    desc: 'Jun 13: Means underwent successful Tommy John surgery Monday in Texas to repair a torn UCL in his left elbow, Jake Rill of MLB.com reports.',
                    value: 0,
                },
                {
                    player: 'Nate Webb',
                    position: 'RP',
                    date: 'Feb 1',
                    status: 'Day-To-Day',
                    desc: 'May 24: Webb will miss the rest of the season after undergoing left Achilles tendon surgery, Roch Kubatko of MASNSports.com reports.',
                    value: 0,
                },
                {
                    player: 'Felix Bautista',
                    position: 'RP',
                    date: 'Feb 1',
                    status: '60-Day-IL',
                    desc: 'May 17: Bautista (elbow) played catch Friday, Danielle Allentuck of TheBaltimoreBanner.com reports.',
                    value: 0,
                },
            ],
            openMoneyLine: -125,
            stats: {
                def: {
                    errors: 59,
                    fpct: 0.986,
                    ppg: 4.3,
                },
                off: {
                    avg: 0.255,
                    hr: 178,
                    obp: 0.319,
                    oppg: 5.09,
                    rbi: 568,
                    runs: 5.09,
                    slg: 0.453,
                },
                splits: {},
                pitching: {
                    bb: 444,
                    era: 3.89,
                    fip: 3.9,
                    homeruns: 495,
                    losses: 68,
                    saves: 47,
                    so: 119,
                    wins: 115,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 113.33333333333333,
                    totalGames: 9,
                },
                favorite: {
                    avgML: -150.21505376344086,
                    totalGames: 93,
                },
            },
            recordData: {
                ats: '62-53-0',
                awayRecord: '34-22',
                conferenceRecord: '0-0',
                divisionRecord: '0-0',
                homeRecord: '34-25',
                ou: '64-41-10',
                record: '68-47',
            },
            sos: 3,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/mlb%2F7654_mlb.png?alt=media',
            division: 'East',
            conference: 'American League',
        },
        homeTeam: {
            teamName: 'Toronto',
            teamId: 992,
            abbr: 'TOR',
            mascot: 'Blue Jays',
            fullName: 'Toronto Blue Jays',
            shortName: '',
            pitcherData: 'https://www.espn.com/mlb/player/_/id/32667',
            color: '#134A8E',
            pitcher: {
                firstName: 'Kevin',
                lastName: 'Gausman',
                stats: {
                    'Wins-Losses': 9,
                    'Earned Run Average': 4.56,
                    Strikeouts: 119,
                    'Walks Plus Hits Per Inning Pitched': 1.3,
                    xfip: 4.04,
                    kpercent: 22.4,
                    war: 1.7,
                    babip: 0.303,
                },
                throws: 'Right',
                splits: {
                    away: {
                        era: 2.74,
                        walks: 15,
                    },
                    home: {
                        era: 6.39,
                        walks: 21,
                    },
                },
            },
            odds: {
                fullGame: {
                    spread: 1.5,
                    moneyline: -125,
                },
            },
            spreadPercent: 17,
            mlPercent: 36,
            mlMoneyPercent: 36,
            spreadMoneyPercent: 17,
            injuries: [
                {
                    player: 'Genesis Cabrera',
                    position: 'RP',
                    date: 'Aug 11',
                    status: 'paternity',
                    desc: 'Aug 8: Cabrera gave up a hit and a walk while striking out one in the 10th inning Sunday to record his second save of the season in a win over the Mariners.',
                    value: 0,
                },
                {
                    player: 'Alek Manoah',
                    position: 'SP',
                    date: 'Jul 1',
                    status: '15-Day-IL',
                    desc: 'Jul 29: Manoah revealed Monday in a social media post that he had the brace removed from his surgically repaired right elbow.',
                    value: 0,
                },
                {
                    player: 'Jordan Romano',
                    position: 'RP',
                    date: 'Sep 6',
                    status: '60-Day-IL',
                    desc: 'Jul 27: Romano underwent arthroscopic surgery on his right elbow Tuesday to repair an impingement and will be shut down from throwing for at least six weeks, Keegan Matheson of MLB.com reports.',
                    value: 0,
                },
                {
                    player: 'Ricky Tiedemann',
                    position: 'SP',
                    date: 'Oct 1',
                    status: 'Day-To-Day',
                    desc: 'Jul 27: Tiedemann (forearm) will undergo Tommy John surgery Tuesday, Keegan Matheson of MLB.com reports.',
                    value: 0,
                },
                {
                    player: 'Bo Bichette',
                    position: 'SS',
                    date: 'Aug 10',
                    status: '10-Day-IL',
                    desc: 'Jul 21: no',
                    value: 0,
                },
                {
                    player: 'Payton Henry',
                    position: 'C',
                    date: 'Aug 9',
                    status: 'Day-To-Day',
                    desc: 'Jun 4: Henry signed a minor-league contract with the Blue Jays on Saturday.',
                    value: 0,
                },
            ],
            openMoneyLine: 105,
            stats: {
                def: {
                    errors: 67,
                    fpct: 0.983,
                    ppg: 4.91,
                },
                off: {
                    avg: 0.241,
                    hr: 103,
                    obp: 0.313,
                    oppg: 4.13,
                    rbi: 447,
                    runs: 4.13,
                    slg: 0.382,
                },
                splits: {},
                pitching: {
                    bb: 516,
                    era: 4.63,
                    fip: 4.61,
                    homeruns: 560,
                    losses: 52,
                    saves: 45,
                    so: 158,
                    wins: 114,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 129.7058823529412,
                    totalGames: 34,
                },
                favorite: {
                    avgML: -138.86363636363637,
                    totalGames: 66,
                },
            },
            recordData: {
                ats: '54-60-0',
                awayRecord: '25-33',
                conferenceRecord: '0-0',
                divisionRecord: '0-0',
                homeRecord: '27-29',
                ou: '62-49-3',
                record: '52-62',
            },
            sos: 24,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/mlb%2F992_mlb.png?alt=media',
            division: 'East',
            conference: 'American League',
        },
        gameId: '2024-08-09T00:07:00.000Z-992-7654',
        dateTime: '2024-08-09T00:07:00.000Z',
        status: 'not started',
        bookData: {
            moneylines: [
                {
                    awayLine: -112,
                    homeLine: -104,
                    label: 'FanDuel',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                    color: '#2192f5',
                },
                {
                    awayLine: -115,
                    homeLine: -105,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: -112,
                    homeLine: -108,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
            ],
            spreads: [
                {
                    awayLine: -1.5,
                    homeLine: 1.5,
                    label: 'FanDuel',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                    color: '#2192f5',
                },
                {
                    awayLine: -1.5,
                    homeLine: 1.5,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: -1.5,
                    homeLine: 1.5,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
            ],
        },
        openingSpread: 1.5,
        headlines: [
            {
                title: 'BALTIMORE ORIOLES VS TORONTO BLUE JAYS PREDICTION, PICK & MLB ODDSACTION NETWORK\n5 HOURS AGO',
                href: 'https://www.google.com/url?q=https://www.actionnetwork.com/mlb/baltimore-orioles-vs-toronto-blue-jays-prediction-pick-mlb-odds&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAD&usg=AOvVaw36Sp8lJC0xH7ljSw0uCF04',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'BALTIMORE ORIOLES VS TORONTO BLUE JAYS PREDICTION MLB PICKS 8/8/24PICKDAWGZ\n17 HOURS AGO',
                href: 'https://www.google.com/url?q=https://pickdawgz.com/mlb-picks/2024-08-08/baltimore-orioles-vs-toronto-blue-jays-prediction-mlb-picks&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAH&usg=AOvVaw2ulGylaHF5cGNw331tixha',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'BALTIMORE ORIOLES AT TORONTO BLUE JAYS ODDS, PICKS AND PREDICTIONSUSA TODAY SPORTSBOOK WIRE\n1 DAY AGO',
                href: 'https://www.google.com/url?q=https://sportsbookwire.usatoday.com/2024/08/07/baltimore-orioles-at-toronto-blue-jays-odds-picks-and-predictions-17/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAL&usg=AOvVaw2d7iQufj2ZFkWe-GQ9ipsz',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'TORONTO BLUE JAYS VS BALTIMORE ORIOLES PREDICTION 8-8-24 MLB PICKS | SPORTS \nCHAT PLACESPORTS CHAT PLACE\n16 HOURS AGO',
                href: 'https://www.google.com/url?q=https://sportschatplace.com/mlb-picks/toronto-blue-jays-vs-baltimore-orioles-prediction-8-8-24-mlb-picks/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAP&usg=AOvVaw1SICSvMqu7WHG7UAigpjah',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'BALTIMORE ORIOLES AT TORONTO BLUE JAYS ODDS, PICKS AND PREDICTIONSUSA TODAY SPORTSBOOK WIRE\n2 DAYS AGO',
                href: 'https://www.google.com/url?q=https://sportsbookwire.usatoday.com/2024/08/06/baltimore-orioles-at-toronto-blue-jays-odds-picks-and-predictions-16/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAT&usg=AOvVaw1O84R_9wgbHpJF8U8scGtk',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'BALTIMORE ORIOLES VS TORONTO BLUE JAYS PREDICTION MLB PICKS 8/6/24PICKDAWGZ\n2 DAYS AGO',
                href: 'https://www.google.com/url?q=https://pickdawgz.com/mlb-picks/2024-08-06/baltimore-orioles-vs-toronto-blue-jays-prediction-mlb-picks&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAX&usg=AOvVaw1bcXOZf-MZNFbO_EvQJdcY',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'BALTIMORE ORIOLES AT TORONTO BLUE JAYS ODDS, PICKS AND PREDICTIONSSPORTSBOOKWIRE.USATODAY.COM › 2024/08/08 › BALTIMORE-ORIOLES-AT-TORONTO-...',
                href: 'https://www.google.com/url?q=https://sportsbookwire.usatoday.com/2024/08/08/baltimore-orioles-at-toronto-blue-jays-odds-picks-and-predictions-18/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAQQAg&usg=AOvVaw2JcLnwCKZjwdoOIB5Zmjh5',
                description:
                    "16 hours ago   Analyzing Thursday's Baltimore Orioles at Toronto Blue Jays odds and lines, with MLB expert picks, predictions and best bets.",
            },
            {
                title: 'ORIOLES VS. BLUE JAYS PREDICTION, ODDS, PROBABLE PITCHERS ... - KRON4WWW.KRON4.COM › SPORTS › SPORTS-ILLUSTRATED › ORIOLES-VS-BLUE-JAYS-PREDICTI...',
                href: 'https://www.google.com/url?q=https://www.kron4.com/sports/sports-illustrated/7f95f06a/orioles-vs-blue-jays-prediction-odds-probable-pitchers-for-thursday-aug-8-fade-dean-kremer&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAEQAg&usg=AOvVaw2F_ypjZFpv7Z7RJbn2wJEx',
                description:
                    '3 hours ago   The Toronto Blue Jays and Baltimore Orioles will play the rubber match of a three-game set on Thursday night, and oddsmakers are expecting ...',
            },
            {
                title: "ORIOLES VS BLUE JAYS PREDICTION, PICKS & ODDS FOR TONIGHT'S MLB GAMEWWW.COVERS.COM › MLB › ORIOLES-VS-BLUE-JAYS-PREDICTION-PICKS-TONIGHT-THU...",
                href: 'https://www.google.com/url?q=https://www.covers.com/mlb/orioles-vs-blue-jays-prediction-picks-tonight-thursday-8-8-2024&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAUQAg&usg=AOvVaw1k9pO2EmEhxm-N5e5I9UJd',
                description:
                    '3 hours ago   Baltimore is 7-4 straight up against Toronto this season. Find more MLB betting trends for Orioles vs. Blue Jays. Orioles vs Blue Jays game info ...',
            },
            {
                title: 'ORIOLES VS. BLUE JAYS PREDICTION, ODDS, PICKS - AUGUST 8 | FOX SPORTSWWW.FOXSPORTS.COM › ARTICLES › MLB › ORIOLES-VS-BLUE-JAYS-PREDICTION-ODDS-...',
                href: 'https://www.google.com/url?q=https://www.foxsports.com/articles/mlb/orioles-vs-blue-jays-prediction-odds-picks-august-8&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAIQAg&usg=AOvVaw3tMYYJg-PTDXM2xUmew-T7',
                description:
                    '19 hours ago   Preview the August 8 matchup between the Baltimore Orioles and Toronto Blue Jays with odds over/under, game spread, betting lines and more.',
            },
            {
                title: 'BALTIMORE ORIOLES VS. TORONTO BLUE JAYS PREDICTION 8/8/24 MLB PICK ...WWW.TONYSPICKS.COM › FREE MLB PICKS',
                href: 'https://www.google.com/url?q=https://www.tonyspicks.com/2024/08/08/baltimore-orioles-vs-toronto-blue-jays-prediction-8-8-24-mlb-pick-today/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAcQAg&usg=AOvVaw26FoQNKZc6MD7pe235qgNx',
                description:
                    "10 hours ago   This match is fixed to take place on Thursday, August 8th, 2024 at Rogers Centre. The contest not only exemplifies two teams' different fortunes ...",
            },
            {
                title: 'ORIOLES VS BLUE JAYS PREDICTION AND PICKS FOR AUGUST 8 2024WINNERSANDWHINERS.COM › GAMES › MLB › BALTIMORE-ORIOLES-VS-TORONTO-BLUE...',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/mlb/8-8-2024/baltimore-orioles-vs-toronto-blue-jays-prediction-7650/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAMQAg&usg=AOvVaw26F9EWccZ-W-bYY2auSGCA',
                description:
                    '8 hours ago   Get our Baltimore Orioles vs Toronto Blue Jays MLB picks, prediction, analysis, odds, and more for the 8/8/24 matchup.',
            },
            {
                title: 'BALTIMORE ORIOLES VS TORONTO BLUE JAYS PREDICTION MLB PICKS 8/8/24PICKDAWGZ.COM › MLB-PICKS › BALTIMORE-ORIOLES-VS-TORONTO-BLUE-JAYS-PREDI...',
                href: 'https://www.google.com/url?q=https://pickdawgz.com/mlb-picks/2024-08-08/baltimore-orioles-vs-toronto-blue-jays-prediction-mlb-picks&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAYQAg&usg=AOvVaw2bG1FXmrxo8S4bsr9APOpy',
                description:
                    '17 hours ago   Toronto Blue Jays vs. Baltimore Orioles 8/8/24: MLB free preview, analysis, prediction, odds and pick against the spread.',
            },
            {
                title: 'ORIOLES VS. BLUE JAYS PREDICTION, ODDS, PICKS - AUGUST 7 | FOX SPORTSWWW.FOXSPORTS.COM › ARTICLES › MLB › ORIOLES-VS-BLUE-JAYS-PREDICTION-ODDS-...',
                href: 'https://www.google.com/url?q=https://www.foxsports.com/articles/mlb/orioles-vs-blue-jays-prediction-odds-picks-august-7&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAAQAg&usg=AOvVaw2LSY2GrNq3kJotw4r07KVE',
                description:
                    '2 days ago   Preview the August 7 matchup between the Baltimore Orioles and Toronto Blue Jays with odds over/under, game spread, betting lines and more.',
            },
        ],
        total: 9,
        totalStats: {
            total: 9,
            over: 117,
            under: 120,
        },
        advantages: [
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 55.708047411104175,
                teamId: 7654,
                description: 'BAL has a better PPG.  Teams with a better PPG win at a 55% rate.',
                amount: 0.96,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 53.903810118675835,
                teamId: 7654,
                description: 'BAL has a better DPPG.  Teams with a better DPPG win at a 53% rate.',
                amount: 0.6100000000000003,
            },
            {
                adv: 'Key Factor - AVG',
                id: 'key-factor',
                factorId: 'avg',
                winPercent: 55.55555555555556,
                teamId: 7654,
                description: 'BAL has a better AVG.  Teams with a better AVG win at a 55% rate.',
                amount: 0.014000000000000012,
            },
            {
                adv: 'Key Factor - HR',
                id: 'key-factor',
                factorId: 'hr',
                winPercent: 54.06790518898143,
                teamId: 7654,
                description: 'BAL has a better HR.  Teams with a better HR win at a 54% rate.',
                amount: 75,
            },
            {
                adv: 'Key Factor - OBP',
                id: 'key-factor',
                factorId: 'obp',
                winPercent: 54.694391934467546,
                teamId: 7654,
                description: 'BAL has a better OBP.  Teams with a better OBP win at a 54% rate.',
                amount: 0.006000000000000005,
            },
            {
                adv: 'Key Factor - RBI(s)',
                id: 'key-factor',
                factorId: 'rbi',
                winPercent: 55.72232645403376,
                teamId: 7654,
                description: 'BAL has a better RBI(s).  Teams with a better RBI(s) win at a 55% rate.',
                amount: 121,
            },
            {
                adv: 'Key Factor - SLG',
                id: 'key-factor',
                factorId: 'slg',
                winPercent: 54.76635514018692,
                teamId: 7654,
                description: 'BAL has a better SLG.  Teams with a better SLG win at a 54% rate.',
                amount: 0.07100000000000001,
            },
            {
                adv: 'Key Factor - ERs',
                id: 'key-factor',
                factorId: 'errors',
                winPercent: 52.03197868087941,
                teamId: 7654,
                description: 'BAL has a better ERs.  Teams with a better ERs win at a 52% rate.',
                amount: 8,
            },
            {
                adv: 'Key Factor - FPCT',
                id: 'key-factor',
                factorId: 'fpct',
                winPercent: 52.361111111111114,
                teamId: 7654,
                description: 'BAL has a better FPCT.  Teams with a better FPCT win at a 52% rate.',
                amount: 0.0030000000000000027,
            },
            {
                adv: 'Key Factor - ERA',
                id: 'key-factor',
                factorId: 'Earned Run Average',
                winPercent: 49.96734160679295,
                teamId: 7654,
                description: 'BAL has a better ERA.  Teams with a better ERA win at a 49% rate.',
                amount: 0.16999999999999993,
            },
            {
                adv: 'Key Factor - WHIP',
                id: 'key-factor',
                factorId: 'Walks Plus Hits Per Inning Pitched',
                winPercent: 51.477347340774784,
                teamId: 7654,
                description: 'BAL has a better WHIP.  Teams with a better WHIP win at a 51% rate.',
                amount: 0.050000000000000044,
            },
            {
                adv: 'Key Factor - Ks',
                id: 'key-factor',
                factorId: 'Strikeouts',
                winPercent: 54.266666666666666,
                teamId: 992,
                description: 'TOR has a better Ks.  Teams with a better Ks win at a 54% rate.',
                amount: 44,
            },
            {
                adv: 'Key Factor - BABIP',
                id: 'key-factor',
                factorId: 'babip',
                winPercent: 52.16763005780347,
                teamId: 7654,
                description: 'BAL has a better BABIP.  Teams with a better BABIP win at a 52% rate.',
                amount: 0.055999999999999994,
            },
            {
                adv: 'Key Factor - K%',
                id: 'key-factor',
                factorId: 'kpercent',
                winPercent: 54.96402877697841,
                teamId: 992,
                description: 'TOR has a better K%.  Teams with a better K% win at a 54% rate.',
                amount: 0.6999999999999993,
            },
            {
                adv: 'Key Factor - WAR',
                id: 'key-factor',
                factorId: 'war',
                winPercent: 50.22488755622189,
                teamId: 992,
                description: 'TOR has a better WAR.  Teams with a better WAR win at a 50% rate.',
                amount: 1.2,
            },
            {
                adv: 'Key Factor - xFIP',
                id: 'key-factor',
                factorId: 'xfip',
                winPercent: 52.12383009359252,
                teamId: 992,
                description: 'TOR has a better xFIP.  Teams with a better xFIP win at a 52% rate.',
                amount: 0.3899999999999997,
            },
            {
                adv: 'Key Factor - Saves',
                id: 'key-factor',
                factorId: 'saves',
                winPercent: 53.428377460964015,
                teamId: 7654,
                description: 'BAL has a better Saves.  Teams with a better Saves win at a 53% rate.',
                amount: 2,
            },
            {
                adv: 'Key Factor - Ks',
                id: 'key-factor',
                factorId: 'so',
                winPercent: 47.647058823529406,
                teamId: 992,
                description: 'TOR has a better Ks.  Teams with a better Ks win at a 47% rate.',
                amount: 39,
            },
            {
                adv: 'Key Factor - ERA',
                id: 'key-factor',
                factorId: 'era',
                winPercent: 52.80548628428927,
                teamId: 7654,
                description: 'BAL has a better ERA.  Teams with a better ERA win at a 52% rate.',
                amount: 0.7399999999999998,
            },
            {
                adv: 'Key Factor - FIP',
                id: 'key-factor',
                factorId: 'fip',
                winPercent: 53.421052631578945,
                teamId: 7654,
                description: 'BAL has a better FIP.  Teams with a better FIP win at a 53% rate.',
                amount: 0.7100000000000004,
            },
            {
                adv: 'Key Factor - Walks',
                id: 'key-factor',
                factorId: 'bb',
                winPercent: 53.61407919547454,
                teamId: 7654,
                description: 'BAL has a better Walks.  Teams with a better Walks win at a 53% rate.',
                amount: 72,
            },
            {
                adv: 'Key Factor - HR Allowed',
                id: 'key-factor',
                factorId: 'homeruns',
                winPercent: 54.56821026282853,
                teamId: 7654,
                description: 'BAL has a better HR Allowed.  Teams with a better HR Allowed win at a 54% rate.',
                amount: 65,
            },
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 55.708047411104175,
                teamId: 7654,
                description: 'BAL has a better PPG.  Teams with a better PPG win at a 55% rate.',
                amount: 0.96,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 53.903810118675835,
                teamId: 7654,
                description: 'BAL has a better DPPG.  Teams with a better DPPG win at a 53% rate.',
                amount: 0.6100000000000003,
            },
            {
                adv: 'Key Factor - AVG',
                id: 'key-factor',
                factorId: 'avg',
                winPercent: 55.55555555555556,
                teamId: 7654,
                description: 'BAL has a better AVG.  Teams with a better AVG win at a 55% rate.',
                amount: 0.014000000000000012,
            },
            {
                adv: 'Key Factor - HR',
                id: 'key-factor',
                factorId: 'hr',
                winPercent: 54.06790518898143,
                teamId: 7654,
                description: 'BAL has a better HR.  Teams with a better HR win at a 54% rate.',
                amount: 75,
            },
            {
                adv: 'Key Factor - OBP',
                id: 'key-factor',
                factorId: 'obp',
                winPercent: 54.694391934467546,
                teamId: 7654,
                description: 'BAL has a better OBP.  Teams with a better OBP win at a 54% rate.',
                amount: 0.006000000000000005,
            },
            {
                adv: 'Key Factor - RBI(s)',
                id: 'key-factor',
                factorId: 'rbi',
                winPercent: 55.72232645403376,
                teamId: 7654,
                description: 'BAL has a better RBI(s).  Teams with a better RBI(s) win at a 55% rate.',
                amount: 121,
            },
            {
                adv: 'Key Factor - SLG',
                id: 'key-factor',
                factorId: 'slg',
                winPercent: 54.76635514018692,
                teamId: 7654,
                description: 'BAL has a better SLG.  Teams with a better SLG win at a 54% rate.',
                amount: 0.07100000000000001,
            },
            {
                adv: 'Key Factor - ERs',
                id: 'key-factor',
                factorId: 'errors',
                winPercent: 52.03197868087941,
                teamId: 7654,
                description: 'BAL has a better ERs.  Teams with a better ERs win at a 52% rate.',
                amount: 8,
            },
            {
                adv: 'Key Factor - FPCT',
                id: 'key-factor',
                factorId: 'fpct',
                winPercent: 52.361111111111114,
                teamId: 7654,
                description: 'BAL has a better FPCT.  Teams with a better FPCT win at a 52% rate.',
                amount: 0.0030000000000000027,
            },
            {
                adv: 'Key Factor - ERA',
                id: 'key-factor',
                factorId: 'Earned Run Average',
                winPercent: 49.96734160679295,
                teamId: 7654,
                description: 'BAL has a better ERA.  Teams with a better ERA win at a 49% rate.',
                amount: 0.16999999999999993,
            },
            {
                adv: 'Key Factor - WHIP',
                id: 'key-factor',
                factorId: 'Walks Plus Hits Per Inning Pitched',
                winPercent: 51.477347340774784,
                teamId: 7654,
                description: 'BAL has a better WHIP.  Teams with a better WHIP win at a 51% rate.',
                amount: 0.050000000000000044,
            },
            {
                adv: 'Key Factor - Ks',
                id: 'key-factor',
                factorId: 'Strikeouts',
                winPercent: 54.266666666666666,
                teamId: 992,
                description: 'TOR has a better Ks.  Teams with a better Ks win at a 54% rate.',
                amount: 44,
            },
            {
                adv: 'Key Factor - BABIP',
                id: 'key-factor',
                factorId: 'babip',
                winPercent: 52.16763005780347,
                teamId: 7654,
                description: 'BAL has a better BABIP.  Teams with a better BABIP win at a 52% rate.',
                amount: 0.055999999999999994,
            },
            {
                adv: 'Key Factor - K%',
                id: 'key-factor',
                factorId: 'kpercent',
                winPercent: 54.96402877697841,
                teamId: 992,
                description: 'TOR has a better K%.  Teams with a better K% win at a 54% rate.',
                amount: 0.6999999999999993,
            },
            {
                adv: 'Key Factor - WAR',
                id: 'key-factor',
                factorId: 'war',
                winPercent: 50.22488755622189,
                teamId: 992,
                description: 'TOR has a better WAR.  Teams with a better WAR win at a 50% rate.',
                amount: 1.2,
            },
            {
                adv: 'Key Factor - xFIP',
                id: 'key-factor',
                factorId: 'xfip',
                winPercent: 52.12383009359252,
                teamId: 992,
                description: 'TOR has a better xFIP.  Teams with a better xFIP win at a 52% rate.',
                amount: 0.3899999999999997,
            },
            {
                adv: 'Key Factor - Saves',
                id: 'key-factor',
                factorId: 'saves',
                winPercent: 53.428377460964015,
                teamId: 7654,
                description: 'BAL has a better Saves.  Teams with a better Saves win at a 53% rate.',
                amount: 2,
            },
            {
                adv: 'Key Factor - Ks',
                id: 'key-factor',
                factorId: 'so',
                winPercent: 47.647058823529406,
                teamId: 992,
                description: 'TOR has a better Ks.  Teams with a better Ks win at a 47% rate.',
                amount: 39,
            },
            {
                adv: 'Key Factor - ERA',
                id: 'key-factor',
                factorId: 'era',
                winPercent: 52.80548628428927,
                teamId: 7654,
                description: 'BAL has a better ERA.  Teams with a better ERA win at a 52% rate.',
                amount: 0.7399999999999998,
            },
            {
                adv: 'Key Factor - FIP',
                id: 'key-factor',
                factorId: 'fip',
                winPercent: 53.421052631578945,
                teamId: 7654,
                description: 'BAL has a better FIP.  Teams with a better FIP win at a 53% rate.',
                amount: 0.7100000000000004,
            },
            {
                adv: 'Key Factor - Walks',
                id: 'key-factor',
                factorId: 'bb',
                winPercent: 53.61407919547454,
                teamId: 7654,
                description: 'BAL has a better Walks.  Teams with a better Walks win at a 53% rate.',
                amount: 72,
            },
            {
                adv: 'Key Factor - HR Allowed',
                id: 'key-factor',
                factorId: 'homeruns',
                winPercent: 54.56821026282853,
                teamId: 7654,
                description: 'BAL has a better HR Allowed.  Teams with a better HR Allowed win at a 54% rate.',
                amount: 65,
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'BAL has an advantage based on our power rank data.',
                teamId: 7654,
                amount: 42,
            },
        ],
        prediction: {
            awayScore: 6.41,
            homeScore: 3.8,
            winner: 7654,
            spreadWinner: 7654,
            powerSpread: 2.6100000000000003,
            powerMoneyline: {
                dogML: 152,
                favML: -157,
            },
            powerLineWinner: 7654,
        },
        situations: [
            {
                id: 'division-game',
                label: 'Division Game',
                value: true,
                description: 'This is a East division game.',
                type: 'other',
            },
            {
                id: 'conference-game',
                label: 'Conference Game',
                value: true,
                description: 'This is a American League conference game.',
                type: 'other',
            },
            {
                id: 'line-movement',
                label: 'Line Movement',
                teamId: 7654,
                description: 'The line is on the move in favor of Baltimore.',
                type: 'team',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 7654,
                description: 'The Baltimore Orioles win 50.00% of games on Thursday.',
                type: 'team',
                queryString: '?test-string',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 992,
                description: 'The Toronto Blue Jays win 50.00% of games on Thursday.',
                type: 'team',
                queryString: '?test-string',
            },
        ],
    },
    nba: {
        awayTeam: {
            teamName: 'Miami',
            teamId: 123404,
            abbr: 'MIA',
            mascot: 'Heat',
            fullName: 'Miami Heat',
            shortName: '',
            pitcherData: '',
            color: '#98002E',
            odds: {
                fullGame: {
                    moneyline: -180,
                    spread: -4.5,
                },
            },
            openMoneyLine: -170,
            spreadPercent: 83,
            mlPercent: 83,
            mlMoneyPercent: 95,
            spreadMoneyPercent: 73,
            injuries: [
                {
                    player: 'Nikola Jovic',
                    position: 'F',
                    date: 'Dec 20',
                    status: 'Out',
                    desc: "Dec 15: Jovic (ankle) has been ruled out for Monday's game against the Pistons, Ky Carlin of USA Today reports.",
                    value: 0,
                },
                {
                    player: 'Pelle Larsson',
                    position: 'G',
                    date: 'Dec 20',
                    status: 'Out',
                    desc: "Dec 15: Larsson (ankle) has been ruled out for Monday's game against the Pistons, Ira Winderman of the South Florida Sun Sentinel reports.",
                    value: 0,
                },
                {
                    player: 'Josh Richardson',
                    position: 'SG',
                    date: 'Dec 20',
                    status: 'Out',
                    desc: 'Dec 11: Richardson had an injection in his left heel Wednesday and will miss at least one week, Ira Winderman of the South Florida Sun Sentinel reports.',
                    value: 0,
                },
            ],
            stats: {
                def: {
                    defEff: 1.082,
                    fouls: 16.1,
                    oppThreePoint: 35.9,
                    ppg: 109,
                    reboundPercent: 50.6,
                },
                off: {
                    floorPercent: 49.7,
                    freeThrow: 77.6,
                    offEff: 1.114,
                    oppg: 112.3,
                    threePoint: 38.4,
                },
                splits: {},
            },
            averageMoneylines: {
                dog: {
                    avgML: 190.71428571428572,
                    totalGames: 7,
                },
                favorite: {
                    avgML: -226.33333333333334,
                    totalGames: 15,
                },
            },
            recordData: {
                ats: '11-12-0',
                awayRecord: '5-6',
                conferenceRecord: '9-7',
                divisionRecord: '3-1',
                homeRecord: '8-4',
                ou: '13-10-0',
                record: '13-10',
            },
            sos: 22,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nba%2F123404_nba.png?alt=media',
            division: 'Southeast',
            conference: 'Eastern Conference',
        },
        homeTeam: {
            teamName: 'Detroit',
            teamId: 5378,
            abbr: 'DET',
            mascot: 'Pistons',
            fullName: 'Detroit Pistons',
            shortName: '',
            pitcherData: '',
            color: '#C8102E',
            odds: {
                fullGame: {
                    moneyline: 150,
                    spread: 4.5,
                },
            },
            openMoneyLine: 145,
            spreadPercent: 17,
            mlPercent: 17,
            mlMoneyPercent: 0,
            spreadMoneyPercent: 27,
            injuries: [
                {
                    player: 'Tobias Harris',
                    position: 'F',
                    date: 'Dec 19',
                    status: 'Out',
                    desc: "Dec 15: Harris (hand) has been ruled out for Monday's game against the Heat.",
                    value: 0,
                },
            ],
            stats: {
                def: {
                    defEff: 1.105,
                    fouls: 19.7,
                    oppThreePoint: 37.7,
                    ppg: 113.4,
                    reboundPercent: 51.4,
                },
                off: {
                    floorPercent: 48.6,
                    freeThrow: 76.5,
                    offEff: 1.072,
                    oppg: 110.1,
                    threePoint: 35.6,
                },
                splits: {},
            },
            averageMoneylines: {
                dog: {
                    avgML: 295,
                    totalGames: 17,
                },
                favorite: {
                    avgML: -157.85714285714286,
                    totalGames: 7,
                },
            },
            recordData: {
                ats: '12-13-1',
                awayRecord: '6-9',
                conferenceRecord: '9-14',
                divisionRecord: '1-5',
                homeRecord: '4-7',
                ou: '12-13-1',
                record: '10-16',
            },
            sos: 16,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nba%2F5378_nba.png?alt=media',
            division: 'Central',
            conference: 'Eastern Conference',
        },
        gameId: '2024-12-17T01:00:00.000Z-5378-123404',
        dateTime: '2024-12-17T01:00:00.000Z',
        status: 'not started',
        bookData: {
            moneylines: [
                {
                    awayLine: -185,
                    homeLine: 150,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: -180,
                    homeLine: 150,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
                {
                    awayLine: -194,
                    homeLine: 162,
                    label: 'FanDuel',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                    color: '#2192f5',
                },
            ],
            spreads: [
                {
                    awayLine: -4.5,
                    homeLine: 4.5,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: -4.5,
                    homeLine: 4.5,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
                {
                    awayLine: -4.5,
                    homeLine: 4.5,
                    label: 'FanDuel',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                    color: '#2192f5',
                },
            ],
        },
        openingSpread: 4,
        headlines: [
            {
                title: 'MIAMI HEAT VS DETROIT PISTONS PREDICTION AND BETTING TIPS | DEC. 16, 2024SPORTSKEEDA\n5 HOURS AGO',
                href: 'https://www.google.com/url?q=https://www.sportskeeda.com/basketball/miami-heat-vs-detroit-pistons-prediction-betting-tips-dec-16-2024&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQ0PADegQIDBAD&usg=AOvVaw0eZdIRVJBfoB7-7JP_0JvS',
                description: 'Winners & Whiners\n17 hours ago',
            },
            {
                title: 'BETTING ODDS & PREDICTION FOR DETROIT PISTONS VS MIAMI HEAT ON MONDAYSPORTS ILLUSTRATED\n3 HOURS AGO',
                href: 'https://www.google.com/url?q=https://www.si.com/nba/pistons/news/betting-odds-prediction-for-detroit-pistons-vs-miami-heat-on-monday-12-16-24&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQ0PADegQIDBAH&usg=AOvVaw0zW21hUp7L9nhdntWoKEso',
                description: 'Winners & Whiners\n17 hours ago',
            },
            {
                title: 'MIAMI HEAT VS DETROIT PISTONS PREDICTION, ODDS AND PICKS FOR TOMORROW, 12/16/24PICKSWISE\n7 HOURS AGO',
                href: 'https://www.google.com/url?q=https://www.pickswise.com/nba/predictions/miami-heat-vs-detroit-pistons-predictions-442296/&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQ0PADegQIDBAL&usg=AOvVaw29jb2xBN38FqORRTpwayZj',
                description: 'Winners & Whiners\n17 hours ago',
            },
            {
                title: 'HEAT VS. PISTONS PREDICTION, ODDS, PICK - 12/16/2024CLUTCHPOINTS\n50 MINUTES AGO',
                href: 'https://www.google.com/url?q=https://clutchpoints.com/heat-vs-pistons-prediction-odds-pick-12-16-2024&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQ0PADegQIDBAP&usg=AOvVaw1dAsqdqR9FA0KOENdHezCH',
                description: 'Winners & Whiners\n17 hours ago',
            },
            {
                title: 'MIAMI HEAT VS DETROIT PISTONS BETTING PICK & PREDICTION 12/16/24BANG THE BOOK\n3 HOURS AGO',
                href: 'https://www.google.com/url?q=https://bangthebook.com/miami-heat-vs-detroit-pistons-betting-pick-prediction-12-16-24&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQ0PADegQIDBAT&usg=AOvVaw0R2ocSFF9wWIjLomcUu_20',
                description: 'Winners & Whiners\n17 hours ago',
            },
            {
                title: 'MIAMI HEAT VS DETROIT PISTONS PREDICTION, BET BUILDER TIPS & ODDSSPORTSGAMBLER\n10 HOURS AGO',
                href: 'https://www.google.com/url?q=https://www.sportsgambler.com/betting-tips/basketball/miami-heat-vs-detroit-pistons-prediction-bet-builder-tips-odds-2024-12-16/&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQ0PADegQIDBAX&usg=AOvVaw2fVqsUFTj2mHC31bs7voRG',
                description: 'Winners & Whiners\n17 hours ago',
            },
            {
                title: 'HEAT VS. PISTONS PREDICTIONS, ODDS & STATS – DECEMBER 16IHEART\n11 HOURS AGO',
                href: 'https://www.google.com/url?q=https://sports.iheart.com/content/2024-12-16-heat-vs-pistons-predictions-odds-stats-december-16/&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQ0PADegQIDBAb&usg=AOvVaw1GcGBJB3NzPonriovsQgfk',
                description: 'Winners & Whiners\n17 hours ago',
            },
            {
                title: 'MIAMI HEAT VS DETROIT PISTONS PREDICTION & PICKS - NBASPORTYTRADER\n16 HOURS AGO',
                href: 'https://www.google.com/url?q=https://www.sportytrader.com/us/picks/miami-heat-detroit-pistons-234287/&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQ0PADegQIDBAf&usg=AOvVaw3CEEo-FAXu2X1BOZ9sK0Se',
                description: 'Winners & Whiners\n17 hours ago',
            },
            {
                title: 'HEAT VS PISTONS PREDICTION AND PICKS FOR DECEMBER 16 2024WINNERS & WHINERS\n17 HOURS AGO',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/nba/12-16-2024/miami-heat-vs-detroit-pistons-prediction-8762/&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQ0PADegQIDBAj&usg=AOvVaw3DmAL2IDdlTbwVZ3C0nBLS',
                description: 'Winners & Whiners\n17 hours ago',
            },
            {
                title: 'BETTING ODDS & PREDICTION FOR DETROIT PISTONS VS MIAMI HEAT ON MONDAYWWW.SI.COM › HOME › NEWS',
                href: 'https://www.google.com/url?q=https://www.si.com/nba/pistons/news/betting-odds-prediction-for-detroit-pistons-vs-miami-heat-on-monday-12-16-24&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQFnoECAcQAg&usg=AOvVaw1IewENhyrF1_2bmEeLQ1iJ',
                description:
                    '3 hours ago   Detroit Pistons vs Miami Heat. Date: Monday, December 16, 2024. Time: 7 PM ET. Location: Little Ceasars Arena. Betting Odds. Spread: Heat -4.5.',
            },
            {
                title: 'MIAMI HEAT VS DETROIT PISTONS PREDICTION, ODDS AND PICKS | PICKSWISEWWW.PICKSWISE.COM › NBA › NBA PREDICTIONS',
                href: 'https://www.google.com/url?q=https://www.pickswise.com/nba/predictions/miami-heat-vs-detroit-pistons-predictions-442296/&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQFnoECAsQAg&usg=AOvVaw1-8rbv__FbBIk47ru-R8_0',
                description:
                    '7 hours ago   Other than that game, Miami has had a lot of success at Detroit winning 6 of 8 games, while overall they are 7-1 SU over the past 8 meetings.',
            },
            {
                title: 'MIAMI HEAT VS DETROIT PISTONS PREDICTION, BET BUILDER TIPS & ODDSWWW.SPORTSGAMBLER.COM › BETTING-TIPS › BASKETBALL › MIAMI-HEAT-VS-DETROIT...',
                href: 'https://www.google.com/url?q=https://www.sportsgambler.com/betting-tips/basketball/miami-heat-vs-detroit-pistons-prediction-bet-builder-tips-odds-2024-12-16/&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQFnoECAUQAg&usg=AOvVaw01lq4geIIvwYmuX_Rb5rZf',
                description:
                    '10 hours ago   With a 66% chance of securing victory, Miami Heat are favorites for this NBA game and the sportsbooks have them at betting odds of -196. At +166 ...',
            },
            {
                title: 'HEAT VS PISTONS PREDICTION AND PICKS FOR DECEMBER 16 2024WINNERSANDWHINERS.COM › MIAMI-HEAT-VS-DETROIT-PISTONS-PREDICTION-8762',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/nba/12-16-2024/miami-heat-vs-detroit-pistons-prediction-8762/&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQFnoECAQQAg&usg=AOvVaw1tp7tYCaisZamWbe8L8SnZ',
                description:
                    '17 hours ago   Miami ranks 26th in pace, while Detroit ranks 20th, indicating bettors should expect a low-possession game on Monday.',
            },
            {
                title: 'HEAT VS. PISTONS PREDICTION, ODDS, PICK - 12/16/2024 - CLUTCHPOINTSCLUTCHPOINTS.COM › HEAT-VS-PISTONS-PREDICTION-ODDS-PICK-12-16-2024',
                href: 'https://www.google.com/url?q=https://clutchpoints.com/heat-vs-pistons-prediction-odds-pick-12-16-2024&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQFnoECAYQAg&usg=AOvVaw3397bw1IbVxbDMBRy1krzi',
                description:
                    '50 minutes ago   The Heat are 11-12 against the spread, while the Pistons are 12-13-1 against the odds. Moreover, the Heat are 5-6 against the spread on the road ...',
            },
            {
                title: 'MIAMI HEAT VS DETROIT PISTONS BETTING PICK & PREDICTION 12/16/24BANGTHEBOOK.COM › MIAMI-HEAT-VS-DETROIT-PISTONS-BETTING-PICK-PREDICTION-...',
                href: 'https://www.google.com/url?q=https://bangthebook.com/miami-heat-vs-detroit-pistons-betting-pick-prediction-12-16-24&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQFnoECAkQAg&usg=AOvVaw2Da90SCgH1jQl2kC1o-iAL',
                description:
                    '3 hours ago   At 7:00 ET, the Miami Heat will travel to Detroit to take on the Pistons. Miami is favored by 5 points and has a moneyline of -193.',
            },
            {
                title: 'HEAT VS. PISTONS PREDICTIONS, ODDS & STATS – DECEMBER 16SPORTS.IHEART.COM › CONTENT › 2024-12-16-HEAT-VS-PISTONS-PREDICTIONS-ODDS-...',
                href: 'https://www.google.com/url?q=https://sports.iheart.com/content/2024-12-16-heat-vs-pistons-predictions-odds-stats-december-16/&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQFnoECAoQAg&usg=AOvVaw0FaAlXGOuKXZsVmsvwUAxH',
                description:
                    '11 hours ago   The Miami Heat (13-10) will look to extend a four-game win streak when they visit the Detroit Pistons (10-16) on Monday, December 16, 2024 ...',
            },
            {
                title: 'DETROIT PISTONS VS MIAMI HEAT PREDICTION 12-16-24 NBA PICKSSPORTSCHATPLACE.COM › NBA PICKS',
                href: 'https://www.google.com/url?q=https://sportschatplace.com/nba-picks/detroit-pistons-vs-miami-heat-prediction-12-16-24-nba-picks/&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQFnoECAgQAg&usg=AOvVaw1jG1jS4WvQ0mm1enXxNXD4',
                description:
                    '10 hours ago   Miami Heat (13-10) vs. Detroit Pistons (10-16) December 16, 2024 7:00 pm EDT The Line: Detroit Pistons +4.5; Over/Under: +217',
            },
            {
                title: 'MIAMI HEAT VS DETROIT PISTONS PICKS & PREDICTION - NBA - SPORTYTRADERWWW.SPORTYTRADER.COM › HOME PAGE › SPORTS PICKS › BASKETBALL › NBA',
                href: 'https://www.google.com/url?q=https://www.sportytrader.com/us/picks/miami-heat-detroit-pistons-234287/&sa=U&ved=2ahUKEwi5kvGExayKAxXIM0QIHVY_NesQFnoECAMQAg&usg=AOvVaw0BpwgfI0Gka8OcuYaY4WFJ',
                description:
                    '16 hours ago   Miami Heat vs Detroit Pistons picks and predictions for the 12/16/24 NBA game, with match analysis and H2H stats from Basketball experts.',
            },
        ],
        total: 219.5,
        totalStats: {
            total: 219.5,
            over: 8,
            under: 10,
        },
        advantages: [
            {
                adv: 'Key Factor - Floor',
                id: 'key-factor',
                factorId: 'floorPercent',
                winPercent: 49.72067039106145,
                teamId: 123404,
                description: 'MIA has a better Floor.  Teams with a better Floor win at a 49% rate.',
                amount: 1.1000000000000014,
            },
            {
                adv: 'Key Factor - 3 Pt.',
                id: 'key-factor',
                factorId: 'threePoint',
                winPercent: 48.9010989010989,
                teamId: 123404,
                description: 'MIA has a better 3 Pt..  Teams with a better 3 Pt. win at a 48% rate.',
                amount: 2.799999999999997,
            },
            {
                adv: 'Key Factor - FT',
                id: 'key-factor',
                factorId: 'freeThrow',
                winPercent: 46.994535519125684,
                teamId: 123404,
                description: 'MIA has a better FT.  Teams with a better FT win at a 46% rate.',
                amount: 1.0999999999999943,
            },
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 47.25274725274725,
                teamId: 123404,
                description: 'MIA has a better PPG.  Teams with a better PPG win at a 47% rate.',
                amount: 2.200000000000003,
            },
            {
                adv: 'Key Factor - oEff%',
                id: 'key-factor',
                factorId: 'offEff',
                winPercent: 48.21917808219178,
                teamId: 123404,
                description: 'MIA has a better oEff%.  Teams with a better oEff% win at a 48% rate.',
                amount: 0.04200000000000004,
            },
            {
                adv: 'Key Factor - Rebound',
                id: 'key-factor',
                factorId: 'reboundPercent',
                winPercent: 50,
                teamId: 5378,
                description: 'DET has a better Rebound.  Teams with a better Rebound win at a 50% rate.',
                amount: 0.7999999999999972,
            },
            {
                adv: 'Key Factor - dPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 52.87671232876713,
                teamId: 123404,
                description: 'MIA has a better dPPG.  Teams with a better dPPG win at a 52% rate.',
                amount: 4.400000000000006,
            },
            {
                adv: 'Key Factor - Def. Eff%',
                id: 'key-factor',
                factorId: 'defEff',
                winPercent: 46.849315068493155,
                teamId: 5378,
                description: 'DET has a better Def. Eff%.  Teams with a better Def. Eff% win at a 46% rate.',
                amount: 0.02299999999999991,
            },
            {
                adv: 'Key Factor - Fouls PG',
                id: 'key-factor',
                factorId: 'fouls',
                winPercent: 46.28099173553719,
                teamId: 123404,
                description: 'MIA has a better Fouls PG.  Teams with a better Fouls PG win at a 46% rate.',
                amount: 3.599999999999998,
            },
            {
                adv: 'Key Factor - 3 Point',
                id: 'key-factor',
                factorId: 'oppThreePoint',
                winPercent: 49.44444444444444,
                teamId: 123404,
                description: 'MIA has a better 3 Point.  Teams with a better 3 Point win at a 49% rate.',
                amount: 1.8000000000000043,
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'DET has an advantage based on our power rank data.',
                teamId: 5378,
                amount: 0,
            },
        ],
        prediction: {
            awayScore: 113.74,
            homeScore: 112.15,
            winner: 123404,
            spreadWinner: 5378,
            powerSpread: 1.5899999999999892,
            powerMoneyline: {
                dogML: 132,
                favML: -135,
            },
            powerLineWinner: 5378,
        },
        situations: [
            {
                id: 'division-game',
                label: 'Division Game',
                value: false,
                description: 'This is a Central division game.',
                type: 'other',
            },
            {
                id: 'conference-game',
                label: 'Conference Game',
                value: true,
                description: 'This is a Eastern Conference conference game.',
                type: 'other',
            },
            {
                id: 'line-movement',
                label: 'Line Movement',
                teamId: 5378,
                description: 'The line is on the move in favor of Detroit.',
                type: 'team',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 123404,
                description: 'Miami win 50.00% of games on Mon.',
                type: 'team',
                queryString: '?subpage=trends&trend=team-days-of-week&team=123404',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 5378,
                description: 'Detroit win 50.00% of games on Mon.',
                type: 'team',
                queryString: '?subpage=trends&trend=team-days-of-week&team=5378',
            },
        ],
    },
    nfl: {
        awayTeam: {
            teamName: 'Tampa Bay',
            teamId: 4156,
            abbr: 'TAM',
            mascot: 'Buccaneers',
            fullName: 'Tampa Bay Buccaneers',
            shortName: 'TB',
            pitcherData: '',
            color: '#D50A0A',
            odds: {
                fullGame: {
                    moneyline: -185,
                    spread: -3.5,
                },
                half: {
                    spread: -2,
                    moneyline: -135,
                },
            },
            openMoneyLine: 120,
            spreadPercent: 53,
            mlPercent: 52,
            mlMoneyPercent: 72,
            spreadMoneyPercent: 67,
            injuries: [
                {
                    player: 'Antoine Winfield Jr.',
                    position: 'S',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Trey Palmer',
                    position: 'WR',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Jalen McMillan',
                    position: 'WR',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Calijah Kancey',
                    position: 'DT',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Luke Goedeke',
                    position: 'OT',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Cody Thompson',
                    position: 'WR',
                    date: 'Nov 10',
                    status: 'Out',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Markees Watts',
                    position: 'LB',
                    date: 'Nov 10',
                    status: 'Injured Reserve',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'SirVocea Dennis',
                    position: 'LB',
                    date: 'Nov 4',
                    status: 'Injured Reserve',
                    desc: 'Oct 4: Buccaneers head coach Todd Bowles said Friday that Allen (shoulder), who was placed on IR on Tuesday, will have his status evaluated week-to-week, Greg Auman of Fox Sports reports.',
                    value: 0,
                },
                {
                    player: 'Jordan Whitehead',
                    position: 'S',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: "Oct 3: Whitehead (groin) is questionable to return to Thursday's game against the Falcons, Brianna Dix of the Buccaneers' official site reports.",
                    value: 0,
                },
                {
                    player: 'Kameron Johnson',
                    position: 'WR',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: "Oct 3: Johnson (ankle) is questionable to return to Thursday's game against the Falcons, Scott Smith of the Buccaneers' official site reports.",
                    value: 0,
                },
                {
                    player: 'Evan Deckers',
                    position: 'LS',
                    date: 'Oct 27',
                    status: 'Injured Reserve',
                    desc: 'Sep 24: Deckers (hamstring) was placed on injured reserve Tuesday, Rick Stroud of the Tampa Bay Times reports.',
                    value: 0,
                },
                {
                    player: 'Bryce Hall',
                    position: 'CB',
                    date: 'Dec 1',
                    status: 'Injured Reserve',
                    desc: 'Sep 10: The Buccaneers placed Hall (ankle) on injured reserve Tuesday, Jenna Laine of ESPN.com reports.',
                    value: 0,
                },
                {
                    player: 'Earnest Brown IV',
                    position: 'DE',
                    date: 'Oct 13',
                    status: 'Injured Reserve',
                    desc: 'Sep 5: The Buccaneers placed Brown on the reserve/injured list Thursday due to a rib injury, Aaron Wilson of KPRC 2 Houston reports.',
                    value: 0,
                },
                {
                    player: 'Chase Edmonds',
                    position: 'RB',
                    date: 'Feb 10',
                    status: 'Injured Reserve',
                    desc: 'Aug 27: Edmonds was placed on season-ending injured reserve Monday due to a knee injury, Greg Auman of Fox Sports reports.',
                    value: 0,
                },
                {
                    player: 'Rakim Jarrett',
                    position: 'WR',
                    date: 'Oct 13',
                    status: 'Injured Reserve',
                    desc: 'Aug 26: Tampa Bay placed Jarrett (undisclosed) on its injured reserve list with a designation to return Monday, Greg Auman of Fox Sports reports.',
                    value: 0,
                },
                {
                    player: 'Silas Dzansi',
                    position: 'OT',
                    date: 'Feb 10',
                    status: 'Injured Reserve',
                    desc: "Aug 8: The Buccaneers placed Dzansi on injured reserve Thursday due to a knee injury, Scott Smith of the team's official site reports.",
                    value: 0,
                },
                {
                    player: 'Sua Opeta',
                    position: 'G',
                    date: 'Feb 10',
                    status: 'Injured Reserve',
                    desc: 'Aug 2: Tampa Bay placed Opeta on injured reserve Friday due to his torn ACL, Rick Stroud of the Tampa Bay Times reports.',
                    value: 0,
                },
            ],
            stats: {
                def: {
                    ppg: 22.8,
                    pypg: 258.4,
                    rypg: 119.8,
                    ypg: 378.2,
                },
                off: {
                    avgPenaltyYards: 33,
                    oppg: 25.4,
                    thirdDown: 44.83,
                    toRatio: 2,
                    top: 30,
                    ypg: 341.6,
                },
                splits: {},
            },
            averageMoneylines: {
                dog: {
                    avgML: 210,
                    totalGames: 2,
                },
                favorite: {
                    avgML: -235,
                    totalGames: 2,
                },
            },
            recordData: {
                ats: '3-2-0',
                awayRecord: '1-1',
                conferenceRecord: '3-1',
                divisionRecord: '0-1',
                homeRecord: '2-1',
                ou: '3-2-0',
                record: '3-2',
            },
            sos: 12,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nfl%2F4156_nfl.png?alt=media',
            division: 'South',
            conference: 'National Football Conference',
        },
        homeTeam: {
            teamName: 'New Orleans',
            teamId: 5437,
            abbr: 'NOR',
            mascot: 'Saints',
            fullName: 'New Orleans Saints',
            shortName: 'New Orleans',
            pitcherData: '',
            color: '#D3BC8D',
            odds: {
                fullGame: {
                    moneyline: 155,
                    spread: 3.5,
                },
                half: {
                    spread: 2,
                    moneyline: 115,
                },
            },
            openMoneyLine: -140,
            spreadPercent: 47,
            mlPercent: 48,
            mlMoneyPercent: 28,
            spreadMoneyPercent: 33,
            injuries: [
                {
                    player: 'Derek Carr',
                    position: 'QB',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: 'Oct 8: Carr will undergo an MRI on his left oblique Tuesday, Katherine Terrell of ESPN.com reports.',
                    value: 0,
                },
                {
                    player: 'Payton Turner',
                    position: 'DE',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Cesar Ruiz',
                    position: 'C',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Taysom Hill',
                    position: 'TE',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Willie Gay',
                    position: 'LB',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Will Harris',
                    position: 'CB',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: "Oct 7: Harris exited New Orleans' matchup versus the Chiefs on Monday due to an undisclosed injury, Jeff Nowak of Audacy Sports reports.",
                    value: 0,
                },
                {
                    player: 'Pete Werner',
                    position: 'LB',
                    date: 'Oct 13',
                    status: 'Questionable',
                    desc: "Oct 7: Werner (hamstring) is inactive ahead of Monday night's matchup against the Chiefs.",
                    value: 0,
                },
                {
                    player: 'Shane Lemieux',
                    position: 'C',
                    date: 'Nov 3',
                    status: 'Injured Reserve',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Kendre Miller',
                    position: 'RB',
                    date: 'Oct 13',
                    status: 'Injured Reserve',
                    desc: "Oct 7: Miller (hamstring) won't suit up Monday against the Chiefs, Ross Jackson of LouisianaSports.net reports.",
                    value: 0,
                },
                {
                    player: 'Erik McCoy',
                    position: 'C',
                    date: 'Oct 27',
                    status: 'Injured Reserve',
                    desc: 'Sep 25: McCoy (groin) was placed on injured reserve Wednesday, Ross Jackson of WWL Louisiana reports.',
                    value: 0,
                },
                {
                    player: 'Rejzohn Wright',
                    position: 'CB',
                    date: 'Feb 10',
                    status: 'Injured Reserve',
                    desc: 'Aug 29: Wright (undisclosed) was placed on injured reserve by the Saints on Tuesday.',
                    value: 0,
                },
                {
                    player: 'Nephi Sewell',
                    position: 'LB',
                    date: 'Oct 13',
                    status: 'Out',
                    desc: 'Aug 29: Sewell (knee) was placed on the reserve/PUP list by the Saints on Tuesday.',
                    value: 0,
                },
                {
                    player: 'Camron Peterson',
                    position: 'DT',
                    date: 'Feb 10',
                    status: 'Injured Reserve',
                    desc: 'Aug 28: Peterson (undisclosed) was placed on injured reserve by the Saints on Tuesday.',
                    value: 0,
                },
                {
                    player: 'Tanoh Kpassagnon',
                    position: 'DE',
                    date: 'Oct 13',
                    status: 'Out',
                    desc: 'Aug 27: Kpassagnon (Achilles) was placed on the reserve/PUP list by the Saints on Tuesday.',
                    value: 0,
                },
                {
                    player: 'Justin Herron',
                    position: 'OT',
                    date: 'Feb 10',
                    status: 'Injured Reserve',
                    desc: 'Jul 28: The Saints placed Herron on the injured reserve list Sunday due to a knee injury.',
                    value: 0,
                },
                {
                    player: 'Ryan Ramczyk',
                    position: 'OT',
                    date: 'Feb 10',
                    status: 'Out',
                    desc: 'Jul 18: The Saints placed Ramczyk (knee) on reserve/PUP Thursday, and he will miss the entirety of the 2024 season, Ian Rapoport of NFL Network reports.',
                    value: 0,
                },
            ],
            stats: {
                def: {
                    ppg: 19.2,
                    pypg: 251.2,
                    rypg: 105,
                    ypg: 356.2,
                },
                off: {
                    avgPenaltyYards: 58,
                    oppg: 28,
                    thirdDown: 50.88,
                    toRatio: 3,
                    top: 30,
                    ypg: 328.4,
                },
                splits: {},
            },
            averageMoneylines: {
                dog: {
                    avgML: 185,
                    totalGames: 3,
                },
                favorite: {
                    avgML: -170,
                    totalGames: 2,
                },
            },
            recordData: {
                ats: '3-2-0',
                awayRecord: '1-2',
                conferenceRecord: '2-2',
                divisionRecord: '1-1',
                homeRecord: '1-1',
                ou: '3-2-0',
                record: '2-3',
            },
            sos: 8,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nfl%2F5437_nfl.png?alt=media',
            division: 'South',
            conference: 'National Football Conference',
        },
        gameId: '2024-10-13T18:00:00.000Z-5437-4156',
        dateTime: '2024-10-13T18:00:00.000Z',
        status: 'not started',
        bookData: {
            moneylines: [
                {
                    awayLine: -200,
                    homeLine: 165,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: -175,
                    homeLine: 145,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
                {
                    awayLine: -186,
                    homeLine: 156,
                    label: 'FanDuel',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                    color: '#2192f5',
                },
            ],
            spreads: [
                {
                    awayLine: -4,
                    homeLine: 4,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: -3.5,
                    homeLine: 3.5,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
                {
                    awayLine: -3.5,
                    homeLine: 3.5,
                    label: 'FanDuel',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                    color: '#2192f5',
                },
            ],
        },
        openingSpread: 2.5,
        headlines: [
            {
                title: 'TAMPA BAY BUCCANEERS VS NEW ORLEANS SAINTS PICKS, ODDS FOR NFL ...WWW.AZCENTRAL.COM › STORY › SPORTS › NFL › 2024/10/07 › TAMPA-BAY-BUCCA...',
                href: 'https://www.google.com/url?q=https://www.azcentral.com/story/sports/nfl/2024/10/07/tampa-bay-buccaneers-new-orleans-saints-picks-nfl-week-6/75487144007/&sa=U&ved=2ahUKEwibsvP3yYGJAxUyg4kEHXwGCeEQFnoECAUQAg&usg=AOvVaw0awMpu9t8bnHHHwwq01pSe',
                description:
                    '2 days ago   The Saints are a 2.5-point favorite over the Buccaneers in NFL Week 6 odds for the game, courtesy of BetMGM Sportsbook. New Orleans is -145 on ...',
            },
            {
                title: 'TAMPA BAY BUCCANEERS VS NEW ORLEANS SAINTS PREDICTION NFL ...PICKDAWGZ.COM › NFL-PICKS › TAMPA-BAY-BUCCANEERS-VS-NEW-ORLEANS-SAINTS-...',
                href: 'https://www.google.com/url?q=https://pickdawgz.com/nfl-picks/2024-10-13/tampa-bay-buccaneers-vs-new-orleans-saints-prediction-nfl-picks&sa=U&ved=2ahUKEwibsvP3yYGJAxUyg4kEHXwGCeEQFnoECAgQAg&usg=AOvVaw0vTxoS64pUIEKhFtz12E-K',
                description:
                    "20 hours ago   I actually think the Saints win this game outright, but we will take the points to be safe. Back the Saints. Shane's Pick Saints +2.5. Get ...",
            },
            {
                title: 'TAMPA BAY BUCCANEERS VS NEW ORLEANS SAINTS PREDICTION, ODDS ...WWW.PICKSWISE.COM › NFL › NFL PREDICTIONS',
                href: 'https://www.google.com/url?q=https://www.pickswise.com/nfl/predictions/tampa-bay-buccaneers-vs-new-orleans-saints-predictions-433418/&sa=U&ved=2ahUKEwibsvP3yYGJAxUyg4kEHXwGCeEQFnoECAYQAg&usg=AOvVaw2RFXHtwOG4mCsiU4qH17H1',
                description:
                    "23 minutes ago   The Bucs have scored 30+ points in back-to-back weeks, so I'll back the over in New Orleans. Buccaneers vs Saints prediction: Over 41.5 (-110) ...",
            },
            {
                title: 'BUCCANEERS VS. SAINTS PREDICTION, ODDS, SPREAD, INJURIES, TRENDS FOR ...WWW.SI.COM › BETTING › BUCCANEERS-VS-SAINTS-PREDICTION-ODDS-SPREAD-INJURI...',
                href: 'https://www.google.com/url?q=https://www.si.com/betting/buccaneers-vs-saints-prediction-odds-spread-injuries-trends-for-nfl-week-6-01j9q23qryrm&sa=U&ved=2ahUKEwibsvP3yYGJAxUyg4kEHXwGCeEQFnoECAAQAg&usg=AOvVaw1jL6e-PZGbil6BmpGqxj6z',
                description:
                    '17 hours ago   Buccaneers vs. Saints Prediction and Pick   Pick: Buccaneers -3 (-120)   Odds refresh periodically and are subject to change.   If you or someone ...',
            },
            {
                title: 'SAINTS VS BUCCANEERS PREDICTION AND PREVIEW - NFL WEEK 6, 2024WWW.STATSINSIDER.COM.AU › NEWS › SAINTS-VS-BUCCANEERS-PREDICTION-AND-PRE...',
                href: 'https://www.google.com/url?q=https://www.statsinsider.com.au/news/saints-vs-buccaneers-prediction-and-preview-nfl-week-6-2024&sa=U&ved=2ahUKEwibsvP3yYGJAxUyg4kEHXwGCeEQFnoECAMQAg&usg=AOvVaw0J3VMFSPJPVtdZcwJVCRqa',
                description:
                    '3 days ago   Our independent predictive analytics model gives the Buccaneers a 62% chance of winning against the Saints at Caesars Superdome. Saints vs ...',
            },
            {
                title: "BUCS' WEEK 6 GAME VS. SAINTS NOW A MUST-WINWWW.SI.COM › HOME › TAMPA BAY BUCCANEERS NEWS",
                href: 'https://www.google.com/url?q=https://www.si.com/nfl/buccaneers/news/tampa-bay-buccaneers-week-6-game-vs-new-orleans-saints-now-a-must-win&sa=U&ved=2ahUKEwibsvP3yYGJAxUyg4kEHXwGCeEQFnoECAcQAg&usg=AOvVaw0Dj1DH6UWvsjA4aR7SyBl2',
                description: '2 days ago   The Tampa Bay Buccaneers need to beat the New Orleans Saints to get a grip on the NFC South.',
            },
            {
                title: 'SAINTS VS BUCCANEERS EXPERT PICK AND PREDICTION – OCTOBER 13FREEPICKS.PICKSANDPARLAYS.NET › MORE-FREE-PICKS › NFL-PICKS › TAMPA-BAY-B...',
                href: 'https://www.google.com/url?q=https://freepicks.picksandparlays.net/more-free-picks/nfl-picks/tampa-bay-buccaneers-at-new-orleans-saints-picks-and-predictions-10-13-24&sa=U&ved=2ahUKEwibsvP3yYGJAxUyg4kEHXwGCeEQFnoECAIQAg&usg=AOvVaw3Hy8TekuedJO6I3miwwPPE',
                description:
                    '20 hours ago   Final Score Prediction, Tampa Bay Buccaneers win and cover ATS 27-21. NFL Hot Hand. Picks From ONLY our Hot Cappers. Buy Now - $59.',
            },
            {
                title: 'THREAD: NEW ORLEANS SAINTS VS. TAMPA BAY BUCCANEERS FREE NFL PICKSFORUMEUS.COM › SHOWTHREAD.PHP › 87933-NEW-ORLEANS-SAINTS-VS-TAMPA-B...',
                href: 'https://www.google.com/url?q=https://forumeus.com/showthread.php/87933-New-Orleans-Saints-vs-Tampa-Bay-Buccaneers-free-nfl-picks-football-predictions&sa=U&ved=2ahUKEwibsvP3yYGJAxUyg4kEHXwGCeEQFnoECAQQAg&usg=AOvVaw3xQG9fD5r2uosNpASVVwpa',
                description:
                    'The New Orleans Saints and the Tampa Bay Buccaneers will both be trying to pick up a win on Sunday when they battle at Raymond James Stadium.',
            },
        ],
        total: 41.5,
        totalStats: {
            total: 41.5,
            over: 3,
            under: 4,
        },
        advantages: [
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 50,
                teamId: 5437,
                description: 'NOR has a better PPG.  Teams with a better PPG win at a 50% rate.',
                amount: 2.6000000000000014,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 48.333333333333336,
                teamId: 5437,
                description: 'NOR has a better DPPG.  Teams with a better DPPG win at a 48% rate.',
                amount: 3.6000000000000014,
            },
            {
                adv: 'Key Factor - YPG',
                id: 'key-factor',
                factorId: 'ypg',
                winPercent: 48.38709677419355,
                teamId: 4156,
                description: 'TAM has a better YPG.  Teams with a better YPG win at a 48% rate.',
                amount: 13.200000000000045,
            },
            {
                adv: 'Key Factor - toRatio',
                id: 'key-factor',
                factorId: 'toRatio',
                winPercent: 46.15384615384615,
                teamId: 5437,
                description: 'NOR has a better toRatio.  Teams with a better toRatio win at a 46% rate.',
                amount: 1,
            },
            {
                adv: 'Key Factor - 3rd Down',
                id: 'key-factor',
                factorId: 'thirdDown',
                winPercent: 48.38709677419355,
                teamId: 5437,
                description: 'NOR has a better 3rd Down.  Teams with a better 3rd Down win at a 48% rate.',
                amount: 6.050000000000004,
            },
            {
                adv: 'Key Factor - TOP',
                id: 'key-factor',
                factorId: 'top',
                winPercent: 51.66666666666667,
                teamId: 0,
                description: '',
                amount: 0,
            },
            {
                adv: 'Key Factor - Penalties',
                id: 'key-factor',
                factorId: 'avgPenaltyYards',
                winPercent: 55.73770491803278,
                teamId: 4156,
                description: 'TAM has a better Penalties.  Teams with a better Penalties win at a 55% rate.',
                amount: 25,
            },
            {
                adv: 'Key Factor - RYPG',
                id: 'key-factor',
                factorId: 'rypg',
                winPercent: 48.38709677419355,
                teamId: 5437,
                description: 'NOR has a better RYPG.  Teams with a better RYPG win at a 48% rate.',
                amount: 14.799999999999997,
            },
            {
                adv: 'Key Factor - PYPG',
                id: 'key-factor',
                factorId: 'pypg',
                winPercent: 46.774193548387096,
                teamId: 5437,
                description: 'NOR has a better PYPG.  Teams with a better PYPG win at a 46% rate.',
                amount: 7.199999999999989,
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'NOR has an advantage based on our power rank data.',
                teamId: 5437,
                amount: 0,
            },
        ],
        prediction: {
            awayScore: 23.79,
            homeScore: 27.16,
            winner: 5437,
            spreadWinner: 5437,
            powerSpread: 3.370000000000001,
            powerMoneyline: {
                dogML: 169,
                favML: -179,
            },
            powerLineWinner: 5437,
        },
        situations: [
            {
                id: 'division-game',
                label: 'Division Game',
                value: true,
                description: 'This is a South division game.',
                type: 'other',
            },
            {
                id: 'conference-game',
                label: 'Conference Game',
                value: true,
                description: 'This is a National Football Conference conference game.',
                type: 'other',
            },
            {
                id: 'line-movement',
                label: 'Line Movement',
                teamId: 5437,
                description: 'The line is on the move in favor of New Orleans.',
                type: 'team',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 4156,
                description: 'Tampa Bay win 75.00% of games on Sun.',
                type: 'team',
                queryString: '?subpage=trends&trend=team-days-of-week&team=4156',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 5437,
                description: 'New Orleans win 50.00% of games on Sun.',
                type: 'team',
                queryString: '?subpage=trends&trend=team-days-of-week&team=5437',
            },
        ],
    },
    cfb: {
        awayTeam: {
            teamName: 'Clemson',
            teamId: 201,
            abbr: 'CLEM',
            mascot: '',
            fullName: 'Clemson Tigers',
            shortName: '',
            pitcherData: '',
            color: '#F56600',
            odds: {
                fullGame: {
                    spread: 11.5,
                    moneyline: 345,
                },
                half: {
                    spread: 6,
                    moneyline: 250,
                },
            },
            openMoneyLine: 315,
            spreadPercent: 45,
            mlPercent: 0,
            spreadMoneyPercent: 24,
            injuries: [
                {
                    player: 'T.                                                                                    Brown',
                    position: 'WR',
                    date: '',
                    status: 'Questionable',
                    desc: ' Ankle',
                },
                {
                    player: 'J.                                                                                    Haynes',
                    position: 'RB',
                    date: '',
                    status: 'Questionable',
                    desc: ' Leg',
                },
                {
                    player: 'K.                                                                                    McCloud',
                    position: 'LB',
                    date: '',
                    status: 'Out',
                    desc: ' Knee',
                },
                {
                    player: 'D.                                                                                    Pennington',
                    position: 'OL',
                    date: '',
                    status: 'Questionable',
                    desc: ' Undisclosed',
                },
                {
                    player: 'T.                                                                                    Howard',
                    position: 'OL',
                    date: '',
                    status: 'Out',
                    desc: ' Knee',
                },
                {
                    player: 'C.                                                                                    Gipson',
                    position: 'CB',
                    date: '',
                    status: 'Out',
                    desc: ' Undisclosed',
                },
                {
                    player: 'T.                                                                                    Stellato',
                    position: 'WR',
                    date: '',
                    status: 'Out',
                    desc: ' Thumb',
                },
                {
                    player: 'E.                                                                                    Thurmon',
                    position: 'OL',
                    date: '',
                    status: 'Out',
                    desc: ' Ankle',
                },
                {
                    player: 'C.                                                                                    Sadler',
                    position: 'OL',
                    date: '',
                    status: 'Out',
                    desc: ' Foot',
                },
                {
                    player: 'Q.                                                                                    Castner',
                    position: 'K/P',
                    date: '',
                    status: 'Out',
                    desc: ' Leg',
                },
            ],
            stats: {
                def: {
                    ppg: 22.3,
                    pypg: 214.4,
                    rypg: 150.5,
                    ypg: 364.9,
                },
                off: {
                    avgPenaltyYards: 46,
                    oppg: 34.3,
                    thirdDown: 45.16,
                    toRatio: 16,
                    top: 30,
                    ypg: 454.9,
                },
                splits: {},
            },
            averageMoneylines: {
                dog: {
                    avgML: 250,
                    totalGames: 2,
                },
                favorite: {
                    avgML: -911.6666666666666,
                    totalGames: 9,
                },
            },
            recordData: {
                ats: '6-7-0',
                awayRecord: '4-0',
                conferenceRecord: '7-1',
                divisionRecord: '0-0',
                homeRecord: '5-2',
                ou: '5-7-1',
                record: '10-3',
            },
            sos: 23,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/cfb%2F201_cfb.svg?alt=media',
            rank: 13,
        },
        homeTeam: {
            teamName: 'Texas',
            teamId: 8458,
            abbr: 'UT',
            mascot: '',
            fullName: 'Texas Longhorns',
            shortName: 'TEX',
            pitcherData: '',
            color: '#bf5700',
            odds: {
                fullGame: {
                    spread: -11.5,
                    moneyline: -445,
                },
                half: {
                    spread: -6,
                    moneyline: -310,
                },
            },
            openMoneyLine: -410,
            spreadPercent: 54,
            mlPercent: 0,
            spreadMoneyPercent: 75,
            injuries: [
                {
                    player: 'K.                                                                                    Banks',
                    position: 'OL',
                    date: '',
                    status: 'Questionable',
                    desc: ' Undisclosed',
                },
                {
                    player: 'D.                                                                                    Williams',
                    position: 'DB',
                    date: '',
                    status: 'Out',
                    desc: ' Lower Body',
                },
                {
                    player: 'V.                                                                                    Gardner',
                    position: 'RB',
                    date: '',
                    status: 'Out',
                    desc: ' Undisclosed',
                },
                {
                    player: 'T.                                                                                    Owens',
                    position: 'QB',
                    date: '',
                    status: 'Out',
                    desc: ' Lower Body',
                },
                {
                    player: 'C.                                                                                    Clark',
                    position: 'RB',
                    date: '',
                    status: 'Out',
                    desc: ' Achilles',
                },
                {
                    player: 'C.                                                                                    Baxter',
                    position: 'RB',
                    date: '',
                    status: 'Out',
                    desc: ' Knee',
                },
            ],
            stats: {
                def: {
                    ppg: 12.5,
                    pypg: 143.1,
                    rypg: 106.7,
                    ypg: 249.8,
                },
                off: {
                    avgPenaltyYards: 53,
                    oppg: 33.6,
                    thirdDown: 42.61,
                    toRatio: 6,
                    top: 30,
                    ypg: 445.4,
                },
                splits: {},
            },
            averageMoneylines: {
                dog: {
                    avgML: 0,
                    totalGames: 0,
                },
                favorite: {
                    avgML: -595.9090909090909,
                    totalGames: 11,
                },
            },
            recordData: {
                ats: '7-6-0',
                awayRecord: '4-0',
                conferenceRecord: '7-1',
                divisionRecord: '0-0',
                homeRecord: '6-1',
                ou: '4-8-1',
                record: '11-2',
            },
            sos: 3,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/cfb%2F8458_cfb.svg?alt=media',
            rank: 4,
        },
        gameId: '2024-12-21T22:00:00.000Z-8458-201',
        dateTime: '2024-12-21T22:00:00.000Z',
        status: 'not started',
        bookData: {
            moneylines: [],
            spreads: [],
        },
        openingSpread: 11,
        total: 56.5,
        totalStats: {
            total: 56.5,
            over: 30,
            under: 50,
        },
        headlines: [
            {
                title: 'TEXAS VS CLEMSON PICKS, PREDICTIONS, ODDS: WHO WINS COLLEGE FOOTBALL PLAYOFF GAME?THE ARIZONA REPUBLIC\n13 HOURS AGO',
                href: 'https://www.google.com/url?q=https://www.azcentral.com/story/sports/college/2024/12/15/texas-vs-clemson-picks-predictions-odds-college-football-playoff-game/76930390007/&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQ0PADegQIBxAD&usg=AOvVaw1xEDMJeSm-VSA8x3oJ-ipb',
                description: 'Austin American-Statesman\n1 week ago',
            },
            {
                title: 'USA TODAY SPORTS EXPERTS PREDICT CLEMSON VS. TEXAS WINNER IN HISTORIC CFP MATCHUPCLEMSON WIRE\n2 DAYS AGO',
                href: 'https://www.google.com/url?q=https://clemsonwire.usatoday.com/2024/12/13/clemson-football-texas-football-cfp-expert-predictions/&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQ0PADegQIBxAH&usg=AOvVaw2AuEuwQnYt2jjI7vtxlvDO',
                description: 'Austin American-Statesman\n1 week ago',
            },
            {
                title: 'ESPN ANALYST MAKES SCORE PREDICTION FOR CLEMSON VS. TEXASYAHOO SPORTS\n2 DAYS AGO',
                href: 'https://www.google.com/url?q=https://sports.yahoo.com/espn-analyst-makes-score-prediction-155500716.html&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQ0PADegQIBxAL&usg=AOvVaw2E7keYLCJug7nTXDK93aqx',
                description: 'Austin American-Statesman\n1 week ago',
            },
            {
                title: 'ESPN STAFFER MAKES SCORE PREDICTION FOR CLEMSON VS. TEXASTHE CLEMSON INSIDER\n3 DAYS AGO',
                href: 'https://www.google.com/url?q=https://theclemsoninsider.com/2024/12/12/espn-staffer-makes-score-prediction-for-clemson-vs-texas/&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQ0PADegQIBxAP&usg=AOvVaw2IWWa5X6doJp1uSafzbTZg',
                description: 'Austin American-Statesman\n1 week ago',
            },
            {
                title: 'COLLEGE FOOTBALL PLAYOFF: CLEMSON AT TEXAS - PREDICTION ODDS, EXPERT PICKS, QBS, BETTING TRENDS, AND STATSNBC SPORTS\n4 DAYS AGO',
                href: 'https://www.google.com/url?q=https://www.nbcsports.com/betting/college-football/news/college-football-playoff-clemson-at-texas-prediction-odds-expert-picks-qbs-betting-trends-and-stats&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQ0PADegQIBxAT&usg=AOvVaw0pDT4_mqoOGB6nVylcRQ-d',
                description: 'Austin American-Statesman\n1 week ago',
            },
            {
                title: 'CLEMSON VS. TEXAS PICKS: COLLEGE FOOTBALL PLAYOFF ODDS, PREDICTIONSPORTS ILLUSTRATED\n6 DAYS AGO',
                href: 'https://www.google.com/url?q=https://www.si.com/fannation/college/cfb-hq/picks/clemson-vs-texas-picks-college-football-playoff-odds-prediction&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQ0PADegQIBxAX&usg=AOvVaw0sHwT24rC3VsZeUEc1svZb',
                description: 'Austin American-Statesman\n1 week ago',
            },
            {
                title: 'WHO WILL WIN TEXAS VS CLEMSON? LATEST COLLEGE FOOTBALL PLAYOFF PREDICTIONS, ODDSAUSTIN AMERICAN-STATESMAN\n1 WEEK AGO',
                href: 'https://www.google.com/url?q=https://www.statesman.com/story/sports/college/longhorns/football/2024/12/08/texas-clemson-predictions-odds/76853192007/&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQ0PADegQIBxAb&usg=AOvVaw1ZOBUsEpm3ZcT_FMKcdHCm',
                description: 'Austin American-Statesman\n1 week ago',
            },
            {
                title: 'CLEMSON VS TEXAS EXPERT PREDICTIONS FOR FIRST-ROUND CFP MATCHUPCLEMSONWIRE.USATODAY.COM › 2024/12/13 › CLEMSON-FOOTBALL-TEXAS-FOOTBALL...',
                href: 'https://www.google.com/url?q=https://clemsonwire.usatoday.com/2024/12/13/clemson-football-texas-football-cfp-expert-predictions/&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQFnoECAsQAg&usg=AOvVaw1ep46KgdH6p4ZFwwcd7BzR',
                description:
                    '3 days ago   Cade Klubnik and the Clemson Tigers got a tough draw in the CFP with the Texas Longhorns. Who do they experts think will win?',
            },
            {
                title: 'ESPN ANALYST MAKES SCORE PREDICTION FOR CLEMSON VS. TEXASSPORTS.YAHOO.COM › ESPN-ANALYST-MAKES-SCORE-PREDICTION-155500716',
                href: 'https://www.google.com/url?q=https://sports.yahoo.com/espn-analyst-makes-score-prediction-155500716.html&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQFnoECAwQAg&usg=AOvVaw0-3ZW9Mc1Ck7eGzHoJ7VwW',
                description: "3 days ago   For Clemson's first-round matchup against Texas, he's predicting a 24-16 win for the Longhorns.",
            },
            {
                title: 'TEXAS VS. CLEMSON PREDICTIONS, ODDS: WHO WILL WIN CFP GAME?WWW.STATESMAN.COM › SPORTS › COLLEGE › LONGHORNS › FOOTBALL › 2024/12/08',
                href: 'https://www.google.com/url?q=https://www.statesman.com/story/sports/college/longhorns/football/2024/12/08/texas-clemson-predictions-odds/76853192007/&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQFnoECAIQAg&usg=AOvVaw1wsyOsQzHlmv2lVMtTKiyh',
                description:
                    'Dec 8, 2024   Texas vs Clemson odds. Texas has opened as the favorite. Clemson is coming off an ACC title game win against SMU while Texas lost the SEC title ...',
            },
            {
                title: 'COLLEGE FOOTBALL PLAYOFF: CLEMSON AT TEXAS - PREDICTION ODDS, EXPERT ...WWW.NBCSPORTS.COM › BETTING › COLLEGE-FOOTBALL › NEWS › COLLEGE-FOOTBALL-...',
                href: 'https://www.google.com/url?q=https://www.nbcsports.com/betting/college-football/news/college-football-playoff-clemson-at-texas-prediction-odds-expert-picks-qbs-betting-trends-and-stats&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQFnoECAQQAg&usg=AOvVaw2MSeYdTSGUfuVjh7sPXGxG',
                description:
                    '5 days ago   Game odds for Clemson at Texas - CFP Playoff   Moneyline: Texas (-395), Clemson (+325)   Spread: Texas -11.5   Over/Under: 51.5 points ...',
            },
            {
                title: 'CLEMSON VS TEXAS PREDICTION, ODDS & BEST PROP BETS - BETMGMSPORTS.BETMGM.COM › BLOG › COLLEGE-FOOTBALL › CLEMSON-TEXAS-PREDICTION-O...',
                href: 'https://www.google.com/url?q=https://sports.betmgm.com/en/blog/college-football/clemson-texas-prediction-odds-player-prop-bets-picks-ncaaf-week-17-jaa/&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQFnoECAYQAg&usg=AOvVaw0D2DR4VDhhXS-lsnomyfsy',
                description:
                    '4 days ago   The winning team model predicts Texas will win this game with 77.1% confidence, based on game simulations, offensive & defensive matchups and ...',
            },
            {
                title: 'ESPN STAFFER MAKES SCORE PREDICTION FOR CLEMSON VS. TEXASTHECLEMSONINSIDER.COM › 2024/12/12 › ESPN-STAFFER-MAKES-SCORE-PREDICTION...',
                href: 'https://www.google.com/url?q=https://theclemsoninsider.com/2024/12/12/espn-staffer-makes-score-prediction-for-clemson-vs-texas/&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQFnoECA0QAg&usg=AOvVaw2v0_HFZR2hBc0TeV_b6Yn_',
                description:
                    "4 days ago   Rittenberg predicts the Longhorns to beat the Tigers, 24-16. “Coach Dabo Swinney's Tigers are the rare conference champion playing with house ...",
            },
            {
                title: 'CLEMSON VS. TEXAS PICKS: COLLEGE FOOTBALL PLAYOFF ODDS, PREDICTIONWWW.SI.COM › HOME › PICKS',
                href: 'https://www.google.com/url?q=https://www.si.com/fannation/college/cfb-hq/picks/clemson-vs-texas-picks-college-football-playoff-odds-prediction&sa=U&ved=2ahUKEwjsvPCSxqyKAxUxl-4BHa4OF3gQFnoECAgQAg&usg=AOvVaw0niTxSOYIWTMsBv_Q75HLJ',
                description:
                    '7 days ago   Texas is a 10.5 point favorite against Clemson, according to the updated lines posted to FanDuel Sportsbook for the matchup. FanDuel set the ...',
            },
        ],
        advantages: [
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 52.47933884297521,
                teamId: 201,
                description: 'CLEM has a better PPG.  Teams with a better PPG win at a 52% rate.',
                amount: 0.6999999999999957,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 51.031636863823934,
                teamId: 8458,
                description: 'UT has a better DPPG.  Teams with a better DPPG win at a 51% rate.',
                amount: 9.8,
            },
            {
                adv: 'Key Factor - YPG',
                id: 'key-factor',
                factorId: 'ypg',
                winPercent: 50.273972602739725,
                teamId: 201,
                description: 'CLEM has a better YPG.  Teams with a better YPG win at a 50% rate.',
                amount: 9.5,
            },
            {
                adv: 'Key Factor - toRatio',
                id: 'key-factor',
                factorId: 'toRatio',
                winPercent: 50.52316890881914,
                teamId: 201,
                description: 'CLEM has a better toRatio.  Teams with a better toRatio win at a 50% rate.',
                amount: 10,
            },
            {
                adv: 'Key Factor - 3rd Down',
                id: 'key-factor',
                factorId: 'thirdDown',
                winPercent: 50.824175824175825,
                teamId: 201,
                description: 'CLEM has a better 3rd Down.  Teams with a better 3rd Down win at a 50% rate.',
                amount: 2.549999999999997,
            },
            {
                adv: 'Key Factor - TOP',
                id: 'key-factor',
                factorId: 'top',
                winPercent: 48.503937007874015,
                teamId: 0,
                description: '',
                amount: 0,
            },
            {
                adv: 'Key Factor - Penalties',
                id: 'key-factor',
                factorId: 'avgPenaltyYards',
                winPercent: 51.38121546961326,
                teamId: 201,
                description: 'CLEM has a better Penalties.  Teams with a better Penalties win at a 51% rate.',
                amount: 7,
            },
            {
                adv: 'Key Factor - RYPG',
                id: 'key-factor',
                factorId: 'rypg',
                winPercent: 49.31318681318682,
                teamId: 8458,
                description: 'UT has a better RYPG.  Teams with a better RYPG win at a 49% rate.',
                amount: 43.8,
            },
            {
                adv: 'Key Factor - PYPG',
                id: 'key-factor',
                factorId: 'pypg',
                winPercent: 50.89163237311386,
                teamId: 8458,
                description: 'UT has a better PYPG.  Teams with a better PYPG win at a 50% rate.',
                amount: 71.30000000000001,
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'CLEM has an advantage based on our power rank data.',
                teamId: 201,
                amount: 0,
            },
        ],
        prediction: {
            awayScore: 30.51,
            homeScore: 31.11,
            winner: 8458,
            spreadWinner: 201,
            powerSpread: 0.5999999999999979,
            powerMoneyline: {
                dogML: 117,
                favML: -117,
            },
            powerLineWinner: 201,
        },
        situations: [
            {
                id: 'division-game',
                label: 'Division Game',
                value: false,
                description: 'This is a undefined division game.',
                type: 'other',
            },
            {
                id: 'conference-game',
                label: 'Conference Game',
                value: false,
                description: 'This is a undefined conference game.',
                type: 'other',
            },
            {
                id: 'line-movement',
                label: 'Line Movement',
                teamId: 201,
                description: 'The line is on the move in favor of Clemson.',
                type: 'team',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 201,
                description: 'Clemson win 75.00% of games on Sat.',
                type: 'team',
                queryString: '?subpage=trends&trend=team-days-of-week&team=201',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 8458,
                description: 'Texas win 84.62% of games on Sat.',
                type: 'team',
                queryString: '?subpage=trends&trend=team-days-of-week&team=8458',
            },
        ],
    },
    cbb: {
        awayTeam: {
            teamName: 'Southern Miss',
            teamId: 7713898,
            abbr: 'USM',
            mascot: '',
            fullName: 'Southern Mississippi Golden Eagles',
            shortName: 'Southern Miss',
            pitcherData: '',
            color: '#FFD046',
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/cbb%2F7713898_cfb.svg?alt=media&token=9fdb2f25-28f9-42cb-898b-577408162558',
            spread: 8.5,
            odds: {
                fullGame: {
                    moneyline: 290,
                    spread: 8.5,
                },
            },
            spreadPercent: 51,
            mlPercent: 59,
            mlMoneyPercent: 23,
            spreadMoneyPercent: 61,
            injuries: [
                {
                    player: 'T.\n                                                                                    Hardy',
                    position: 'G',
                    date: 'for season ',
                    status: 'out',
                    desc: ' Shoulder',
                },
            ],
            openMoneyLine: 270,
            moneyLine: 290,
            stats: {
                def: {
                    defEff: 1.051,
                    fouls: 16.2,
                    oppThreePoint: 34.3,
                    ppg: 72.4,
                    reboundPercent: 32.8,
                },
                off: {
                    assists: 12.6,
                    astTo: 0.89,
                    floorPercent: 41.5,
                    freeThrow: 67.7,
                    offEff: 0.87,
                    oppg: 59.9,
                    paceofplay: 269,
                    threePoint: 30.6,
                    turnovers: 14.2,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 500,
                    totalGames: 14,
                },
                favorite: {
                    avgML: -455,
                    totalGames: 3,
                },
            },
            recordData: {
                ats: ' 7-11-0',
                awayRecord: '2-9',
                homeRecord: '4-5',
                ou: '22-18-1',
                record: '6-14',
            },
            sos: 253,
        },
        homeTeam: {
            teamName: 'FIU',
            teamId: 23787,
            abbr: 'FIU',
            mascot: '',
            fullName: 'Florida International Panthers',
            shortName: 'Florida Int.',
            pitcherData: '',
            color: '#081E3F',
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/cbb%2F23787_cfb.svg?alt=media&token=12aae9f5-64fc-4b48-a307-6ca80a4a0939',
            spread: -8.5,
            spreadPercent: 49,
            mlPercent: 41,
            mlMoneyPercent: 77,
            spreadMoneyPercent: 39,
            injuries: [],
            openMoneyLine: -350,
            moneyLine: -360,
            odds: {
                fullGame: {
                    moneyline: -360,
                    spread: -8.5,
                },
            },
            stats: {
                def: {
                    defEff: 0.969,
                    fouls: 16.3,
                    oppThreePoint: 30.7,
                    ppg: 69.7,
                    reboundPercent: 36.3,
                },
                off: {
                    assists: 14.8,
                    astTo: 1.04,
                    floorPercent: 43,
                    freeThrow: 69.6,
                    offEff: 0.957,
                    oppg: 68.8,
                    paceofplay: 109,
                    threePoint: 33,
                    turnovers: 14.2,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 327.77777777777777,
                    totalGames: 9,
                },
                favorite: {
                    avgML: -197.85714285714286,
                    totalGames: 7,
                },
            },
            recordData: {
                ats: ' 7-11-1',
                awayRecord: '1-8',
                homeRecord: '11-1',
                ou: '22-18-1',
                record: '12-9',
            },
            sos: 270,
        },
        gameId: '2022-02-03T18:00:00-06:00-23787-7713898',
        dateTime: '2022-02-03T18:00:00-06:00',
        status: 'not started',
        location: 'Ocean Bank Convocation Center, Miami, FL',
        openingSpread: 8,
        bookData: [
            {
                line: 8,
                label: 'Bet MGM',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                color: '#ae996a',
            },
            {
                line: 8.5,
                label: 'DraftKings',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                color: '#62af23',
            },
            {
                line: 8.5,
                label: 'FanDuel',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                color: '#2192f5',
            },
            {
                line: 8.5,
                label: 'Points Bet',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-pointsbet.png',
                color: '#000',
            },
            {
                line: 8.5,
                label: 'Caesars',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-williamhill.png',
                color: '#183432',
            },
            {
                line: 8.5,
                label: 'Sugar House',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betrivers.png',
                color: '#18365e',
            },
            {
                line: 8.5,
                label: 'UniBet',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-unibet.png',
                color: '#147b44',
            },
        ],
        headlines: [
            {
                title: 'SOUTHERN MISS VS. FLORIDA INTERNATIONAL (FIU) PREDICTION, PREVIEW ...',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/ncaab/2-3-2022/southern-miss-vs-florida-international-fiu-prediction-502/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAYQAg&usg=AOvVaw3hFk6ZEC9ofA2daUlHUoX0',
                description:
                    '20 hours ago   The Golden Eagles are coming off a 76-62 loss to Rice as 5.5-point underdogs. The Panthers are coming off a 79-68 loss to UTEP as seven-point ...',
            },
            {
                title: 'SOUTHERN MISS VS. FLORIDA INTERNATIONAL (FIU) - PREDICTIONS',
                href: 'https://www.google.com/url?q=https://statsalt.com/games/ncaab/2-3-2022/southern-miss-vs-florida-international-fiu-prediction-9054/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAkQAg&usg=AOvVaw1uFfsvKiSAhqmX14Pl7nmm',
                description:
                    '14 hours ago   The Southern Mississippi Eagles get set to take on the Florida International Panthers with an overall record of 6-14.',
            },
            {
                title: 'FLORIDA INTERNATIONAL VS SOUTHERN MISSISSIPPI 2/3/22 COLLEGE ...',
                href: 'https://www.google.com/url?q=https://sportschatplace.com/college-basketball-picks/2022/02/02/florida-international-vs-southern-mississippi-2-3-22-college-basketball-picks-predictions-odds/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAcQAg&usg=AOvVaw2_QOP65Mbc30EdhWZITvd5',
                description:
                    '13 hours ago   The Southern Miss Golden Eagles and FIU Golden Panthers meet Thursday in college basketball action at the Ocean Bank Convocation Center.',
            },
            {
                title: 'SOUTHERN MISS AT FIU 2/3/22 - COLLEGE BASKETBALL PICKS & PREDICTIONS',
                href: 'https://www.google.com/url?q=https://freepicks.picksandparlays.net/more-free-picks/college-basketball-picks/southern-miss-at-fiu-2322-college-basketball-picks-and-predictions&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAgQAg&usg=AOvVaw00hnxNoNGfK9AQZZcgiGhL',
                description:
                    '17 hours ago   Game, FIU Panthers vs USM Golden Eagles. Date & Time, Thursday February 3, 2022, 7:00 PM (EST). Location. Ocean Bank Convocation Center.',
            },
            {
                title: 'SOUTHERN MISSISSIPPI GOLDEN EAGLES VS FLORIDA INTERNATIONAL PANTHERS',
                href: 'https://www.google.com/url?q=https://scores24.live/en/basketball/m-04-02-2022-southern-mississippi-golden-eagles-fiu-panthers-prediction&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAUQAg&usg=AOvVaw10NSYm7SBcen2O64Yo0cYL',
                description:
                    '11 hours ago   In the current season, the team plays at a high level: 3 wins and 7 loses allows Southern Mississippi Golden Eagles gain 63.16% victory and get ...',
            },
            {
                title: 'FLORIDA INTERNATIONAL VS. SOUTHERN MISS - COLLEGE BASKETBALL',
                href: 'https://www.google.com/url?q=https://www.sportsgamblingpodcast.com/2022/02/02/florida-international-vs-southern-miss-college-basketball-predictions-betting-lines-odds-and-trends/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAEQAg&usg=AOvVaw0rsh-0hP7M-XrEVyWOq8ul',
                description:
                    '15 hours ago   The 72 points per game the Panthers score are only one more point than the Golden Eagles allow (71). When Florida International scores more than ...',
            },
            {
                title: 'FLORIDA INTERNATIONAL VS SOUTHERN MISS BETTING ODDS - THELINES.COM',
                href: 'https://www.google.com/url?q=https://www.thelines.com/florida-international-southern-miss-ncaa-basketball-preview-prediction-2-3-2022/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAMQAg&usg=AOvVaw2ZIn5eTKZqsk2GfmYSwFKq',
                description:
                    "17 hours ago   Keep reading to find betting insights and statistics to help place a wager on the Panthers' ( to win on the moneyline) game versus the Golden ...",
            },
            {
                title: 'SOUTHERN MISS GOLDEN EAGLES VS FLORIDA INTERNATIONAL GOLDEN ...',
                href: 'https://www.google.com/url?q=https://www.sportsbettingdime.com/college-basketball/odds/matchup/1043070/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAAQAg&usg=AOvVaw2gzI4rTjzfCZiw-3873TzI',
                description: '19 hours ago   Southern Miss Golden Eagles (6-14 SU, 5-11 ATS) vs Florida International Golden Panthers (12-9 SU, 7-11-1 ATS).',
            },
            {
                title: 'FIU PANTHERS VS SOUTHERN MISS GOLDEN EAGLES: PREVIEW & PREDICTION ...',
                href: 'https://www.google.com/url?q=https://www.underdogdynasty.com/2021/11/26/22802623/fiu-panthers-vs-southern-miss-golden-eagles-preview-prediction-tv-radio-college-football-cfb&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAIQAg&usg=AOvVaw2Adrm294bfHopqMXsrxwss',
                description:
                    "Nov 26, 2021   FIU Panthers vs Southern Miss Golden Eagles: Preview & Prediction, TV, Radio. Will Hall looks to end Southern Miss' season on a two-game win ...",
            },
        ],
        total: 134.5,
        totalStats: {
            total: 134.5,
            over: 30,
            under: 50,
        },
        advantages: [
            {
                adv: 'Line Movement',
                id: 'line-movement',
                description: 'The line is moving in favor of USM.',
                teamId: 7713898,
            },
            {
                adv: 'Big Money',
                id: 'big-money',
                description: '',
                teamId: 0,
            },
            {
                adv: 'Healthier',
                id: 'health',
                description: 'The FIU have less guys on the injury report.',
                teamId: 23787,
            },
            {
                adv: 'Key Factor - AST:TO%',
                id: 'key-factor',
                factorId: 'astTo',
                winPercent: 60.328997704667174,
                teamId: 23787,
                description: 'FIU has a better AST:TO%.  Teams with a better AST:TO% win at a 60% rate.',
                amount: 0.15000000000000002,
            },
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 60.93692364441166,
                teamId: 23787,
                description: 'FIU has a better PPG.  Teams with a better PPG win at a 60% rate.',
                amount: 8.899999999999999,
            },
            {
                adv: 'Key Factor - Floor %',
                id: 'key-factor',
                factorId: 'floorPercent',
                winPercent: 65.62384983437614,
                teamId: 23787,
                description: 'FIU has a better Floor %.  Teams with a better Floor % win at a 65% rate.',
                amount: 1.5,
            },
            {
                adv: 'Key Factor - oEff%',
                id: 'key-factor',
                factorId: 'offEff',
                winPercent: 65.58282208588957,
                teamId: 23787,
                description: 'FIU has a better oEff%.  Teams with a better oEff% win at a 65% rate.',
                amount: 0.08699999999999997,
            },
            {
                adv: 'Key Factor - 3 Point %',
                id: 'key-factor',
                factorId: 'threePoint',
                winPercent: 57.265569076592705,
                teamId: 23787,
                description: 'FIU has a better 3 Point %.  Teams with a better 3 Point % win at a 57% rate.',
                amount: 2.3999999999999986,
            },
            {
                adv: 'Key Factor - Free Throw %',
                id: 'key-factor',
                factorId: 'freeThrow',
                winPercent: 53.97769516728624,
                teamId: 23787,
                description: 'FIU has a better Free Throw %.  Teams with a better Free Throw % win at a 53% rate.',
                amount: 1.8999999999999915,
            },
            {
                adv: 'Key Factor - Rebound %',
                id: 'key-factor',
                factorId: 'reboundPercent',
                winPercent: 57.00410907732536,
                teamId: 23787,
                description: 'FIU has a better Rebound %.  Teams with a better Rebound % win at a 57% rate.',
                amount: 3.5,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 62.95888399412628,
                teamId: 23787,
                description: 'FIU has a better DPPG.  Teams with a better DPPG win at a 62% rate.',
                amount: 2.700000000000003,
            },
            {
                adv: 'Key Factor - dEff%',
                id: 'key-factor',
                factorId: 'defEff',
                winPercent: 35.68281938325991,
                teamId: 7713898,
                description: 'USM has a better dEff%.  Teams with a better dEff% win at a 35% rate.',
                amount: 0.08199999999999996,
            },
            {
                adv: 'Key Factor - Fouls PG',
                id: 'key-factor',
                factorId: 'fouls',
                winPercent: 53.95017793594305,
                teamId: 7713898,
                description: 'USM has a better Fouls PG.  Teams with a better Fouls PG win at a 53% rate.',
                amount: 0.10000000000000142,
            },
            {
                adv: 'Key Factor - Opp. 3 Point %',
                id: 'key-factor',
                factorId: 'oppThreePoint',
                winPercent: 56.59025787965616,
                teamId: 23787,
                description: 'FIU has a better Opp. 3 Point %.  Teams with a better Opp. 3 Point % win at a 56% rate.',
                amount: 3.599999999999998,
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'FIU has an advantage based on our power rank data.',
                teamId: 23787,
                amount: 0,
            },
        ],
        prediction: {
            awayScore: 59.66,
            homeScore: 82.25,
            rating: 4.17,
            winner: 23787,
            spreadWinner: 23787,
            powerSpread: 22.590000000000003,
            powerMoneyline: {
                dogML: 407,
                favML: -407,
            },
            powerLineWinner: 23787,
        },
        weather: {},
        situations: [],
    },
    nhl: {
        awayTeam: {
            teamName: 'Colorado',
            teamId: 8920312,
            abbr: 'COL',
            mascot: 'Avalanche',
            fullName: 'Colorado Avalanche',
            shortName: '',
            pitcherData: '',
            color: '#6F263D',
            odds: {
                fullGame: {
                    moneyline: -135,
                    spread: -1.5,
                },
            },
            openMoneyLine: -125,
            spreadPercent: 41,
            mlPercent: 76,
            mlMoneyPercent: 84,
            spreadMoneyPercent: 94,
            injuries: [
                {
                    player: 'Oliver Kylington',
                    position: 'D',
                    date: 'Dec 27',
                    status: 'Out',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Miles Wood',
                    position: 'LW',
                    date: 'Dec 31',
                    status: 'Injured Reserve',
                    desc: 'Dec 7: Wood (upper body) was placed on long-term injured reserve Saturday, per PuckPedia.',
                    value: 0,
                },
                {
                    player: 'Jonathan Drouin',
                    position: 'LW',
                    date: 'Dec 27',
                    status: 'Injured Reserve',
                    desc: 'Dec 7: Drouin (upper body) was placed on injured reserve retroactive to Nov. 23 on Saturday.',
                    value: 0,
                },
                {
                    player: 'Josh Manson',
                    position: 'D',
                    date: 'Dec 27',
                    status: 'Injured Reserve',
                    desc: 'Dec 2: Manson (upper body) was placed on injured reserve Monday, per the NHL media site.',
                    value: 0,
                },
                {
                    player: 'Tucker Poolman',
                    position: 'D',
                    date: 'Sep 15',
                    status: 'Injured Reserve',
                    desc: 'Oct 6: Colorado received Poolman (head) and a 2025 fourth-round draft pick from Vancouver on Sunday in exchange for Erik Brannstrom.',
                    value: 0,
                },
            ],
            stats: {
                def: {
                    hdsv: 74.4,
                    ppg: 3.03,
                    scsvPercent: 82.24,
                },
                off: {
                    oppg: 3.31,
                    pp: 23.96,
                    sog: 29.28,
                },
                splits: {},
            },
            averageMoneylines: {
                dog: {
                    avgML: 116.42857142857143,
                    totalGames: 7,
                },
                favorite: {
                    avgML: -178.75,
                    totalGames: 24,
                },
            },
            recordData: {
                awayRecord: '10-6-0',
                conferenceRecord: '0-0',
                divisionRecord: '0-0',
                homeRecord: '8-8-0',
                ou: '12-5-0',
                record: '5-14-0',
            },
            sos: 0,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nhl%2F8920312_nhl.svg?alt=media',
            division: 'Central',
            conference: 'Western Conference',
        },
        homeTeam: {
            teamName: 'Vancouver',
            teamId: 931231,
            abbr: 'VAN',
            mascot: 'Canucks',
            fullName: 'Vancouver Canucks',
            shortName: '',
            pitcherData: '',
            color: '#00205B',
            odds: {
                fullGame: {
                    moneyline: 115,
                    spread: 1.5,
                },
            },
            openMoneyLine: 105,
            spreadPercent: 59,
            mlPercent: 24,
            mlMoneyPercent: 16,
            spreadMoneyPercent: 0,
            injuries: [
                {
                    player: 'Kevin Lankinen',
                    position: 'G',
                    date: 'Dec 16',
                    status: 'Day-To-Day',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Derek Forbort',
                    position: 'D',
                    date: 'Dec 16',
                    status: 'Day-To-Day',
                    desc: '',
                    value: 0,
                },
                {
                    player: 'Filip Hronek',
                    position: 'D',
                    date: 'Jan 29',
                    status: 'Injured Reserve',
                    desc: 'Dec 5: Hronek (lower body) was placed on long-term injured reserve Thursday, retroactive to Nov. 27.',
                    value: 0,
                },
            ],
            stats: {
                def: {
                    hdsv: 81.69,
                    ppg: 3,
                    scsvPercent: 82.56,
                },
                off: {
                    oppg: 3.21,
                    pp: 23.81,
                    sog: 26.21,
                },
                splits: {},
            },
            averageMoneylines: {
                dog: {
                    avgML: 120.83333333333333,
                    totalGames: 6,
                },
                favorite: {
                    avgML: -169.75,
                    totalGames: 20,
                },
            },
            recordData: {
                awayRecord: '10-2-1',
                conferenceRecord: '0-0',
                divisionRecord: '0-0',
                homeRecord: '5-7-4',
                ou: '16-11-2',
                record: '15-9-5',
            },
            sos: 0,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nhl%2F931231_nhl.svg?alt=media',
            division: 'Pacific',
            conference: 'Western Conference',
        },
        gameId: '2024-12-17T04:30:00.000Z-931231-8920312',
        dateTime: '2024-12-17T04:30:00.000Z',
        status: 'not started',
        bookData: {
            moneylines: [
                {
                    awayLine: -135,
                    homeLine: 115,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: -135,
                    homeLine: 114,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
                {
                    awayLine: -140,
                    homeLine: 116,
                    label: 'FanDuel',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                    color: '#2192f5',
                },
            ],
            spreads: [
                {
                    awayLine: -1.5,
                    homeLine: 1.5,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: -1.5,
                    homeLine: 1.5,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
                {
                    awayLine: -1.5,
                    homeLine: 1.5,
                    label: 'FanDuel',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                    color: '#2192f5',
                },
            ],
        },
        openingSpread: 1.5,
        headlines: [
            {
                title: "COLORADO AVALANCHE VS VANCOUVER CANUCKS PREDICTION, 12/16/2024 NHL PICKS, BEST BETS & ODDSDOC'S SPORTS PICKS\n15 HOURS AGO",
                href: 'https://www.google.com/url?q=https://www.docsports.com/free-picks/nhl-hockey/2024/colorado-avalanche-vs-vancouver-canucks-prediction-12-16-2024-nhl-picks-best-bets-odds.html&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4Q0PADegQICxAD&usg=AOvVaw1BaccgLgU42S4EHF4s170L',
                description: 'Winners & Whiners\n16 hours ago',
            },
            {
                title: 'COLORADO AVALANCHE VS VANCOUVER CANUCKS BETTING PICK & PREDICTION 12/16/24BANG THE BOOK\n3 HOURS AGO',
                href: 'https://www.google.com/url?q=https://bangthebook.com/colorado-avalanche-vs-vancouver-canucks-betting-pick-prediction-12-16-24&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4Q0PADegQICxAH&usg=AOvVaw2cu_vGIIv9P3IIFDS4rovF',
                description: 'Winners & Whiners\n16 hours ago',
            },
            {
                title: 'CANUCKS VS AVALANCHE PREDICTION, PICKS & ODDS FOR TONIGHT’S NHL GAMECOVERS.COM\n1 HOUR AGO',
                href: 'https://www.google.com/url?q=https://www.covers.com/nhl/canucks-vs-avalanche-prediction-picks-monday-12-16-2024&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4Q0PADegQICxAL&usg=AOvVaw3Xqylwr71bllyTKnHFEWW7',
                description: 'Winners & Whiners\n16 hours ago',
            },
            {
                title: 'AVALANCHE VS CANUCKS PREDICTION AND PICKS FOR DECEMBER 16 2024WINNERS & WHINERS\n16 HOURS AGO',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/nhl/12-16-2024/colorado-avalanche-vs-vancouver-canucks-prediction-1884/&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4Q0PADegQICxAP&usg=AOvVaw2t3Xr2zRhZ3oKEW2MHEQOU',
                description: 'Winners & Whiners\n16 hours ago',
            },
            {
                title: 'COLORADO AVALANCHE VS VANCOUVER CANUCKS BETTING PICKBANGTHEBOOK.COM › COLORADO-AVALANCHE-VS-VANCOUVER-CANUCKS-BETTING-PIC...',
                href: 'https://www.google.com/url?q=https://bangthebook.com/colorado-avalanche-vs-vancouver-canucks-betting-pick-prediction-12-16-24&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4QFnoECAoQAg&usg=AOvVaw0aRYxtn3ZpP-PhG__SYeyw',
                description:
                    '3 hours ago   In spite of being 1.5 goal underdogs, we anticipate the Canucks winning this one 4-3.   We like the Canucks on the moneyline (+116)   The Canucks ...',
            },
            {
                title: 'AVALANCHE VS CANUCKS PREDICTION AND PICKS FOR DECEMBER 16 2024WINNERSANDWHINERS.COM › GAMES › NHL › COLORADO-AVALANCHE-VS-VANCOUVER...',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/nhl/12-16-2024/colorado-avalanche-vs-vancouver-canucks-prediction-1884/&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4QFnoECAYQAg&usg=AOvVaw2xSK7m1F_druTVphGHogMC',
                description:
                    '16 hours ago   When it comes to the power-play percentage, Colorado is ninth (23-for-96), and Vancouver is right behind the Avs, going 20-for-84 with the man ...',
            },
            {
                title: 'AVALANCHE VS CANUCKS PREDICTION, ODDS, PICKS, BEST BETS ... - FANDUELWWW.FANDUEL.COM › RESEARCH › AVALANCHE-VS-CANUCKS-PREDICTION-NHL-ODDS-...',
                href: 'https://www.google.com/url?q=https://www.fanduel.com/research/avalanche-vs-canucks-prediction-nhl-odds-12-16-2024&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4QFnoECAUQAg&usg=AOvVaw0r8hMt0mJ5VGWFcHHhEcX_',
                description:
                    '9 hours ago   Looking at the moneyline for Avalanche-Canucks, Colorado is the favorite at -134, and Vancouver is +112 playing at home. Sign up for FanDuel ...',
            },
            {
                title: 'VANCOUVER CANUCKS VS COLORADO AVALANCHE PREDICTION 12-16-24 ...SPORTSCHATPLACE.COM › NHL PICKS',
                href: 'https://www.google.com/url?q=https://sportschatplace.com/nhl-picks/vancouver-canucks-vs-colorado-avalanche-prediction-12-16-24-nhl-picks/&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4QFnoECAkQAg&usg=AOvVaw0s5QM8QQ3yqLrax7oCu8Sx',
                description:
                    '12 hours ago   The Vancouver Canucks rank T29th in the league in short handed goals this season (0). The Colorado Avalanche rank 29th in the league for goals ...',
            },
            {
                title: 'AVALANCHE VS CANUCKS PREDICTION AND PICKS FOR DECEMBER 16 2024STATSALT.COM › COLORADO-AVALANCHE-VS-VANCOUVER-CANUCKS-PREDICTION-4914',
                href: 'https://www.google.com/url?q=https://statsalt.com/games/nhl/12-16-2024/colorado-avalanche-vs-vancouver-canucks-prediction-4914/&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4QFnoECAcQAg&usg=AOvVaw1y2rbL-nVFVH5lZSRFp0Zt',
                description:
                    '17 hours ago   Get our Colorado Avalanche vs Vancouver Canucks NHL picks, prediction, analysis, odds, and more for the 12/16/24 matchup.',
            },
            {
                title: 'VANCOUVER WHITECAPS VS COLORADO RAPIDS PREDICTION, ODDS & BETTING ...FOOTBALLWHISPERS.COM › FOOTBALL WHISPERS › SOCCER PICKS › MLS PICKS',
                href: 'https://www.google.com/url?q=https://footballwhispers.com/us/blog/colorado-rapids-vs-vancouver-whitecaps-prediction/&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4QFnoECAgQAg&usg=AOvVaw3tKqo4rR6nV9dIylVk9QuO',
                description: 'Vancouver Whitecaps vs...   Vancouver Whitecaps team...',
            },
            {
                title: 'VANCOUVER CANUCKS VS COLORADO AVALANCHE PREDICTION & ODDSWWW.SPORTINGPOST.COM › HOME › NEWS',
                href: 'https://www.google.com/url?q=https://www.sportingpost.com/2024/12/16/vancouver-canucks-vs-colorado-avalanche-prediction-and-odds/&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4QFnoECAQQAg&usg=AOvVaw33UsznOkNJHFvqGc_Jr2kS',
                description:
                    "7 hours ago   For tonight's Vancouver Canucks vs Colorado Avalanche matchup, our predictive model gives the Avalanche a 59.6% chance of defeating the Canucks.",
            },
            {
                title: 'VANCOUVER VS. COLORADO PREDICTION, PICKS AND ODDS - DIMERS.COMWWW.DIMERS.COM › BET-HUB › MLS › SCHEDULE › 2024_12_COL_VAN',
                href: 'https://www.google.com/url?q=https://www.dimers.com/bet-hub/mls/schedule/2024_12_col_van&sa=U&ved=2ahUKEwjlntKZx6yKAxXdle4BHf2RBm4QFnoECAIQAg&usg=AOvVaw0DiULFQUn07aXKIi8qvdVE',
                description:
                    "Our most likely correct score for this MLS game is Vancouver 1-1 Colorado, which has a correct score probability of 11%. This prediction is based on each team's ...",
            },
        ],
        total: 6,
        totalStats: {
            total: 6,
            over: 105,
            under: 99,
        },
        advantages: [
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 55.98194130925508,
                teamId: 8920312,
                description: 'COL has a better PPG.  Teams with a better PPG win at a 55% rate.',
                amount: 0.10000000000000009,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 52.144469525959366,
                teamId: 931231,
                description: 'VAN has a better DPPG.  Teams with a better DPPG win at a 52% rate.',
                amount: 0.029999999999999805,
            },
            {
                adv: 'Key Factor - SOG/G',
                id: 'key-factor',
                factorId: 'sog',
                winPercent: 54.95495495495496,
                teamId: 8920312,
                description: 'COL has a better SOG/G.  Teams with a better SOG/G win at a 54% rate.',
                amount: 3.0700000000000003,
            },
            {
                adv: 'Key Factor - PP',
                id: 'key-factor',
                factorId: 'pp',
                winPercent: 52.71493212669684,
                teamId: 8920312,
                description: 'COL has a better PP.  Teams with a better PP win at a 52% rate.',
                amount: 0.15000000000000213,
            },
            {
                adv: 'Key Factor - SCSV%',
                id: 'key-factor',
                factorId: 'scsvPercent',
                winPercent: 50.989010989010985,
                teamId: 931231,
                description: 'VAN has a better SCSV%.  Teams with a better SCSV% win at a 50% rate.',
                amount: 0.3200000000000074,
            },
            {
                adv: 'Key Factor - HDSV%',
                id: 'key-factor',
                factorId: 'hdsv',
                winPercent: 48.55875831485588,
                teamId: 931231,
                description: 'VAN has a better HDSV%.  Teams with a better HDSV% win at a 48% rate.',
                amount: 7.289999999999992,
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'VAN has an advantage based on our power rank data.',
                teamId: 931231,
                amount: 5,
            },
        ],
        prediction: {
            awayScore: 2.62,
            homeScore: 3.52,
            winner: 931231,
            spreadWinner: 931231,
            powerSpread: 0.8999999999999999,
            powerMoneyline: {
                dogML: 120,
                favML: -120,
            },
            powerLineWinner: 931231,
        },
        situations: [
            {
                id: 'division-game',
                label: 'Division Game',
                value: false,
                description: 'This is a Pacific division game.',
                type: 'other',
            },
            {
                id: 'conference-game',
                label: 'Conference Game',
                value: true,
                description: 'This is a Western Conference conference game.',
                type: 'other',
            },
            {
                id: 'line-movement',
                label: 'Line Movement',
                teamId: 931231,
                description: 'The line is on the move in favor of Vancouver.',
                type: 'team',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 8920312,
                description: 'Colorado win 40.33% of games on Mon.',
                type: 'team',
                queryString: '?subpage=trends&trend=team-days-of-week&team=8920312',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 931231,
                description: 'Vancouver win 73.21% of games on Mon.',
                type: 'team',
                queryString: '?subpage=trends&trend=team-days-of-week&team=931231',
            },
        ],
    },
};
