// Library imports
import styled from 'styled-components';
import { MDBBtn } from 'mdb-react-ui-kit';

export const TabContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 15px;
`;

export const TabButton = styled(MDBBtn)`
    flex: 1;
    border-radius: 0;
    font-size: clamp(10px, 2.5vw, 12px); /* Responsive font size */
    background-color: ${({ active, theme }) => (active === 'true' ? theme.colors.primary : 'white')} !important;
    color: ${({ active, theme }) => (active === 'true' ? 'white' : theme.colors.primary)} !important;
    border-width: 1px;
    border-color: ${({ theme }) => theme.colors.primary};
    border-top-left-radius: ${props => (props.firstItem ? '999px' : '0')};
    border-bottom-left-radius: ${props => (props.firstItem ? '999px' : '0')};
    border-top-right-radius: ${props => (props.lastItem ? '999px' : '0')};
    border-bottom-right-radius: ${props => (props.lastItem ? '999px' : '0')};
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary} !important;
        color: white !important;
        box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
    }
`;
