// Library imports
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faListOl, faArrowRight, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
// Component imports
import { Drawer } from './Drawer';
import UpgradeTooltip from '../../components/UpgradeTooltip';
// Actions imports
import { setDrawer } from '../../store/slices/appSlice';
// Styled components
import { SidebarContainer, TabContainer, Tab, BackDrop, CollapseButton } from './styles';

const tabData = {
    vip: {
        title: 'VIP Picks',
        id: 'vip',
        icon: <FontAwesomeIcon icon={faUserCheck} />,
        free: false,
    },
    standings: {
        title: 'Standings',
        id: 'standings',
        icon: <FontAwesomeIcon icon={faListOl} />,
        free: true,
    },
};

const Sidebar = ({ tabs }) => {
    // Redux
    const { user } = useSelector(state => state.user);
    const { page, openDrawer } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [collapse, setCollapse] = useState(false);

    return (
        <SidebarContainer id="sidebar" page={page} collapse={collapse.toString()}>
            {!openDrawer ? (
                <>
                    <CollapseButton collapse={collapse} onClick={() => setCollapse(!collapse)}>
                        {collapse ? <FontAwesomeIcon icon={faArrowLeft} /> : <FontAwesomeIcon icon={faArrowRight} />}
                    </CollapseButton>
                    <TabContainer
                        initial={{ translateX: collapse ? 0 : '100%' }}
                        animate={{ translateX: collapse ? '100%' : 0 }}
                        transition={{ ease: 'easeOut', duration: 0.75 }}
                        collapse={collapse}
                    >
                        {tabs.map(tab => {
                            if (tabData[tab].free || (user.reciept?.type === 'account' && !user.expired)) {
                                return (
                                    <Tab key={tabData[tab].id} onClick={() => dispatch(setDrawer(tabData[tab].id))} className={tabData[tab].id}>
                                        {tabData[tab].icon}
                                        {tabData[tab].title}
                                    </Tab>
                                );
                            } else {
                                return (
                                    <UpgradeTooltip
                                        children={
                                            <Tab onClick={() => ''} id={tabData[tab].id}>
                                                {tabData[tab].icon}
                                                {tabData[tab].title}
                                            </Tab>
                                        }
                                    />
                                );
                            }
                        })}
                    </TabContainer>
                </>
            ) : (
                <>
                    <Drawer title={tabData[openDrawer].title} id={openDrawer} closeDrawers={() => dispatch(setDrawer(''))} />
                    <BackDrop onClick={() => dispatch(setDrawer(''))} />
                </>
            )}
        </SidebarContainer>
    );
};

export default Sidebar;
