// Library imports
import React from 'react';
import { MDBCard, MDBListGroupItem } from 'mdb-react-ui-kit';
// Component imports
import NoData from '../../../../../../components/NoData';
import TwoColumnView from '../../../../../../components/TwoColumnView';
// Util imports
import { shortName } from '../../../../../../utils';
// Styled components
import { CardTitle, InnerContent } from '../styles';
import { LabelValueListGroup } from '../../../../styles';
import { InjuryStatus, PlayerName } from './styles';

const InjuryList = ({ game }) => {
    if (!game.awayTeam.injuries && !game.homeTeam.injuries) {
        return <NoData />;
    }

    const createInjuryJSX = team => {
        return (
            <MDBCard>
                <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                {!team.injuries || team.injuries.length === 0 ? (
                    <LabelValueListGroup>
                        <MDBListGroupItem>
                            <NoData title="No injuries" message="There are currently no injuries for this team." />
                        </MDBListGroupItem>
                    </LabelValueListGroup>
                ) : (
                    <LabelValueListGroup>
                        {team.injuries.map((injury, j) => {
                            return (
                                <MDBListGroupItem key={j}>
                                    <PlayerName>
                                        {injury.player} ({injury.position})
                                    </PlayerName>
                                    <span>
                                        <InjuryStatus>
                                            {injury.status} | {injury.date}
                                        </InjuryStatus>
                                        {injury.desc}
                                    </span>
                                </MDBListGroupItem>
                            );
                        })}
                    </LabelValueListGroup>
                )}
            </MDBCard>
        );
    };

    return (
        <InnerContent fluid>
            <TwoColumnView columnOneJSX={createInjuryJSX(game.awayTeam)} columnTwoJSX={createInjuryJSX(game.homeTeam)} />
        </InnerContent>
    );
};

export default InjuryList;
