// Library imports
import React from 'react';
import { bool, node, shape, string } from 'prop-types';
// Component imports
import Header from '../Header';
import Footer from '../Footer';
// Styled components
import { Main, Wrapper } from './styles';

const LayoutWithHeader = ({ fixedHeader = false, children = [<></>], page, showBanner = false, contentStyle = {}, shadow = true }) => {
    return (
        <Wrapper>
            <Header fixedHeader={fixedHeader} currentPage={page} showBanner={showBanner} shadow={shadow} />
            <Main style={contentStyle}>{children}</Main>
            <Footer />
        </Wrapper>
    );
};

LayoutWithHeader.propTypes = {
    page: string.isRequired,
    children: node,
    showBanner: bool,
    contentStyle: shape({}),
    fixedHeader: bool,
    shadow: bool,
};

export default LayoutWithHeader;
