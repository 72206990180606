// Library imports
import React, { useEffect, useState } from 'react';
import { MDBCard, MDBListGroupItem } from 'mdb-react-ui-kit';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { shape } from 'prop-types';
// Component imports
import TwoColumnView from '../../../../../../components/TwoColumnView';
import TabNav from '../../../../../../components/TabNav';
import NoData from '../../../../../../components/NoData';
import Dropdown from '../../../../../../components/DropDown';
// Util imports
import { shortName } from '../../../../../../utils';
// Styled components
import { CardTitle, InnerContent } from '../styles';
import { LabelValueListGroup } from '../../../../styles';
import { GameDate, GameDetailsWrapper, TeamName } from './styles';

const Schedule = ({ game }) => {
    // Redux
    const { teams } = useSelector(state => state.app);
    // Local state
    const [scheduleTab, setScheduleTab] = useState('Last 5 games');
    const [selectedTeam, setSelectedTeam] = useState(null);
    // Local variables
    const awayData = teams.find(team => team.teamId === game.awayTeam.teamId);
    const homeData = teams.find(team => team.teamId === game.homeTeam.teamId);
    var commonOppIds = [];
    if (awayData?.schedule && homeData?.schedule) {
        const awaySet = new Set(awayData?.schedule.filter(game => game.oppId && game.points && game.opp_pts).map(item => item.oppId));
        const homeSet = new Set(homeData?.schedule.filter(game => game.oppId && game.points && game.opp_pts).map(item => item.oppId));
        commonOppIds = [...awaySet].filter(id => homeSet.has(id));
    }

    const getTeamData = (value, property) => {
        return teams.find(team => team[property] === value || team.city === value);
    };

    useEffect(() => {
        if (commonOppIds?.length > 0) {
            setSelectedTeam(commonOppIds[0]);
        }
    }, commonOppIds);

    const renderGameList = games => {
        return games.map((game, i) => {
            const color = game.result === 'W' ? 'success' : game.result === 'L' ? 'danger' : 'light';
            return (
                <MDBListGroupItem color={color} key={i}>
                    <GameDetailsWrapper>
                        <GameDate>{moment(game.date).format('MM/DD/YY')}</GameDate>
                        <TeamName>
                            {game.location === 'away' ? '@' : 'vs.'} {game.oppAbbr}
                        </TeamName>
                    </GameDetailsWrapper>
                    <div>
                        {game.points}-{game.opp_pts} {game.result}
                    </div>
                </MDBListGroupItem>
            );
        });
    };

    const renderLastFiveGames = team => {
        var schedule = team.teamId === game.awayTeam.teamId ? awayData.schedule : homeData.schedule;
        if (!schedule || !Array.isArray(schedule)) {
            schedule = [];
        }
        const games = schedule
            .filter(game => game.points && game.opp_pts)
            .slice(-5)
            .reverse();
        return (
            <MDBCard>
                <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                <LabelValueListGroup direction="row">
                    <MDBListGroupItem>
                        <label>Overall</label>
                        <span>{team.recordData ? team.recordData.record : 'NA'}</span>
                    </MDBListGroupItem>
                    <MDBListGroupItem style={{ borderBottom: '0px' }}>
                        <label>{team.teamId === game.awayTeam.teamId ? `Away: ` : `Home: `}</label>
                        <span>
                            {team.teamId === game.awayTeam.teamId
                                ? `${team.recordData ? team.recordData.awayRecord : 'NA'}`
                                : `${team.recordData ? team.recordData.homeRecord : 'NA'}`}
                        </span>
                    </MDBListGroupItem>
                </LabelValueListGroup>
                <LabelValueListGroup coloredList="true" direction="row">
                    {!schedule || !games?.length ? (
                        <NoData title="No data" message="There is currently no schedule data for this team." />
                    ) : (
                        renderGameList(games)
                    )}
                </LabelValueListGroup>
            </MDBCard>
        );
    };

    const commonGames = team => {
        var schedule = team.teamId === game.awayTeam.teamId ? awayData.schedule : homeData.schedule;
        if (!schedule || !Array.isArray(schedule)) {
            schedule = [];
        }
        const games = schedule
            .filter(game => game.points && game.opp_pts && selectedTeam === game.oppId)
            .slice(0)
            .reverse();

        return (
            <MDBCard>
                <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                <LabelValueListGroup coloredList="true" direction="row">
                    {!schedule || !games?.length ? <NoData title="No data" message="There is currently no common game data." /> : renderGameList(games)}
                </LabelValueListGroup>
            </MDBCard>
        );
    };

    const headToHead = team => {
        var schedule = team.teamId === game.awayTeam.teamId ? awayData.schedule : homeData.schedule;
        const otherTeamId = team.teamId === game.awayTeam.teamId ? game.homeTeam.teamId : game.awayTeam.teamId;
        if (!schedule || !Array.isArray(schedule)) {
            schedule = [];
        }
        const games = schedule
            .filter(game => game.points && game.opp_pts && game.oppId === otherTeamId)
            .slice(0)
            .reverse();
        return (
            <MDBCard>
                <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                <LabelValueListGroup coloredList="true" direction="row">
                    {!schedule || !games?.length ? <NoData title="No data" message="There is currently no head to head data." /> : renderGameList(games)}
                </LabelValueListGroup>
            </MDBCard>
        );
    };

    return (
        <InnerContent fluid>
            <TabNav
                tabs={['Last 5 games', 'Head to Head', 'Common Games']}
                activeTab={scheduleTab}
                setTab={setScheduleTab}
                containerStyle={{ marginBottom: '1rem' }}
            />
            {scheduleTab === 'Common Games' && commonOppIds?.length > 0 && (
                <Dropdown
                    value={getTeamData(selectedTeam, 'teamId').teamName}
                    setValue={teamName => setSelectedTeam(Number(getTeamData(teamName, 'teamName').teamId))}
                    menuItems={commonOppIds.map(id => getTeamData(id, 'teamId').teamName)}
                    caption="Select a team to view common games"
                />
            )}
            <TwoColumnView
                columnOneJSX={
                    scheduleTab === 'Last 5 games'
                        ? renderLastFiveGames(game.awayTeam)
                        : scheduleTab === 'Head to Head'
                        ? headToHead(game.awayTeam)
                        : commonGames(game.awayTeam)
                }
                columnTwoJSX={
                    scheduleTab === 'Last 5 games'
                        ? renderLastFiveGames(game.homeTeam)
                        : scheduleTab === 'Head to Head'
                        ? headToHead(game.homeTeam)
                        : commonGames(game.homeTeam)
                }
            />
        </InnerContent>
    );
};

Schedule.propTypes = {
    game: shape({}).isRequired,
};

export default Schedule;
