// Library imports
import styled from 'styled-components';
import { Tooltip } from 'react-bootstrap';

export const TooltipContainer = styled(Tooltip)`
    &.tooltip.show {
        opacity: 1 !important;
        z-index: 9999999999 !important;
    }
    .tooltip-inner {
        padding: 10px;
        background: #fff;
        opacity: 1;
        border: ${({ theme }) => `1px solid ${theme.colors.primaryDark}`};
        max-width: 400px;
        height: 400px;
        overflow-y: scroll;
        text-align: left;
    }
`;

export const OverlayButton = styled.div`
    position: absolute;
    top: 10px;
    left: 125px;
`;

export const KeyContainer = styled.div`
    margin-top: 15px;
    span {
        color: ${({ theme }) => theme.colors.black};
    }
`;

export const KeyItemContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    gap: 5px;
    span {
        color: ${({ theme }) => theme.colors.black};
        font-size: 14px;
    }
`;
