// Library imports
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
// Component imports
import Testimonials from '../components/Testimonials';
import Section from '../components/Section';
import LayoutWithHeader from '../components/Layouts/LayoutWithHeader';
import HeroSection from '../components/HeroSection';
import SocialMediaSection from '../components/SocialMediaSection';
// Util imports
import { scrollToTop } from '../utils';

const Home = () => {
    // Routing
    const history = useHistory();

    useEffect(() => scrollToTop(), []);

    return (
        <LayoutWithHeader fixedHeader={true} page="home">
            <Helmet>
                <title>Home | Line Prophet</title>
                <meta
                    name="description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Home | Line Prophet" />
                <meta
                    property="og:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta property="og:url" content="https://www.lineprophet.com/" />
                <meta property="og:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Home | Line Prophet" />
                <meta
                    name="twitter:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="twitter:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />
                <meta name="twitter:site" content="@LineProphetLLC" />
                <meta name="twitter:creator" content="@LineProphetLLC" />

                {/* Favicon */}
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <HeroSection titleText="Line Prophet" subText="Data, algorithms, & experts focused on winning." />
            <Section
                backgroundColor="white"
                topLine="Why choose Line Prophet?"
                headline="Insights that give you an edge."
                description="Empower your sports betting journey with Line Prophet - your comprehensive toolkit for serious enthusiasts. Our cutting-edge data and advanced algorithms provide unparalleled insights, arming you with a clearer perspective on every game."
                buttons={[
                    <MDBBtn onClick={() => history.push('/about')} color="primary" size="lg">
                        <MDBIcon className="me-2" icon="book-reader" />
                        Learn More
                    </MDBBtn>,
                ]}
                contentSide="left"
                contentType="card"
                slideDirection="img-slide-right"
            />
            <Section
                backgroundColor="silver"
                topLine="Predictive Algorithm"
                headline="Predictions for every game."
                description="Unlock the future of sports outcomes with our algorithmic models, delivering predictive final scores for every game. Our focus on key factors, trends, and unique situations allows us to generate a realistic projection of the ultimate outcome."
                buttons={[
                    <MDBBtn onClick={() => history.push('/about')} color="primary" size="lg">
                        <MDBIcon className="me-2" icon="book-reader" />
                        Learn More
                    </MDBBtn>,
                ]}
                contentSide="right"
                contentType="card"
                defaultCardWindow="prediction"
            />
            <Section
                backgroundColor="white"
                topLine="Honesty, transparency, results"
                headline="VIP Picks & Transparency."
                description="Explore our VIP Picks and Transparency Drawer, offering a transparent window into historical prediction data. Track the algorithm's success over time to uncover valuable model trends. With Line Prophet, informed decisions are not just a possibility but a result of data-driven insights and a commitment to transparency."
                buttons={[
                    <MDBBtn onClick={() => history.push('/about')} color="primary" size="lg">
                        <MDBIcon className="me-2" icon="book-reader" />
                        Learn More
                    </MDBBtn>,
                ]}
                contentSide="left"
                contentType="vip-stats-yearly"
            />
            <SocialMediaSection />
            <Testimonials bgColor="white" />
        </LayoutWithHeader>
    );
};

export default Home;
