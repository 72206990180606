// Library imports
import React from 'react';
// Styled components
import { GridWrapper } from './styles';

const Grid = ({ children }) => {
    return <GridWrapper>{children}</GridWrapper>;
};

export default Grid;
