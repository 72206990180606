import styled from 'styled-components';

export const ScoreText = styled.span`
    font-family: ${({ theme }) => theme.fonts.heading};
    font-size: 1.75rem;
    text-align: center;
    font-weight: 700;
    color: ${props => props.color};
    margin-top: 0;
`;

export const PowerMetrics = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;
    gap: 0.25rem;
`;

export const PowerMetric = styled.div`
    label {
        font-weight: 500;
        font-size: 1rem;
        color: ${({ theme }) => theme.colors.darkGray};
    }
    span {
        font-family: ${({ theme }) => theme.fonts.heading};
        font-size: 1.25rem;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.black};
    }
`;
