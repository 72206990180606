export const TOOL_PAGES = [
    {
        title: 'Games',
        id: 'games',
        free: true,
        fullScreen: false,
        icon: 'list-ul', // FontAwesome icon for "Games"
    },
    {
        title: 'Props',
        id: 'props',
        free: true,
        fullScreen: false,
        icon: 'fas fa-bolt', // FontAwesome icon for "Props"
    },
    {
        title: 'Search Builder',
        id: 'search-builder',
        free: false,
        fullScreen: true,
        icon: 'fas fa-search', // FontAwesome icon for "Search Builder"
    },
    {
        title: 'Trends',
        id: 'trends',
        free: false,
        fullScreen: true,
        icon: 'fas fa-chart-line', // FontAwesome icon for "Trends"
    },
    {
        title: 'Algorithm',
        id: 'algorithm',
        free: false,
        fullScreen: false,
        icon: 'fas fa-cogs', // FontAwesome icon for "Algorithm"
    },
];
