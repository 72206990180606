import styled from 'styled-components';

export const SpinnerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 50%;
    min-height: 125px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
`;
