// Library imports
import styled from 'styled-components';

export const MediaContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.white};
    padding: 5rem;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;
