// Library imports
import React, { useEffect, useState } from 'react';
import { MDBCardBody, MDBCol, MDBIcon, MDBListGroup, MDBRow, MDBTabs, MDBTabsContent, MDBTabsItem, MDBTabsLink, MDBTabsPane } from 'mdb-react-ui-kit';
import { useTheme } from 'styled-components';
import { Helmet } from 'react-helmet';
// Component imports
import LayoutWithHeader from '../../components/Layouts/LayoutWithHeader';
import Testimonials from '../../components/Testimonials';
import FeatureSection from '../../components/FeatureSection';
// Util imports
import { scrollToTop } from '../../utils';
import { PACKAGES } from '../../utils/constants';
// Constants
import { FEATURE_LIST, HOW_IT_WORKS } from './constants';
// Styled components
import { PriceText, CardTitle, HowItWorksCard, HowItWorksTitle, ListGroupItem, PackageCard, PurchaseButton, HowItWorksDescription, CardHeader } from './styles';

const Packages = () => {
    const theme = useTheme();
    // Local state
    const [packageKey, setPackageKey] = useState('access');

    useEffect(() => {
        scrollToTop();
    }, []);

    return (
        <LayoutWithHeader fixedHeader={false} page="packages">
            <Helmet>
                <title>Packages | Line Prophet</title>
                <meta
                    name="description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Packages | Line Prophet" />
                <meta
                    property="og:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta property="og:url" content="https://www.lineprophet.com/" />
                <meta property="og:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Packages | Line Prophet" />
                <meta
                    name="twitter:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="twitter:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />
                <meta name="twitter:site" content="@LineProphetLLC" />
                <meta name="twitter:creator" content="@LineProphetLLC" />

                {/* Favicon */}
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            {/* Packages Section */}
            <FeatureSection bgColor={theme.colors.silver} title="Package Options" subTitle="Choose the package that best fits your needs.">
                <MDBTabs style={{ margin: '0 auto', maxWidth: '45rem' }} justify className="mb-3">
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => setPackageKey('access')} active={packageKey === 'access'}>
                            <MDBIcon fas icon="chart-line" className="me-2" /> All Access
                        </MDBTabsLink>
                    </MDBTabsItem>
                    <MDBTabsItem>
                        <MDBTabsLink onClick={() => setPackageKey('picks')} active={packageKey === 'picks'}>
                            <MDBIcon fas icon="chart-line" className="me-2" /> Just Picks
                        </MDBTabsLink>
                    </MDBTabsItem>
                </MDBTabs>
                <MDBTabsContent>
                    <MDBTabsPane open={packageKey === 'access'}>
                        <MDBRow className="justify-content-center">
                            {Object.values(PACKAGES)
                                .filter(packageObj => packageObj.type === 'account')
                                .map((packageObj, i) => (
                                    <MDBCol style={{ marginTop: '15px' }} md={12} lg={6} key={i}>
                                        <PackageCard onClick={() => (window.location = `/register?package=${packageObj.id}`)}>
                                            <MDBCardBody>
                                                <CardHeader>
                                                    <CardTitle>{packageObj.name}</CardTitle>
                                                    <PriceText>{`$${packageObj.price}.00/${packageObj.timeFrame}`}</PriceText>
                                                </CardHeader>
                                                <MDBListGroup variant="flush">
                                                    {FEATURE_LIST.access.map((feature, i) => (
                                                        <ListGroupItem key={i} className="d-flex justify-content-between align-items-center">
                                                            <div>
                                                                <div className="fw-bold">
                                                                    <MDBIcon {...(feature.icon === 'x-twitter' ? { fab: true } : {})} icon={feature.icon} />{' '}
                                                                    {feature.title}
                                                                </div>
                                                                <div className="text-muted">{feature.description}</div>
                                                            </div>
                                                        </ListGroupItem>
                                                    ))}
                                                </MDBListGroup>
                                                <div style={{ padding: '15px 10px' }}>
                                                    <PurchaseButton onClick={() => (window.location = `/register?package=${packageObj.id}`)}>
                                                        Purchase Package
                                                    </PurchaseButton>
                                                </div>
                                            </MDBCardBody>
                                        </PackageCard>
                                    </MDBCol>
                                ))}
                        </MDBRow>
                    </MDBTabsPane>
                    <MDBTabsPane open={packageKey === 'picks'}>
                        <MDBRow className="justify-content-center">
                            {Object.values(PACKAGES)
                                .filter(packageObj => packageObj.type === 'picks')
                                .map((packageObj, i) => {
                                    return (
                                        <MDBCol style={{ marginTop: '15px' }} md={12} lg={6} key={i}>
                                            <PackageCard onClick={() => (window.location = `/register?package=${packageObj.id}`)}>
                                                <MDBCardBody>
                                                    <CardHeader>
                                                        <CardTitle>{packageObj.name}</CardTitle>
                                                        <PriceText>{`$${packageObj.price}.00`}</PriceText>
                                                    </CardHeader>
                                                    <MDBListGroup variant="flush">
                                                        {FEATURE_LIST.picks.map((feature, i) => (
                                                            <ListGroupItem key={i} className="d-flex justify-content-between align-items-center">
                                                                <div>
                                                                    <div className="fw-bold">
                                                                        <MDBIcon {...(feature.icon === 'x-twitter' ? { fab: true } : {})} icon={feature.icon} />{' '}
                                                                        {feature.title}
                                                                    </div>
                                                                    <div className="text-muted">{feature.description}</div>
                                                                </div>
                                                            </ListGroupItem>
                                                        ))}
                                                    </MDBListGroup>
                                                    <div style={{ padding: '15px 10px' }}>
                                                        <PurchaseButton onClick={() => (window.location = `/register?package=${packageObj.id}`)}>
                                                            Purchase Plays
                                                        </PurchaseButton>
                                                    </div>
                                                </MDBCardBody>
                                            </PackageCard>
                                        </MDBCol>
                                    );
                                })}
                        </MDBRow>
                    </MDBTabsPane>
                </MDBTabsContent>
            </FeatureSection>
            {/* How it works section */}
            <FeatureSection
                bgColor={theme.colors.primaryDark}
                title="How it Works"
                titleStyle={{ color: theme.colors.white }}
                subTitle="It's simple! Just follow these easy steps to get started"
                subTitleStyle={{ color: theme.colors.white }}
            >
                <MDBRow>
                    {HOW_IT_WORKS.map((item, i) => {
                        return (
                            <MDBCol key={i} md="12" lg="6" className="mb-4">
                                <HowItWorksCard>
                                    <MDBCardBody className="d-flex flex-column align-items-center justify-content-center">
                                        <MDBIcon
                                            {...(item.icon === 'x-twitter' ? { fab: true } : {})}
                                            icon={item.icon}
                                            size="3x"
                                            style={{ color: theme.colors.primary }}
                                        />
                                        <HowItWorksTitle>{item.title}</HowItWorksTitle>
                                        <HowItWorksDescription>{item.description}</HowItWorksDescription>
                                    </MDBCardBody>
                                </HowItWorksCard>
                            </MDBCol>
                        );
                    })}
                </MDBRow>
            </FeatureSection>
            {/* Reviews Section */}
            <Testimonials />
        </LayoutWithHeader>
    );
};

export default Packages;
