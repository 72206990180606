// Library imports
import styled from 'styled-components';

export const InputContainer = styled.div`
    margin-bottom: 1rem;
`;

export const ErrorMessage = styled.span`
    color: ${({ theme }) => theme.colors.red} !important;
`;
