// Library and Component imports
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Route, BrowserRouter } from 'react-router-dom';
import App from './app_pages/App';
import Login from './static_pages/Login';
import Home from './static_pages/Home';
import About from './static_pages/About';
import Packages from './static_pages/Packages';
import Register from './static_pages/Register';
import Profile from './app_pages/Profile';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import * as serviceWorker from './serviceWorker';
// Redux imports
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import rootReducer from './store/rootReducer';
// Firebase init
import './Firebase';
// MDB imports
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'react-loading-skeleton/dist/skeleton.css';

export const store = configureStore({
    reducer: rootReducer,
});

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Roboto', sans-serif;
    font-synthesis: none !important;
  }

  *:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  html,
  body,
  #root,
  .App {
    width: 100%;
    min-height: 100%;
    margin: 0px;
    padding: 0px;
    overflow-x: hidden;
  }

  div {
    box-sizing: border-box;
  }

  p {
    display: block;
    color: #333;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-left: 0px;
    padding-right: 0px;
    text-rendering: optimizeLegibility;
  }

  button:hover {
    cursor: pointer;
  }

  button:disabled,
  button:disabled svg {
    color: #ccc;
  }

  .hide {
    display: none !important;
  }

  ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  /*** Global CSS ***/
  div[maskclassname='mask'] {
    opacity: 0.5;
  }

  .pagination>li.page-item.active>.page-link>span {
    display: none;
  }
`;

const theme = {
    colors: {
        primary: '#006eb5',
        primaryDark: '#1c2237',
        primaryMedium: '#9be4ff',
        primaryLight: '#cdf1ff',
        darkGray: '#7a7a7a',
        gray: '#D3D3D3',
        white: '#fff',
        black: '#111',
        red: '#d63031',
        green: '#00b894',
        gold: '#bf9b30',
        silver: '#f5f7fb',
        borderColor: '#ddd',
    },
    fonts: {
        heading: 'Poppins, sans-serif',
        body: 'Roboto, sans-serif',
    },
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <GlobalStyle />
            <BrowserRouter>
                <Route exact path="/" render={props => <Home {...props} />} />
                <Route exact path="/login" component={Login} />
                <Route path="/about" render={props => <About {...props} />} />
                <Route path="/packages" render={props => <Packages {...props} />} />
                <Route path="/register" render={props => <Register {...props} />} />
                <Route path="/sports/:sport" render={props => <App {...props} />} />
                <Route path="/profile" render={props => <Profile {...props} />} />
            </BrowserRouter>
        </ThemeProvider>
    </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
