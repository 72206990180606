// Library imports
import React from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
// Styled components
import { FooterBottom, FooterContainer, FooterLink, FooterText, FooterTitle, SocialIcon } from './styles';

const Footer = () => {
    return (
        <FooterContainer>
            <MDBContainer fluid>
                <MDBRow className="mb-4" style={{ justifyContent: 'center' }}>
                    <MDBCol md="3" className="mb-4">
                        <FooterTitle>About Line Prophet</FooterTitle>
                        <FooterText>
                            Empower your sports betting journey with Line Prophet - your comprehensive toolkit for serious enthusiasts. Our cutting-edge data
                            and advanced algorithms provide unparalleled insights, arming you with a clearer perspective on every game.
                        </FooterText>
                    </MDBCol>
                    <MDBCol md="2" className="mb-4">
                        <FooterTitle>Quick Links</FooterTitle>
                        <FooterLink href="/about">About Us</FooterLink>
                        <br />
                        <FooterLink href="/packages">Get Access</FooterLink>
                        <br />
                        <FooterLink target="_blank" href="https://www.privacypolicies.com/live/24b09fba-d008-47b2-a1ea-f3400ec50625">
                            Terms of Service
                        </FooterLink>
                        <br />
                        <FooterLink target="_blank" href="https://www.privacypolicies.com/live/2fe2e855-51bd-49ea-9547-15140b9c4134">
                            Privacy Policy
                        </FooterLink>
                    </MDBCol>
                    <MDBCol md="2" className="mb-4">
                        <FooterTitle>Explore</FooterTitle>
                        <FooterLink href="/">Home</FooterLink>
                        <br />
                        <FooterLink href="/login">Dashboard</FooterLink>
                        <br />
                        <FooterLink target="_blank" href="https://apps.apple.com/us/app/line-prophet/id1641926747">
                            App Store
                        </FooterLink>
                        <br />
                        <FooterLink target="_blank" href="https://vipclub.lv/#/signup/lineprophet">
                            Sportbook Partner
                        </FooterLink>
                        <br />
                        <FooterLink target="_blank" href="https://blog.lineprophet.com">
                            Blog
                        </FooterLink>
                    </MDBCol>
                    <MDBCol md="3" className="mb-4">
                        <FooterTitle>Follow Us</FooterTitle>
                        <FooterText>Stay updated via our social channels.</FooterText>
                        <SocialIcon onClick={() => window.open('https://twitter.com/LineProphetLLC', '_blank')} fab icon="twitter" />
                        <SocialIcon onClick={() => window.open('https://www.instagram.com/lineprophetllc/', '_blank')} fab icon="instagram" />
                    </MDBCol>
                </MDBRow>
                <FooterBottom>
                    <FooterText>&copy; {new Date().getFullYear()} Line Prophet LLC. All Rights Reserved.</FooterText>
                </FooterBottom>
            </MDBContainer>
        </FooterContainer>
    );
};

export default Footer;
