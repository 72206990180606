// Library imports
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { SMALL_SCREEN_WIDTH } from '../../utils/constants';

export const HeroWrapper = styled.div`
    height: 100vh;
    background: url(${({ backgroundImage }) => backgroundImage}) no-repeat center center / cover;
    background-color: ${({ theme }) => theme.colors.primaryDark};
    position: relative;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        background: rgba(0, 0, 0, 0.5); /* Adds a dark overlay */
        z-index: 0;
    }
`;

export const HeroContent = styled(motion.div)`
    position: relative;
    z-index: 1; /* Ensures content is above the overlay */
    max-width: 800px; /* Ensures text stays centered on smaller screens */
    margin: 0 auto;
    padding: 20px;
`;

export const HeroImage = styled.img`
    height: 400px;
    width: 400px;
    aspect-ratio: auto 400 / 400;

    @media all and (max-width: ${SMALL_SCREEN_WIDTH}) {
        height: 300px;
        width: 300px;
        aspect-ratio: auto 300 / 300;
    }
`;

export const HeroHeading = styled(motion.h1)`
    font-size: clamp(2.5rem, 5vw, 4rem);
    font-weight: 900;
    text-transform: uppercase;
    font-family: ${({ theme }) => theme.fonts.heading};
`;

export const HeroSubText = styled(motion.p)`
    margin-bottom: 30px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 2rem;
    font-weight: 500;
`;

export const HeroButton = styled(motion.button)`
    background-color: ${({ theme }) => theme.colors.primary};
    color: ${({ theme }) => theme.colors.white};
    padding: 15px 30px;
    border: none;
    border-radius: 50px;
    font-size: 1.25rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        background-color: ${({ theme }) => theme.colors.primaryDark};
        transform: scale(1.1);
    }
`;
