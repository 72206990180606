// Library imports
import React from 'react';
import { MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
// Component imports
import NoData from '../../../../../../components/NoData';
// Styled components
import { InnerContent } from '../styles';
import { HeadlineDescription, HeadlineTitle, HeadlineLink } from './styles';

const Headlines = ({ game }) => {
    const nthIndex = (str, pat, n) => {
        var L = str.length,
            i = -1;
        while (n-- && i++ < L) {
            i = str.indexOf(pat, i);
            if (i < 0) break;
        }
        return i;
    };

    if (!game.headlines) {
        return <NoData title="No data" message="There are currently no headlines for this game.  Check back soon." />;
    }

    return (
        <InnerContent>
            <MDBListGroup light className="px-3">
                {game.headlines.map((headline, i) => {
                    var title = headline.title.substr(0, 100) + '...';
                    var description = headline.description.substr(0, 250) + '...';
                    var href = headline.href.substr(headline.href.indexOf('q=') + 2, nthIndex(headline.href, '.com', 2));
                    return (
                        <MDBListGroupItem className="mb-2" key={i}>
                            <HeadlineTitle style={{ display: 'block' }} target="_blank" rel="noopener noreferrer" href={headline.href}>
                                {title}
                            </HeadlineTitle>
                            <HeadlineLink>{href}</HeadlineLink>
                            <HeadlineDescription>{description}</HeadlineDescription>
                        </MDBListGroupItem>
                    );
                })}
            </MDBListGroup>
        </InnerContent>
    );
};

export default Headlines;
