// Library imports
import { MDBContainer, MDBIcon } from 'mdb-react-ui-kit';
import { useHistory } from 'react-router-dom';
// Image imports
import headerImage from '../../img/logo-icon-white.png';
// Styled components
import { HeroButton, HeroContent, HeroHeading, HeroSubText, HeroWrapper, HeroImage } from './styles';

const HeroSection = ({ titleText = '', subText = '', backgroundImage = '', buttonText = 'Get Access' }) => {
    // Routing
    const history = useHistory();

    return (
        <HeroWrapper backgroundImage={backgroundImage}>
            <MDBContainer>
                <HeroContent initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1.5 }}>
                    <HeroImage src={headerImage} alt="Line Prophet Logo" width={400} height={400} />
                    <HeroHeading initial={{ y: -50 }} animate={{ y: 0 }} transition={{ duration: 1 }}>
                        {titleText}
                    </HeroHeading>
                    <HeroSubText initial={{ y: 50 }} animate={{ y: 0 }} transition={{ duration: 1 }}>
                        {subText}
                    </HeroSubText>
                    <HeroButton onClick={() => history.push('/packages')} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
                        <MDBIcon icon="user-plus" /> {buttonText}
                    </HeroButton>
                </HeroContent>
            </MDBContainer>
        </HeroWrapper>
    );
};

export default HeroSection;
