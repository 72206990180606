// Library imports
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, ListGroup } from 'react-bootstrap';
// Component imports
import { queryFields } from './queryFields';
// Action imports
import { toggleField } from '../../../store/slices/queryBuilderSlice';
// Styled components
import { LabelValueListGroup } from '../styles';

const AddFilters = () => {
    // Redux
    const { activeFields } = useSelector(state => state.queryBuilder);
    const dispatch = useDispatch();

    return (
        <div>
            <LabelValueListGroup direction="row" variant="flush">
                {Object.keys(queryFields).map(id => {
                    const fieldData = queryFields[id];
                    return (
                        <ListGroup.Item
                            key={id}
                            style={{
                                borderBottom: '1px solid #ddd',
                                margin: '0px -25px',
                                padding: '15px 25px',
                            }}
                        >
                            <label>{fieldData.title}</label>
                            <span>
                                <Form.Check onClick={() => dispatch(toggleField(fieldData.id))} checked={activeFields.includes(fieldData.id)} />
                            </span>
                        </ListGroup.Item>
                    );
                })}
            </LabelValueListGroup>
        </div>
    );
};

export default AddFilters;
