// Library imports
import React from 'react';
import validator from 'validator';
import { useDispatch, useSelector } from 'react-redux';
import { func } from 'prop-types';
import { useForm } from 'react-hook-form';
import { MDBBtn, MDBIcon } from 'mdb-react-ui-kit';
// Component imports
import TextInput from '../../components/InputLibrary/TextInput';
// Firebase imports
import firebase from 'firebase';
// Action imports
import { setData, setStage } from '../../store/slices/registerSlice';
// Styled components
import { ButtonGroup } from './styles';

const Information = ({ setError }) => {
    // Redux
    const { data } = useSelector(state => state.register);
    const dispatch = useDispatch();

    const {
        control,
        handleSubmit,
        getValues,
        reset,
        formState: { isValid },
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            displayName: data.displayName,
            password: data.password,
            vpassword: data.vpassword,
            twitter: data.twitter,
            discord: data.discord,
            promo: data.promo,
        },
    });

    const isAccessPackage = data.plan?.type === 'account';

    const handleFormReset = e => {
        e.preventDefault();
        reset();
        setError('');
    };

    const onSubmit = async formData => {
        const trimmedData = Object.fromEntries(Object.entries(formData).map(([key, value]) => [key, typeof value === 'string' ? value.trim() : value]));
        const emailExist = await firebase.auth().fetchSignInMethodsForEmail(trimmedData.email);
        if (isAccessPackage) {
            if (emailExist.length !== 0) return setError('That email already exists.  Please use a different email.');
        } else {
            if (emailExist.length !== 0) return setError('Your account already has access.  No need to buy a pick package you will get every play.');
        }
        if (!data.plan) return setError('Please fill out all required fields. [Plan].');
        dispatch(setData(trimmedData));
        dispatch(setStage('pay'));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {isAccessPackage && (
                <>
                    <TextInput name="firstName" label="First Name" size="lg" control={control} isRequired={true} type="text" />
                    <TextInput name="lastName" label="Last Name" size="lg" control={control} isRequired={true} type="text" />
                </>
            )}
            <TextInput
                name="email"
                label="Email Address"
                size="lg"
                control={control}
                isRequired={true}
                type="email"
                rules={{
                    validate: {
                        emailFormat: value => validator.isEmail(value) || 'Invalid email format',
                    },
                }}
            />
            {isAccessPackage && (
                <TextInput
                    name="displayName"
                    label="Display Name"
                    size="lg"
                    control={control}
                    isRequired={true}
                    type="text"
                    rules={{
                        maxLength: { value: 14, message: 'Display name must be shorter than 15 characters' },
                        minLength: { value: 3, message: 'Display name must be at least 3 characters' },
                        validate: {
                            noSpaces: value => !/\s/.test(value) || 'No spaces allowed',
                        },
                    }}
                />
            )}
            {isAccessPackage && (
                <>
                    <TextInput
                        name="password"
                        label="Password"
                        size="lg"
                        control={control}
                        isRequired={true}
                        type="password"
                        rules={{
                            minLength: { value: 5, message: 'Password must be at least 5 characters' },
                            maxLength: { value: 100, message: 'Password cannot exceed 100 characters' },
                        }}
                    />
                    <TextInput
                        name="vpassword"
                        label="Verify Password"
                        size="lg"
                        control={control}
                        isRequired={true}
                        type="password"
                        rules={{
                            validate: {
                                verifyPassword: value => value === getValues('password') || 'Passwords do not match',
                            },
                        }}
                    />
                </>
            )}
            {isAccessPackage && (
                <>
                    <TextInput
                        name="twitter"
                        label="Twitter (optional)"
                        helperText="Your Twitter (X) username is very helpful for onboarding into the community"
                        size="lg"
                        control={control}
                        type="text"
                    />
                    <TextInput name="discord" label="Discord (optional)" size="lg" control={control} type="text" />
                </>
            )}
            <TextInput name="promo" label="Promo Code (optional)" size="lg" control={control} type="text" />
            <ButtonGroup>
                <MDBBtn color="danger" onClick={handleFormReset}>
                    Reset
                </MDBBtn>
                <MDBBtn disabled={!isValid} color="primary" type="submit">
                    Continue
                    <MDBIcon style={{ marginLeft: '5px' }} icon="circle-right" />
                </MDBBtn>
            </ButtonGroup>
        </form>
    );
};

Information.propTypes = {
    setError: func.isRequired,
};

export default Information;
