// Library imports
import styled from 'styled-components';
import { MDBContainer } from 'mdb-react-ui-kit';
// Constant imports
import { POWER_BREAK_POINT } from './PowerRank/constants';

export const InnerContent = styled(MDBContainer)`
    overflow-y: scroll;
    padding-top: 0.5rem;
    padding-bottom: 1rem;
`;

export const CardTitle = styled.span`
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: 900;
    font-size: 1rem;
    white-space: nowrap;
    padding: 0.75rem 1rem;
    position: relative;
    @media (max-width: ${POWER_BREAK_POINT}) {
        ${p => p.responsive && 'padding: 0.75rem 0px;'}
    }
    ${props => props.color && `color: ${props.color};`}
`;

export const TeamRank = styled.span`
    position: absolute;
    font-size: 18px;
    top: -25px;
    ${({ right }) => (right === 'true' ? 'right: -20px;' : 'left: 10px;')}
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
`;

export const HighLightedField = styled.span`
    ${props => props.width && `width: ${props.width};`}
    background-color: ${({ variant, theme }) =>
        variant === 'success' ? '#d4edda' : variant === 'danger' ? '#f8d7da' : variant === 'primary' ? theme.colors.primaryLight : '#f8f9fa'};
    border-color: ${({ variant, theme }) =>
        variant === 'success' ? '#c3e6cb' : variant === 'danger' ? '#f5c6cb' : variant === 'primary' ? theme.colors.primaryDark : '#f8f9fa'};
    color: ${({ variant, theme }) =>
        variant === 'success' ? '#155724' : variant === 'danger' ? '#721c24' : variant === 'primary' ? theme.colors.primaryDark : '#212529'};
    text-align: center;
    padding: 5px 10px;
    font-weight: 500;
    border-radius: 5px;
    white-space: nowrap;
    display: block;
    @media (max-width: ${POWER_BREAK_POINT}) {
        span {
            font-size: 14px;
        }
    }
}
`;
