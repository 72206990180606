// Library imports
import styled from 'styled-components';

export const GridWrapper = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: clamp(15px, 5vw, 50px);
`;
