// Library imports
import React from 'react';
import { MDBBtn } from 'mdb-react-ui-kit';
import { useTheme } from 'styled-components';
import { useDispatch } from 'react-redux';
import { bool, shape } from 'prop-types';
// Component imports
import NoData from '../../../../../../components/NoData';
// Action imports
import { setData } from '../../../../../../store/slices/queryBuilderSlice';
// Util imports
import useScreenWidth from '../../../../../../utils/hooks/useScreenWidth';
import { ADVANCED_SEARCH_SCREEN_SIZE } from '../../../../../../utils/constants';
// Styled components
import { InnerContent } from '../styles';
import { QueryStringContainer, RunQueryContainer, TeamName, TeamNameContainer } from './styles';
// Action imports
import { setSubPage } from '../../../../../../store/slices/appSlice';

const AdvancedSearch = ({ game, demo }) => {
    const theme = useTheme();
    // Redux
    const dispatch = useDispatch();
    // Local state
    const windowWidth = useScreenWidth();

    const runQuery = queryData => {
        dispatch(setData(queryData));
        dispatch(setSubPage('search-builder'));
    };

    if (windowWidth < ADVANCED_SEARCH_SCREEN_SIZE) {
        return <NoData title="Please try again on a desktop." message="You must be on a desktop device to use this feature." variant="warning" />;
    }

    return (
        <InnerContent style={{ backgroundColor: theme.colors.silver, display: 'flex', justifyContent: 'center', alignItems: 'center' }} fluid>
            <RunQueryContainer>
                <QueryStringContainer>
                    <TeamNameContainer>
                        <TeamName color={game.awayTeam.color}>{game.awayTeam.fullName}</TeamName>
                        <span className="subtext">Away team</span>
                    </TeamNameContainer>
                    <span style={{ marginRight: '10px', marginLeft: '10px' }}>vs.</span>
                    <TeamNameContainer>
                        <TeamName color={game.homeTeam.color}>{game.homeTeam.fullName}</TeamName>
                        <span className="subtext" style={{ marginLeft: 'auto' }}>
                            Home team
                        </span>
                    </TeamNameContainer>
                </QueryStringContainer>
                <MDBBtn color="success" onClick={() => runQuery({ awayTeam: game.awayTeam.teamId, homeTeam: game.homeTeam.teamId })} disabled={demo}>
                    Run Query
                </MDBBtn>
            </RunQueryContainer>
        </InnerContent>
    );
};

AdvancedSearch.propTypes = {
    game: shape({}).isRequired,
    demo: bool,
};

export default AdvancedSearch;
