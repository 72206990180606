// Library imports
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { array } from 'prop-types';
// Component imports
import Grid from '../../../components/Layouts/Grid';
import GameCard from '../GameCards/GameCard';
import NoData from '../../../components/NoData';
import AlgoSearchFilter from '../../../components/SearchFilters/AlgoSearchFilter';
// Util imports
import { filterGames } from '../GameCards/utils';

const HistoryTable = ({ gameData = [] }) => {
    // Redux
    const { sport } = useSelector(state => state.app);
    // Local state
    const [value, setValue] = useState('');
    const [filter, setFilter] = useState('All');

    const processGameCards = () => {
        const filteredGames = filterGames(gameData, value, [], 'all');
        if (filteredGames.length) {
            const gameCards = filteredGames.map(game => {
                return <GameCard game={game} key={game.gameId} sport={sport} />;
            });
            return gameCards;
        } else {
            return <NoData />;
        }
    };

    return (
        <div>
            <AlgoSearchFilter value={value} setValue={setValue} filter={filter} setFilter={setFilter} />
            <Grid>{processGameCards()}</Grid>
        </div>
    );
};

HistoryTable.propTypes = {
    gameData: array,
};

export default HistoryTable;
