// Library imports
import styled from 'styled-components';
import { MDBContainer } from 'mdb-react-ui-kit';

export const ProfilePageContainer = styled(MDBContainer)`
    padding: 0.5rem 1rem;
`;

export const ProfileContentContainer = styled.div`
    min-height: 50vh;
`;

export const ProfileHeader = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
`;

export const ProfileUserName = styled.span`
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: 700;
    font-size: 1.65rem;
    color: ${({ theme }) => theme.colors.black};
`;

export const ProfileEmail = styled.span`
    font-family: ${({ theme }) => theme.fonts.body};
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.darkGray};
`;

export const ProfileImage = styled.img`
    height: 85px;
    width: 85px;
`;
