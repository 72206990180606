// Library imports
import React, { useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useTheme } from 'styled-components';
// Styled components
import { TooltipContainer, OverlayButton, KeyContainer, KeyItemContainer } from './styles';
import { HighLightedField } from '../../styles';

const ToolTip = () => {
    // Hooks
    const theme = useTheme();
    // Local state
    const [show, setShow] = useState(false);
    return (
        <OverlayTrigger
            trigger={['hover', 'focus', 'click']}
            overlay={props => {
                return (
                    <TooltipContainer id="button-tooltip-2" {...props} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                        <p>
                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Summary:</span>
                            <span>
                                Implied probability translates a moneyline into a win percentage, reflecting the bookmaker's estimate of an event's likelihood.
                                By converting these odds, you can determine the implied probability of winning.
                            </span>
                        </p>
                        <p>
                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Example:</span>
                            <span>A moneyline of +150 suggests a win chance of around 40%, while -150 translates to a chance of about 60%.</span>
                        </p>
                        <KeyContainer>
                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Legend</span>
                            <KeyItemContainer>
                                <HighLightedField variant="primary">
                                    <span>-150</span>
                                    <span style={{ display: 'block', fontSize: '12px' }}>55.43%</span>
                                </HighLightedField>
                                <span>The blue container represents the favored implied probablity according to Vegas odds.</span>
                            </KeyItemContainer>
                        </KeyContainer>
                        <KeyContainer>
                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Moneylines</span>
                            <KeyItemContainer>
                                <HighLightedField variant="success">
                                    <span>-190</span>
                                    <span style={{ display: 'block', fontSize: '12px' }}>65.43%</span>
                                </HighLightedField>
                                <span>
                                    The green container represents a higher implied probability from the Line Prophet algorithm in relation to the Vegas number.
                                </span>
                            </KeyItemContainer>
                            <KeyItemContainer>
                                <HighLightedField variant="danger">
                                    <span>+130</span>
                                    <span style={{ display: 'block', fontSize: '12px' }}>45.43%</span>
                                </HighLightedField>
                                <span>
                                    The red container represents a lower implied probability from the Line Prophet algorithm in relation to the Vegas number.
                                </span>
                            </KeyItemContainer>
                        </KeyContainer>
                        <KeyContainer>
                            <span style={{ fontWeight: 'bold', marginRight: '5px' }}>Spreads</span>
                            <KeyItemContainer>
                                <HighLightedField variant="success">
                                    <span>-1.02</span>
                                    <span style={{ display: 'block', fontSize: '12px' }}>-.03</span>
                                </HighLightedField>
                                <span>The green container represents that the predicted score from the alorithm has this team covering the Vegas line.</span>
                            </KeyItemContainer>
                            <KeyItemContainer>
                                <HighLightedField variant="danger">
                                    <span>+1.02</span>
                                    <span style={{ display: 'block', fontSize: '12px' }}>+.03</span>
                                </HighLightedField>
                                <span>
                                    The red container represents that the predicted score from the alorithm does not have this team covering the Vegas line.
                                </span>
                            </KeyItemContainer>
                        </KeyContainer>
                    </TooltipContainer>
                );
            }}
            placement="auto"
            show={show}
        >
            <OverlayButton onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                <FontAwesomeIcon color={theme.colors.primaryDark} icon={faInfoCircle} />
            </OverlayButton>
        </OverlayTrigger>
    );
};

export default ToolTip;
