// Library imports
import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import { useTheme } from 'styled-components';
import { shape, string } from 'prop-types';

const LineChart = ({ data, symbol = '', height = '25rem' }) => {
    const theme = useTheme();

    return (
        <div style={{ height }}>
            <ResponsiveLine
                data={data}
                margin={{ top: 20, right: 20, bottom: 50, left: 70 }}
                xScale={{ type: 'point' }}
                yScale={{
                    type: 'linear',
                    min: '0',
                    max: 'auto',
                    stacked: true,
                    reverse: false,
                }}
                yFormat=" >-.2f"
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: -25,
                    legend: '',
                    legendOffset: 40,
                    legendPosition: 'middle',
                    truncateTickAt: 0,
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legendOffset: -40,
                    legendPosition: 'middle',
                    format: d => `${d}${symbol}`,
                }}
                pointSize={10}
                pointColor={{ from: 'color', modifiers: [['brighter', 0.3]] }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true} // Enable mouse tracking
                enableGridX={false} // Disable vertical grid lines
                enableGridY={true} // Enable horizontal grid lines
                colors={theme.colors.primary} // Use a color scheme
                tooltip={({ point }) => (
                    <div
                        style={{
                            padding: '5px 10px',
                            color: theme.colors.black,
                            background: theme.colors.white,
                            border: `1px solid ${theme.colors.primary}`,
                        }}
                    >
                        {point.data.y.toLocaleString()}
                        {symbol} on {point.data.x}
                    </div>
                )}
            />
        </div>
    );
};

LineChart.propTypes = {
    data: shape({}).isRequired,
    symbol: string,
    height: string,
};

export default LineChart;
