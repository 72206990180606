// Library imports
import React from 'react';
import { MDBBtn, MDBCard, MDBIcon, MDBListGroupItem } from 'mdb-react-ui-kit';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { bool, shape } from 'prop-types';
// Component imports
import TwoColumnView from '../../../../../components/TwoColumnView';
import NoData from '../../../../../components/NoData';
// Util imports
import { shortName } from '../../../../../utils';
import { ADVANCED_SEARCH_SCREEN_SIZE } from '../../../../../utils/constants';
import useScreenWidth from '../../../../../utils/hooks/useScreenWidth';
// Styled components
import { CardTitle, InnerContent } from './styles';
import { LabelValueListGroup } from '../../../styles';
// Action imports
import { setSubPage } from '../../../../../store/slices/appSlice';

const Situations = ({ game, demo = false }) => {
    // Redux
    const dispatch = useDispatch();
    // Hooks
    const history = useHistory();
    const windowWidth = useScreenWidth();

    if (!game.situations) {
        return <NoData title="No data" message="There are currently no situations for this game.  Check back soon." />;
    }

    const otherSituations = game.situations.filter(situation => situation.value && situation.type === 'other');

    const renderSituations = team => {
        const side = team.teamId === game.awayTeam.teamId ? 'away' : 'home';
        return (
            <MDBCard>
                <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                <LabelValueListGroup>
                    {team.recordData ? (
                        <MDBListGroupItem>
                            <label>{side === 'away' ? 'Away Record' : 'Home Record'}</label>
                            <span>
                                {shortName(team)} are <b>{side === 'away' ? team.recordData.awayRecord : team.recordData.homeRecord}</b>{' '}
                                {side === 'away' ? 'on the road' : 'at home'} this year.
                            </span>
                        </MDBListGroupItem>
                    ) : (
                        <></>
                    )}
                    {game.situations
                        .filter(situation => situation.type === 'team' && situation.teamId === team.teamId)
                        .map(situation => {
                            return (
                                <MDBListGroupItem key={situation.id}>
                                    <label>{situation.label}</label>
                                    <span>{situation.description}</span>
                                    {situation.queryString ? (
                                        <MDBBtn
                                            color="link"
                                            disabled={demo || windowWidth < ADVANCED_SEARCH_SCREEN_SIZE}
                                            onClick={() => {
                                                history.push({ pathname: location.pathname, search: situation.queryString });
                                                dispatch(setSubPage('trends'));
                                            }}
                                            style={{ padding: 0, marginTop: '5px', display: 'flex', gap: '5px', alignItems: 'center' }}
                                        >
                                            Query Trend
                                            <MDBIcon icon="angle-right" />
                                        </MDBBtn>
                                    ) : (
                                        <></>
                                    )}
                                </MDBListGroupItem>
                            );
                        })}
                </LabelValueListGroup>
            </MDBCard>
        );
    };

    return (
        <InnerContent fluid>
            <TwoColumnView columnOneJSX={renderSituations(game.awayTeam)} columnTwoJSX={renderSituations(game.homeTeam)} />
            <MDBCard className="mt-2">
                <CardTitle>Other Situations</CardTitle>
                <LabelValueListGroup>
                    {!otherSituations.length ? (
                        <NoData title="No data" message="There is currently other situation data for this matchup." />
                    ) : (
                        otherSituations.map(situation => {
                            return (
                                <MDBListGroupItem key={situation.id}>
                                    <label>{situation.label}</label>
                                    <span>{situation.description}</span>
                                </MDBListGroupItem>
                            );
                        })
                    )}
                </LabelValueListGroup>
            </MDBCard>
        </InnerContent>
    );
};

Situations.propTypes = {
    game: shape({}).isRequired,
    demo: bool,
};

export default Situations;
