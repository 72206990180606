// Library imports
import React, { useState } from 'react';
import { MDBCard, MDBCol, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
import { func, shape } from 'prop-types';
// Component imports
import TabNav from '../../../../../../components/TabNav';
// Util imports
import { shortName, addPlusSign, formatOddButtonJuice, capitalize } from '../../../../../../utils';
// Styled components
import { CardTitle } from '../styles';
import { OddButton } from './styles';
import { LabelValueListGroup } from '../../../../styles';

const OddsBoard = ({ game, setView, pickData, setPickData }) => {
    const [oddFilter, setOddFilter] = useState('fullGame');

    const renderOdds = () => {
        if (oddFilter === 'fullGame' || oddFilter === 'half' || oddFilter === 'quarter') {
            return [game.awayTeam, game.homeTeam].map((team, i) => {
                const oddTypes = oddFilter === 'fullGame' ? ['moneyline', 'spread'] : ['moneyline', 'spread', 'total'];
                return (
                    <MDBCol size={6} key={i}>
                        <MDBCard>
                            <CardTitle color={team.color}>{shortName(team)}</CardTitle>
                            <LabelValueListGroup direction="row">
                                {oddTypes.map((type, j) => {
                                    const oddDesc = oddFilter !== 'fullGame' ? ` (${oddFilter})` : '';
                                    const odds = team.odds?.[oddFilter]?.[type] ? team.odds[oddFilter][type] : 0;
                                    return (
                                        <MDBListGroupItem key={j}>
                                            <label>{type === 'total' ? 'Total' : shortName(team)}</label>
                                            <OddButton
                                                size="sm"
                                                onClick={() => {
                                                    setPickData({
                                                        ...pickData,
                                                        // Fullgame totals are not done here - they are done in the totals section
                                                        teamName:
                                                            type === 'total'
                                                                ? `${game.awayTeam.abbr}-${game.homeTeam.abbr}${oddDesc}`
                                                                : `${team.teamName}${oddDesc}`,
                                                        pickTeamId: team.teamId,
                                                        pickValue: odds,
                                                        // Fullgame totals are not done here - they are done in the totals section
                                                        pickType: type === 'total' ? (i === 0 ? 'under' : 'over') : type,
                                                        pickJuice: type === 'moneyline' ? odds : -110,
                                                    });
                                                    setView('confirm');
                                                }}
                                            >
                                                <span className="value">{type === 'total' ? (i === 0 ? `u${odds}` : `o${odds}`) : addPlusSign(odds)}</span>
                                                <span className="juice">{formatOddButtonJuice(-110, type)}</span>
                                            </OddButton>
                                        </MDBListGroupItem>
                                    );
                                })}
                            </LabelValueListGroup>
                        </MDBCard>
                    </MDBCol>
                );
            });
        }
        if (oddFilter === 'total') {
            return (
                <MDBCol size={6}>
                    <MDBCard>
                        <CardTitle>Totals</CardTitle>
                        <LabelValueListGroup direction="row">
                            {['under', 'over'].map((type, i) => {
                                return (
                                    <MDBListGroupItem>
                                        <label>Total ({capitalize(type)})</label>
                                        <OddButton
                                            id={type}
                                            key={i}
                                            onClick={e => {
                                                setPickData({
                                                    ...pickData,
                                                    teamName: `${game.awayTeam.abbr}-${game.homeTeam.abbr}`,
                                                    pickTeamId: `${game.awayTeam.teamId}-${game.homeTeam.teamId}`,
                                                    pickValue: game.total,
                                                    pickType: e.currentTarget.id,
                                                    pickJuice: -110,
                                                });
                                                setView('confirm');
                                            }}
                                        >
                                            <span className="value">
                                                {type === 'under' ? 'u' : 'o'}
                                                {game.total}
                                            </span>
                                            <span className="juice">{formatOddButtonJuice(-110, 'total')}</span>
                                        </OddButton>
                                    </MDBListGroupItem>
                                );
                            })}
                        </LabelValueListGroup>
                    </MDBCard>
                </MDBCol>
            );
        }
        return <></>;
    };

    return (
        <div>
            <TabNav
                containerStyle={{ flexWrap: 'nowrap', marginBottom: '1rem', overflow: 'auto' }}
                tabs={['fullGame', 'quarter', 'half', 'total']}
                activeTab={oddFilter}
                setTab={setOddFilter}
            />
            <MDBRow className="gx-1">{renderOdds()}</MDBRow>
        </div>
    );
};

OddsBoard.propTypes = {
    game: shape({}).isRequired,
    setView: func.isRequired,
    pickData: shape({}).isRequired,
    setPickData: func.isRequired,
};

export default OddsBoard;
