// Library imports
import React from 'react';
import { element, shape, string } from 'prop-types';
// Styled components
import { FeatureSectionContainer, SectionText, SectionTitle, TitleContainer } from './styles';

const FeatureSection = ({ children, bgColor = '#f5f7fb', title = '', titleStyle = {}, subTitle, subTitleStyle = {} }) => {
    return (
        <FeatureSectionContainer fluid bgColor={bgColor}>
            <TitleContainer>
                <SectionTitle style={{ ...titleStyle }}>{title}</SectionTitle>
                <SectionText style={{ ...subTitleStyle }}>{subTitle}</SectionText>
            </TitleContainer>
            {children}
        </FeatureSectionContainer>
    );
};

FeatureSection.protoTypes = {
    children: element,
    bgColor: string,
    title: string,
    titleStyle: shape({}),
    subTitle: string,
    subTitleStyle: shape({}),
};

export default FeatureSection;
