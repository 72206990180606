// Util imports
import { ACTIVE_SPORTS } from '../../utils/constants';

export const STATIC_LINKS = [
    {
        id: 'home',
        icon: 'fa-solid fa-home', // More suitable for Home
        label: 'Home',
        disabled: false,
    },
    {
        id: 'about',
        icon: 'fa-solid fa-users', // More fitting for About
        label: 'About',
        disabled: false,
    },
    {
        id: 'packages',
        icon: 'fa-solid fa-gift', // Suitable for Packages, as it represents gifts/packages
        label: 'Packages',
        disabled: false,
    },
    {
        id: 'login',
        icon: 'fa-solid fa-sign-in', // More fitting for login action
        label: 'Log in',
        disabled: false,
    },
];

export const APP_LINKS = [
    {
        id: 'mlb',
        icon: 'fa-solid fa-baseball-ball',
        label: 'MLB',
        disabled: !ACTIVE_SPORTS.includes('mlb'),
    },
    {
        id: 'nfl',
        icon: 'fa-solid fa-football-ball',
        label: 'NFL',
        disabled: !ACTIVE_SPORTS.includes('nfl'),
    },
    {
        id: 'cfb',
        icon: 'fa-solid fa-football-ball',
        label: 'CFB',
        disabled: !ACTIVE_SPORTS.includes('cfb'),
    },
    {
        id: 'nhl',
        icon: 'fa-solid fa-hockey-puck',
        label: 'NHL',
        disabled: !ACTIVE_SPORTS.includes('nhl'),
    },
    {
        id: 'nba',
        icon: 'fa-solid fa-basketball-ball',
        label: 'NBA',
        disabled: !ACTIVE_SPORTS.includes('nba'),
    },
    {
        id: 'cbb',
        icon: 'fa-solid fa-basketball-ball',
        label: 'CBB',
        disabled: !ACTIVE_SPORTS.includes('cbb'),
    },
    {
        id: 'profile',
        icon: 'fa-solid fa-user',
        label: '',
        disabled: false,
    },
];
