// Library imports
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { MDBCheckbox, MDBCol, MDBRow } from 'mdb-react-ui-kit';
import moment from 'moment';
// Component imports
import StripePaymentForm from '../../../components/PaymentForm';
import Alert from '../../../components/Alert';
// Controller imports
import { saveReciept, createPaymentIntent, subscription } from '../../../controllers/PaymentController';
import { register, deleteProfile } from '../../../controllers/UserController';
// Action imports
import { setStage } from '../../../store/slices/registerSlice';
// Styled components
import { StyledDetails, DetailRow, Label, Value, AutoPayWrapper } from './styles';

const stripeTestPromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const Pay = ({ setError }) => {
    // Redux
    const { data } = useSelector(state => state.register);
    const dispatch = useDispatch();
    // Local state
    const [clientSecret, setClientSecret] = useState('');
    const [autoPay, setAutoPay] = useState(true);

    const calculatePromo = () => {
        const promoDiscounts = {
            playoffs: data.plan.price / 2,
            yearly75: 75,
            yearly99: 99,
            restofyear: 50,
        };
        return promoDiscounts[String(data.promo).toLowerCase()] || data.plan.price;
    };

    const createPayment = async () => {
        try {
            const { client_secret } = await createPaymentIntent({
                product: { ...data.plan, price: calculatePromo() },
                customer: data,
            });
            setClientSecret(client_secret);
        } catch (error) {
            console.error(`Error creating intent: ${error}`);
            setError(error);
        }
    };

    useEffect(() => {
        createPayment();
    }, []);

    return (
        <MDBRow>
            <MDBCol sm={12} md={6}>
                <StyledDetails>
                    {data.plan?.type === 'account' && (
                        <DetailRow>
                            <Label>Display Name:</Label>
                            <Value>{data.displayName}</Value>
                        </DetailRow>
                    )}
                    <DetailRow>
                        <Label>Email:</Label>
                        <Value>{data.email}</Value>
                    </DetailRow>
                    <DetailRow>
                        <Label>Package:</Label>
                        <Value>{data.plan.name}</Value>
                    </DetailRow>
                    <DetailRow>
                        <Label>Price:</Label>
                        <Value>${Number(calculatePromo()).toFixed(2)}</Value>
                    </DetailRow>
                </StyledDetails>
            </MDBCol>
            <MDBCol sm={12} md={6}>
                {data.plan?.type === 'account' && (
                    <AutoPayWrapper className="mt-3 mb-3 mt-md-0">
                        <Label>AutoPay:</Label>
                        <MDBCheckbox
                            name="checkNoLabel"
                            id="checkboxNoLabel"
                            onChange={() => setAutoPay(!autoPay)}
                            checked={autoPay}
                            disabled={data.plan.id === 'daily-access'}
                        />
                    </AutoPayWrapper>
                )}
                {clientSecret && (
                    <Elements stripe={stripeTestPromise} options={{ clientSecret }}>
                        <StripePaymentForm
                            autoPay={autoPay}
                            handleSubmit={async id => {
                                let newUser = null;
                                try {
                                    // 1 - Create account
                                    if (data.plan?.type === 'account') {
                                        newUser = await register(data.email, data.password, data.displayName, data.plan.type);
                                    }
                                    // 2 - Create subscription
                                    if (autoPay && id && data.plan?.type === 'account') {
                                        await subscription({
                                            paymentId: id,
                                            product: {
                                                ...data.plan,
                                                price: calculatePromo(),
                                            },
                                            email: data.email,
                                        });
                                    }
                                    // 3 - Save reciept
                                    await saveReciept({
                                        items: [{ ...data.plan, startDate: moment().toISOString() }],
                                        email: data.email,
                                        type: data.plan.type,
                                        firstName: data.firstName,
                                        lastName: data.lastName,
                                        twitter: data.twitter,
                                        discord: data.discord,
                                    });
                                    setError('');
                                    dispatch(setStage('access'));
                                } catch (error) {
                                    setError(error.message);
                                    deleteProfile(newUser?.uid, newUser?.email?.toLowerCase());
                                    await createPayment();
                                }
                            }}
                            setError={setError}
                        />
                    </Elements>
                )}
                <Alert
                    message="By clicking purchase, you agree to Line Prophet LLC's privacy policy and terms and conditions."
                    variant="light"
                    style={{ textAlign: 'center' }}
                />
            </MDBCol>
        </MDBRow>
    );
};

export default Pay;
