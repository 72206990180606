// Library imports
import React from 'react';
import { MDBCol, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { arrayOf, func, shape, string } from 'prop-types';
// Component imports
import Dropdown from '../DropDown';
// Util imports
import { capitalize } from '../../utils';
import { All_FILTERS } from '../../utils/constants';
// Action imports
import { setFilters } from '../../store/slices/appSlice';
// Styled components
import { SearchFilterContainer, SearchFilterRow, SearchWrapper } from './styles';

const GameSearchFilter = ({ gameData, setActiveDate, activeDate = '', value = '', setValue }) => {
    const theme = useTheme();
    // Redux
    const dispatch = useDispatch();
    const { filters } = useSelector(state => state.app);

    return (
        <SearchFilterContainer fluid>
            <SearchFilterRow className="g-0">
                <MDBCol md={12} lg={2} className="first-col">
                    <Dropdown
                        icon={<MDBIcon icon="calendar" />}
                        caption="Game Date"
                        toggleStyle={{
                            background: theme.colors.white,
                            borderRadius: 0,
                            borderTopLeftRadius: 999,
                            borderBottomLeftRadius: 999,
                        }}
                        value={moment(activeDate).isValid() ? moment(activeDate).format('MM/DD/YYYY') : '---'}
                        setValue={selectedKey => setActiveDate(selectedKey)}
                        menuItems={[...new Set(gameData.map(item => moment(item.dateTime).format('MM/DD/YYYY')))].sort((a, b) => {
                            if (moment(a).isAfter(b)) return 1;
                            if (!moment(a).isBefore(b)) return -1;
                            if (moment(a).isSame(b)) return 1;
                        })}
                    />
                </MDBCol>
                <MDBCol md={12} lg={6} className="search-col">
                    <SearchWrapper>
                        <MDBInput
                            type="search"
                            placeholder="Start typing team name..."
                            aria-label="Search"
                            id="card-search"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />
                    </SearchWrapper>
                </MDBCol>
                <MDBCol md={12} lg={4} className="last-col">
                    <Dropdown
                        icon={<MDBIcon icon="filter" />}
                        caption="Filter Games"
                        toggleStyle={{
                            background: theme.colors.white,
                            borderRadius: 0,
                            borderTopRightRadius: 999,
                            borderBottomRightRadius: 999,
                        }}
                        value={
                            All_FILTERS.find(filter => filter.id === filters[0])
                                ? All_FILTERS.find(filter => filter.id === filters[0]).label
                                : capitalize(filters[0])
                        }
                        setValue={selectedKey =>
                            dispatch(
                                setFilters(
                                    All_FILTERS.find(filter => filter.label === selectedKey)
                                        ? All_FILTERS.find(filter => filter.label === selectedKey).id
                                        : selectedKey,
                                ),
                            )
                        }
                        menuItems={['Upcoming', 'Live', ...All_FILTERS].map(filter => {
                            return filter.id ? filter.label : filter;
                        })}
                    />
                </MDBCol>
            </SearchFilterRow>
        </SearchFilterContainer>
    );
};

GameSearchFilter.propTypes = {
    gameData: arrayOf(shape({})).isRequired,
    setActiveDate: func.isRequired,
    activeDate: string,
    value: string,
    setValue: func.isRequired,
};

export default GameSearchFilter;
