// Library and Component imports
import React, { useState } from 'react';
import { MDBBtn, MDBCardBody, MDBListGroupItem } from 'mdb-react-ui-kit';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
// Controller imports
import { updateSubscription } from '../../../../controllers/PaymentController';
// Util imports
import { PACKAGES } from '../../../../utils/constants';
// Action imports
import { fetchCustomer } from '../../../../store/slices/userSlice';
import { SubscriptionCard, ButtonContainer, StatusText } from './styles';
import { CardTitle } from '../../../App/GameCards/GameCard/windows/styles';
import { LabelValueListGroup } from '../../../App/styles';

const Subscription = ({ subscription }) => {
    // Redux
    const { user } = useSelector(state => state.user);
    const dispatch = useDispatch();
    // Local state
    const [confirmCancel, setConfirmCancel] = useState(false);
    // Local variables
    const packageData = PACKAGES[Object.keys(PACKAGES).find(key => PACKAGES[key].planId === subscription.plan.id)];

    const handleRenewSubscription = async () => {
        try {
            await updateSubscription({}, subscription.id, subscription.items.data[0].id, user.reciept.endDate, false, true);
            dispatch(fetchCustomer());
            setConfirmCancel(false);
        } catch (error) {
            console.error(`Error renewing subscription.\n${error}`);
        }
    };

    const handleCancelAutoPay = async () => {
        try {
            await updateSubscription({}, subscription.id, subscription.items.data[0].id, user.reciept.endDate, true, false);
            dispatch(fetchCustomer());
            setConfirmCancel(false);
        } catch (error) {
            console.error(`Error canceling autopay.\n${error}`);
        }
    };

    return (
        <SubscriptionCard>
            <CardTitle style={{ fontWeight: '700' }}>{packageData?.name || 'Package'}</CardTitle>
            <StatusText active={!subscription.cancel_at_period_end}>{subscription.cancel_at_period_end ? 'Canceled' : 'Active'}</StatusText>
            <MDBCardBody className="p-3">
                <LabelValueListGroup>
                    <MDBListGroupItem noBorders style={{ padding: 0 }}>
                        <label>{subscription.cancel_at_period_end ? 'Cancellation date' : 'Next payment'}</label>
                        <span>{moment.unix(subscription.current_period_end).format('MMM Do YYYY, h:mm A')}</span>
                    </MDBListGroupItem>
                </LabelValueListGroup>
                <ButtonContainer>
                    {subscription.cancel_at_period_end ? (
                        <MDBBtn className="mr-2" color="success" onClick={handleRenewSubscription}>
                            Renew
                        </MDBBtn>
                    ) : confirmCancel ? (
                        <>
                            <MDBBtn className="mr-2" color="secondary" onClick={() => setConfirmCancel(false)}>
                                Back
                            </MDBBtn>
                            <MDBBtn className="mr-2" color="danger" onClick={handleCancelAutoPay}>
                                Confirm Cancel
                            </MDBBtn>
                        </>
                    ) : (
                        <MDBBtn className="mr-2" color="danger" onClick={() => setConfirmCancel(true)}>
                            Cancel Subscription
                        </MDBBtn>
                    )}
                </ButtonContainer>
            </MDBCardBody>
        </SubscriptionCard>
    );
};

export default Subscription;
