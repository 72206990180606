// Library imports
import styled from 'styled-components';

export const HeadlineTitle = styled.a`
    display: block;
    text-decoration: none;
    font-size: 14px;
    color: #1a0dab;
    line-height: 1.57;
`;

export const HeadlineLink = styled.span`
    margin-bottom: 5px;
    font-size: 12px;
    overflow: visible;
    color: #006621 !important;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    display: block;
`;

export const HeadlineDescription = styled.span`
    font-size: 0.75rem;
    color: #666;
`;
