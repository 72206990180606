// Component imports
import React, { useEffect, useState } from 'react';
import { MDBCollapse, MDBContainer, MDBIcon, MDBNavbarItem, MDBNavbarLink, MDBNavbarNav, MDBNavbarToggler } from 'mdb-react-ui-kit';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import Tour from 'reactour';
import { useParams, useHistory } from 'react-router-dom';
// Component imports
import GameCards from './GameCards';
import Trends from './Trends';
import Algorithm from './Algorithm';
import SearchBuilder from './SearchBuilder';
import Props from './Props';
import LayoutWithHeader from '../../components/Layouts/LayoutWithHeader';
import Sidebar from '../Sidebar';
import LoadingSpinner from '../../components/LoadingSpinner';
import UpgradeTooltip from '../../components/UpgradeTooltip';
// Controllers imports
import { updateProfile } from '../../controllers/UserController';
// Action imports
import { fetchUser, setUser } from '../../store/slices/userSlice';
import { fetchTeams, fetchGames, setSport, setFilters, setSubPage } from '../../store/slices/appSlice';
import { resetData } from '../../store/slices/queryBuilderSlice';
// Util imports
import { capitalize, scrollToTop } from '../../utils';
import useScreenWidth from '../../utils/hooks/useScreenWidth';
import { ADVANCED_SEARCH_SCREEN_SIZE, TOUR_CONFIGS } from '../../utils/constants';
// Styled components
import { ToolsNavbar, TourButton } from './styles';
// Constant imports
import { TOOL_PAGES } from './constants';

const App = () => {
    // Routing
    const history = useHistory();
    const { sport: urlSport } = useParams();
    // Redux
    const dispatch = useDispatch();
    const { sport, subPage, openDrawer } = useSelector(state => state.app);
    const { user, userLoading } = useSelector(state => state.user);
    // Local state
    const [isTourOpen, setIsTourOpen] = useState(false);
    const [navbarOpen, setNavbarOpen] = useState(false);
    // Hooks
    const windowWidth = useScreenWidth();

    // Sync the sport in the URL with the Redux state, and fetch data when they match
    useEffect(() => {
        if (urlSport !== sport) {
            dispatch(setSport(urlSport));
        } else {
            // We must set the subpage to games because we are relying on setSport to do that, if returning to
            // the same sport as before from profile setSport is not called.
            dispatch(setSubPage('games'));
            dispatch(setFilters('Upcoming'));
            dispatch(fetchGames());
            dispatch(fetchTeams());
        }
    }, [sport, urlSport]);

    // Fetch user data or handle user subscription validation and tour state initialization
    useEffect(() => {
        if (!user.email) {
            dispatch(fetchUser());
        } else if (user.expired) {
            dispatch(setSubPage('settings'));
            history.push('/profile');
        } else {
            setIsTourOpen(user.tour);
        }
    }, [user]);

    // Handle subPage changes: scroll to top, update URL, and reset data if necessary
    useEffect(() => {
        scrollToTop();
        if (subPage !== 'search-builder') {
            dispatch(resetData());
        }
    }, [subPage]);

    return (
        <LayoutWithHeader page={sport} showBanner={true} shadow={false}>
            <Helmet>
                <title>{String(sport).toUpperCase()} | Line Prophet</title>
                <meta
                    name="description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content={`${String(sport).toUpperCase()} | Line Prophet`} />
                <meta
                    property="og:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta property="og:url" content="https://www.lineprophet.com/" />
                <meta property="og:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${String(sport).toUpperCase()} | Line Prophet`} />
                <meta
                    name="twitter:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="twitter:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />
                <meta name="twitter:site" content="@LineProphetLLC" />
                <meta name="twitter:creator" content="@LineProphetLLC" />

                {/* Favicon */}
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <ToolsNavbar expand="lg" drawerOpen={user.reciept?.type === 'free-account' || openDrawer || isTourOpen}>
                <MDBContainer fluid>
                    <MDBNavbarToggler type="button" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setNavbarOpen(!navbarOpen)}>
                        <MDBIcon icon="bars" fas />{' '}
                        <span style={{ fontWeight: '700', marginLeft: '0.25rem' }}>
                            {sport.toUpperCase()} {capitalize(subPage)}
                        </span>
                    </MDBNavbarToggler>
                    <MDBCollapse className="justify-content-center w-100" navbar open={navbarOpen}>
                        <MDBNavbarNav>
                            {TOOL_PAGES.map((page, index) => {
                                if (!page.free && user.reciept?.type === 'free-account') {
                                    return (
                                        <UpgradeTooltip
                                            key={index}
                                            children={
                                                <MDBNavbarItem>
                                                    <MDBNavbarLink>
                                                        <MDBIcon icon={page.icon} /> {page.title}
                                                    </MDBNavbarLink>
                                                </MDBNavbarItem>
                                            }
                                        />
                                    );
                                }
                                return (
                                    <MDBNavbarItem key={index}>
                                        <MDBNavbarLink
                                            onClick={() => {
                                                setNavbarOpen(false);
                                                dispatch(setSubPage(page.id));
                                            }}
                                            active={subPage === page.id}
                                            disabled={page.fullScreen && windowWidth < ADVANCED_SEARCH_SCREEN_SIZE}
                                        >
                                            <MDBIcon icon={page.icon} /> {page.title}
                                        </MDBNavbarLink>
                                    </MDBNavbarItem>
                                );
                            })}
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
                {TOUR_CONFIGS[subPage] ? (
                    <TourButton color="link" onClick={() => setIsTourOpen(true)} variant="link" id="tour-button">
                        <MDBIcon size="2x" icon="question-circle" fas />
                    </TourButton>
                ) : (
                    <></>
                )}
            </ToolsNavbar>
            {userLoading ? (
                <LoadingSpinner />
            ) : subPage === 'games' ? (
                <GameCards />
            ) : subPage === 'props' ? (
                <Props />
            ) : subPage === 'trends' ? (
                <Trends />
            ) : subPage === 'algorithm' ? (
                <Algorithm />
            ) : subPage === 'search-builder' ? (
                <SearchBuilder />
            ) : (
                <></>
            )}
            <Sidebar tabs={['vip', 'standings']} />
            <Tour
                onRequestClose={async () => {
                    setIsTourOpen(false);
                    const user = await updateProfile({ tour: false });
                    dispatch(setUser(user));
                }}
                steps={TOUR_CONFIGS[subPage]}
                isOpen={isTourOpen}
                maskClassName="mask"
                className="helper"
                rounded={5}
                accentColor={'#5cb7b7'}
                startAt={0}
            />
        </LayoutWithHeader>
    );
};

export default App;
