// Libraries imports
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { MDBCard, MDBCardBody, MDBCardHeader, MDBCol, MDBIcon, MDBListGroup, MDBListGroupItem, MDBRow } from 'mdb-react-ui-kit';
// Component imports
import SportsNav from '../../../../../components/SportsNav';
import Insights from './Insights';
import LoadingSpinner from '../../../../../components/LoadingSpinner';
import PillNav from '../../../../../components/PillNav';
import TabNav from '../../../../../components/TabNav';
import Alert from '../../../../../components/Alert';
// Controller imports
import { pickRecord } from '../../../../../controllers/UserController';
// Util imports
import { formatPickJuice, formatPickValue, sortPicks } from '../../../../../utils';
import { FUTURES } from '../../../../../utils/constants';
// CSS imports
import 'react-calendar/dist/Calendar.css';
import '../../../../../css/Calendar.css';
// Styled Components
import { LabelValueListGroup } from '../../../../App/styles';
import { DrawerContent } from '../../styles';

const VIPDrawer = () => {
    // Local state
    const [data, setData] = useState({});
    const [loadingWeekly, setLoadingWeekly] = useState(false);
    const [loadingTimeFrame, setLoadingTimeFrame] = useState(true);
    const [vipTimeframe, setVIPTimeFrame] = useState('week');
    const [sport, setSport] = useState('all');
    const [tab, setTab] = useState('weekly');
    const [upcomingTab, setUpcomingTab] = useState('Main Card');

    useEffect(() => {
        async function fetchVIPData() {
            try {
                const timeFrameRes = await pickRecord(vipTimeframe, true);
                setData(timeFrameRes);
            } catch (error) {
                console.log(`Error getting timeframe picks VIP stats.\n${error.message}`);
            } finally {
                setLoadingTimeFrame(false);
                setLoadingWeekly(false);
            }
        }
        fetchVIPData();
    }, [vipTimeframe]);

    return (
        <DrawerContent fluid>
            <TabNav tabs={['weekly', 'insights']} activeTab={tab} setActiveTab={setTab} containerStyle={{ marginBottom: '1rem' }} />
            {loadingTimeFrame ? (
                <LoadingSpinner />
            ) : tab === 'weekly' && data.picks ? (
                <MDBRow className="g-2">
                    <MDBCol sm={12} md={6}>
                        <MDBCard>
                            <MDBCardHeader>Pending Plays</MDBCardHeader>
                            <MDBCardBody>
                                <PillNav tabs={['Main Card', 'Futures']} activeTab={upcomingTab} setActiveTab={setUpcomingTab} />
                                <Alert message="All units represent the risk amount" variant="info" />
                                <MDBListGroup>
                                    {sortPicks(upcomingTab === 'Futures' ? FUTURES : data.picks, 'asc')
                                        .filter(pick => (upcomingTab === 'Futures' ? true : pick.result === 'pending'))
                                        .map(pick => {
                                            return (
                                                <MDBListGroupItem key={pick.pickId}>
                                                    <MDBRow>
                                                        <MDBCol size={3}>
                                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                                <span style={{ fontSize: '12px', fontWeight: 400, color: '#666' }}>
                                                                    {moment(pick.gameTime).format('MM/DD h:mm A')}
                                                                </span>
                                                                <span style={{ fontSize: '14px', fontWeight: 'bold', color: '#444' }}>{pick.teamName}</span>
                                                                <span style={{ fontSize: '12px', fontWeight: 400, color: '#666' }}>({pick.sport})</span>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol size={6}>
                                                            <span className="value">{formatPickValue(pick)}</span>
                                                            <span className="juice">{formatPickJuice(pick)}</span>
                                                        </MDBCol>
                                                        <MDBCol size={3}>
                                                            <span className="value">{`${Number(pick.units).toFixed(1)}u`}</span>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBListGroupItem>
                                            );
                                        })}
                                </MDBListGroup>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                    <MDBCol sm={12} md={6}>
                        <MDBCard>
                            <MDBCardHeader>Quick Insights</MDBCardHeader>
                            <MDBCardBody>
                                <TabNav
                                    tabs={['week', 'month', 'season', 'all']}
                                    activeTab={vipTimeframe}
                                    setActiveTab={setVIPTimeFrame}
                                    containerStyle={{ marginBottom: '1rem' }}
                                />
                                <SportsNav click={setSport} activeItem={sport} />
                                {loadingWeekly ? (
                                    <LoadingSpinner />
                                ) : (
                                    <LabelValueListGroup>
                                        <MDBListGroupItem>
                                            <label>Record:</label>
                                            <span>{`${data.record[sport].wins}-${data.record[sport].losses}`}</span>
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <label>Units:</label>
                                            <span>{`${Number(data.record[sport].units).toFixed(2)}u`}</span>
                                            {data.record[sport].units >= 0 ? <MDBIcon icon="arrow-up" /> : <MDBIcon icon="arrow-down" />}
                                        </MDBListGroupItem>
                                        <MDBListGroupItem>
                                            <label>ROI:</label>
                                            <span>{`${Number(data.record[sport].roi).toFixed(2)}%`}</span>
                                        </MDBListGroupItem>
                                    </LabelValueListGroup>
                                )}
                            </MDBCardBody>
                        </MDBCard>
                        <MDBCard>
                            <MDBCardHeader>Weekly Results</MDBCardHeader>
                            <MDBCardBody>
                                <Alert message="All units represent the risk amount" variant="info" />
                                <MDBListGroup>
                                    {sortPicks(data.picks)
                                        .filter(pick => {
                                            if (pick.result !== 'pending') {
                                                var curr = new Date();
                                                curr.setHours(0, 0, 0, 0);
                                                const startOfWeek = new Date(curr.setDate(curr.getDate() - curr.getDay()));
                                                return moment(pick.gameTime).isSameOrAfter(startOfWeek);
                                            } else {
                                                return false;
                                            }
                                        })
                                        .map((pick, i) => {
                                            return (
                                                <MDBListGroupItem key={i}>
                                                    <MDBRow>
                                                        <MDBCol size={6}>
                                                            <div>
                                                                <span>{moment(pick.gameTime).format('MM/DD h:mm A')}</span>
                                                                <span>{pick.teamName}</span>
                                                                <span>({pick.sport})</span>
                                                            </div>
                                                        </MDBCol>
                                                        <MDBCol size={6}>
                                                            <span className="value">{formatPickValue(pick)}</span>
                                                            <span className="juice">{formatPickJuice(pick)}</span>
                                                        </MDBCol>
                                                        <MDBCol size={6}>
                                                            <span className="value">{`${Number(pick.units).toFixed(1)}u`}</span>
                                                        </MDBCol>
                                                    </MDBRow>
                                                </MDBListGroupItem>
                                            );
                                        })}
                                </MDBListGroup>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            ) : (
                <Insights />
            )}
        </DrawerContent>
    );
};

export default VIPDrawer;
