// Library imports
import React, { useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import { MDBBtn } from 'mdb-react-ui-kit';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { node, shape, string } from 'prop-types';
// Styled components
import { UpgradeTooltipContainer } from './styles';
// Action imports
import { setSubPage } from '../../store/slices/appSlice';

const UpgradeTooltip = ({ children, innerChildStyle = {}, label = 'You must be a VIP member to view this data.' }) => {
    // Routing
    const history = useHistory();
    //Redux
    const dispatch = useDispatch();
    // Local state
    const [show, setShow] = useState(false);

    return (
        <OverlayTrigger
            trigger={['hover', 'focus', 'click']}
            overlay={props => {
                return (
                    <UpgradeTooltipContainer id="button-tooltip-2" {...props} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                        <span style={{ display: 'block' }}>{label}</span>
                        <MDBBtn
                            color="success"
                            size="sm"
                            style={{ marginTop: '5px' }}
                            onClick={() => {
                                dispatch(setSubPage('settings'));
                                history.push('/profile');
                            }}
                        >
                            Upgrade Access
                        </MDBBtn>
                    </UpgradeTooltipContainer>
                );
            }}
            placement="auto"
            show={show}
        >
            <div style={{ position: 'relative', ...innerChildStyle }} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
                {children}
            </div>
        </OverlayTrigger>
    );
};

UpgradeTooltip.propTypes = {
    children: node,
    innerChildStyle: shape({}),
    label: string,
};

export default UpgradeTooltip;
