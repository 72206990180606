// Library imports
import styled from 'styled-components';
import { MDBBtn, MDBCard, MDBCardHeader, MDBCardText, MDBCardTitle, MDBListGroupItem } from 'mdb-react-ui-kit';

export const PackageCard = styled(MDBCard)`
    border-radius: 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    background-color: ${({ theme }) => theme.colors.white};
    border-width: 1px;
    cursor: pointer; // Changes cursor to pointer on hover
    &:hover {
        transform: translateY(-5px); // Slight lift effect on hover
        box-shadow: 0 12px 25px rgba(0, 0, 0, 0.4); // Stronger shadow on hover
    }
`;

export const CardHeader = styled(MDBCardHeader)`
    padding: 20px;
    text-align: center;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
`;

export const CardTitle = styled(MDBCardTitle)`
    font-family: ${({ theme }) => theme.fonts.heading};
    color: ${({ theme }) => theme.colors.black};
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 5px;
`;

export const PriceText = styled(MDBCardText)`
    font-family: ${({ theme }) => theme.fonts.body};
    color: ${({ theme }) => theme.colors.primaryDark};
    font-size: 1.6rem;
    font-weight: bold;
    margin-bottom: 15px;
`;

export const PurchaseButton = styled(MDBBtn)`
    background: linear-gradient(135deg, ${({ theme }) => theme.colors.green} 0%, ${({ theme }) => theme.colors.green} 100%);
    border-radius: 8px;
    width: 100%;
    padding: 12px 0;
    font-size: 1.2rem;
    font-weight: bold;
    transition: all 0.3s ease;
    &:hover {
        background: linear-gradient(135deg, ${({ theme }) => theme.colors.green} 0%, ${({ theme }) => theme.colors.green} 100%);
        transform: scale(1.01);
    }
`;

export const ListGroupItem = styled(MDBListGroupItem)`
    background-color: ${({ theme }) => theme.colors.silver};
    border: none;
    margin-bottom: 10px;
    padding: 12px 20px;
    border-radius: 8px;
    &:last-child {
        margin-bottom: 0;
    }
`;

export const HowItWorksCard = styled(MDBCard)`
    background-color: #fff;
    height: 300px;
`;

export const HowItWorksTitle = styled(MDBCardTitle)`
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: 700;
    font-size: 1.5rem;
    margin-top: 1rem;
    color: ${({ theme }) => theme.colors.primaryDark};
    text-align: center;
`;

export const HowItWorksDescription = styled(MDBCardText)`
    font-family: ${({ theme }) => theme.fonts.body};
    font-weight: 500;
    font-size: 1.15rem;
    color: ${({ theme }) => theme.colors.black};
    text-align: center;
`;
