export const customTheme = {
    background: '#ffffff',
    textColor: '#333333',
    fontSize: 12,
    axis: {
        domain: {
            line: {
                stroke: '#cccccc',
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: '#cccccc',
                strokeWidth: 1,
            },
            text: {
                fill: '#333333',
            },
        },
    },
    grid: {
        line: {
            stroke: '#eeeeee',
        },
    },
    legends: {
        text: {
            fontSize: 12,
            fill: '#333333',
        },
    },
    tooltip: {
        container: {
            background: '#ffffff',
            color: '#333333',
            boxShadow: '0 3px 9px rgba(0, 0, 0, 0.5)',
            borderRadius: '5px',
            padding: '5px 10px',
        },
    },
};
