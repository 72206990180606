// Library imports
import React from 'react';
import { array, func, shape, string } from 'prop-types';
// Styled components
import { TabButton, TabContainer } from './styles';
// Util imports
import { capitalize } from '../../utils';

const PillNav = ({ tabs = [], activeTab = '', setActiveTab, buttonStyle = {}, containerStyle = {} }) => {
    return (
        <TabContainer style={{ ...containerStyle }}>
            {tabs.map((tab, i) => {
                return (
                    <TabButton
                        key={i}
                        onClick={() => setActiveTab(tab)}
                        active={String(String(tab).toLowerCase() === String(activeTab).toLowerCase())}
                        firstItem={i === 0}
                        lastItem={i === tabs.length - 1}
                        style={{ ...buttonStyle }}
                    >
                        {capitalize(tab)}
                    </TabButton>
                );
            })}
        </TabContainer>
    );
};

PillNav.propTypes = {
    tabs: array,
    activeTab: string,
    setActiveTab: func.isRequired,
    buttonStyle: shape({}),
    containerStyle: shape({}),
};

export default PillNav;
