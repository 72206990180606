// Library imports
import styled from 'styled-components';
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit';
// Util imports
import { LARGE_SCREEN_WIDTH } from '../../utils/constants';

export const SearchFilterContainer = styled(MDBContainer)`
    display: flex;
    justify-content: center;
    background-image: linear-gradient(180deg, #f1f1f1 0%, #f4f4f4 100%);
    padding: 1rem 0px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
    @media (max-width: ${LARGE_SCREEN_WIDTH}) {
        justify-content: flex-start;
    }
`;

export const SearchFilterRow = styled(MDBRow)`
    width: 80%;
    border: 1px solid ${({ theme }) => theme.colors.borderColor};
    border-radius: 999px;
    align-items: stretch;

    .first-col .dropdown {
        border-right: 1px solid ${({ theme }) => theme.colors.borderColor};
    }
    .last-col .dropdown {
        border-left: 1px solid ${({ theme }) => theme.colors.borderColor};
    }
    .dropdown {
        height: 100%;
    }

    @media (max-width: ${LARGE_SCREEN_WIDTH}) {
        padding-left: 2rem;
        flex-direction: column;
        .dropdown {
            border-left: 0px !important;
            border-right: 0px !important;
        }
        button {
            border-radius: 0px !important;
        }
        .search-col {
            order: 1;
        }
        .first-col {
            order: 2;
            margin-top: 1rem;
            margin-bottom: 0.25rem;
        }
        .last-col {
            order: 3;
        }
    }
`;

export const SearchWrapper = styled.div`
    background: ${({ theme }) => theme.colors.white};
    height: 100%;

    & .form-outline,
    & input,
    & .form-notch-trailing,
    & .form-notch-leading,
    & .form-notch-middle {
        height: 100%;
        border-radius: 0px !important;
        border: 0px !important;
        box-shadow: none !important;
    }

    & input::placeholder {
        opacity: 1 !important;
    }

    @media (max-width: ${LARGE_SCREEN_WIDTH}) {
        & input {
            padding: 1rem !important;
        }
    }
`;
