// Library imports
import React from 'react';
import { MDBBtn, MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { useTheme } from 'styled-components';
// Component imports
import FeatureSection from '../FeatureSection';
// Styled components
import { MediaContainer } from './styles';

const SocialMediaSection = () => {
    const theme = useTheme();

    return (
        <FeatureSection bgColor={theme.colors.silver} title="Connect with Us on Social Media" subTitle="Join our vibrant community on Twitter and Instagram!">
            <MDBRow>
                <MDBCol md="6" sm="12" className="mb-3 mb-md-0">
                    <MediaContainer>
                        <MDBIcon fab icon="twitter" size="5x" style={{ color: '#1DA1F2' }} />
                        <h4 style={{ marginTop: '20px', fontWeight: 'bold' }}>Twitter</h4>
                        <p style={{ margin: '10px 0', color: '#555' }}>Join our conversation and get the latest news.</p>
                        <MDBBtn onClick={() => window.open('https://twitter.com/LineProphetLLC', '_blank')} color="primary" size="md">
                            Follow Us
                        </MDBBtn>
                    </MediaContainer>
                </MDBCol>
                <MDBCol md="6" sm="12">
                    <MediaContainer>
                        <MDBIcon fab icon="instagram" size="5x" style={{ color: '#E4405F' }} />
                        <h4 style={{ marginTop: '20px', fontWeight: 'bold' }}>Instagram</h4>
                        <p style={{ margin: '10px 0', color: '#555' }}>Catch our latest stories and insights.</p>
                        <MDBBtn onClick={() => window.open('https://instagram.com/LineProphetLLC', '_blank')} color="danger" size="md">
                            Follow Us
                        </MDBBtn>
                    </MediaContainer>
                </MDBCol>
            </MDBRow>
        </FeatureSection>
    );
};

export default SocialMediaSection;
