// Library imports
import styled from 'styled-components';
import { MDBCard } from 'mdb-react-ui-kit';

export const ConferenceWrapper = styled.div`
    margin-bottom: 25px;
`;

export const DivisionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 15px;
`;

export const DivisionCard = styled(MDBCard)`
    flex: 1 1 calc(50% - 15px); /* Adjust width to account for gap */
    box-sizing: border-box;
    max-width: calc(50% - 15px);
    @media all and (max-width: 1064px) {
        min-width: 100%;
    }
`;

export const StandingsTitle = styled.span`
    font-family: ${({ theme }) => theme.fonts.heading};
    font-weight: 900 !important;
    font-size: 1.5rem !important;
    line-height: 1.5rem !important;
    display: block;
    margin-bottom: 15px;
    color: ${theme => theme.colors.black};
`;
