// Library imports
import styled from 'styled-components';
import { MDBBtn } from 'mdb-react-ui-kit';

export const OverUnderButtonGroup = styled.div`
    display: flex;
    flex-direction: row;
    button:first-child {
        margin-right: 5px;
    }
    @media (max-width: 1225px) {
        flex-direction: column;
        button:first-child {
            margin-right: 0;
            margin-bottom: 5px;
        }
    }
`;

export const OddButton = styled(MDBBtn)`
    box-shadow: none !important;
    display: flex !important;
    text-transform: none !important;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
    background-color: ${({ theme }) => theme.colors.silver} !important;
    border: 1px solid ${({ theme }) => theme.colors.borderColor} !important;
    color: ${({ theme }) => theme.colors.black} !important;
    border-radius: 10px;
    .juice {
        font-size: 12px !important;
    }
    .value {
        font-size: 14px !important;
        font-weight: 700 !important;
    }
`;

export const FormWrapper = styled.form`
    padding: 10px 15px;
`;
