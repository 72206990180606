// Library imports
import React from 'react';
import { MDBCol, MDBIcon, MDBInput } from 'mdb-react-ui-kit';
import { useTheme } from 'styled-components';
import { arrayOf, func, shape, string } from 'prop-types';
// Component imports
import Dropdown from '../DropDown';
// Styled components
import { SearchFilterContainer, SearchFilterRow, SearchWrapper } from './styles';

const AlgoSearchFilter = ({ value = '', setValue, filter = '', setFilter }) => {
    const theme = useTheme();

    return (
        <SearchFilterContainer fluid>
            <SearchFilterRow className="g-0">
                <MDBCol md={12} lg={2} className="first-col">
                    <Dropdown
                        icon={<MDBIcon icon="filter" />}
                        caption="Filter"
                        toggleStyle={{
                            background: theme.colors.white,
                            borderRadius: 0,
                            borderTopLeftRadius: 999,
                            borderBottomLeftRadius: 999,
                        }}
                        value={filter}
                        setValue={selectedKey => setFilter(selectedKey)}
                        menuItems={['All', 'No Score', 'No Predictions']}
                    />
                </MDBCol>
                <MDBCol md={12} lg={8} className="search-col">
                    <SearchWrapper>
                        <MDBInput
                            type="search"
                            placeholder="Start typing team name..."
                            aria-label="Search"
                            id="card-search"
                            value={value}
                            onChange={e => setValue(e.target.value)}
                        />
                    </SearchWrapper>
                </MDBCol>
                <MDBCol md={12} lg={2} className="last-col">
                    <Dropdown
                        icon={<MDBIcon icon="filter" />}
                        caption="Filter"
                        toggleStyle={{
                            background: theme.colors.white,
                            borderRadius: 0,
                            borderTopRightRadius: 999,
                            borderBottomRightRadius: 999,
                        }}
                        value={filter}
                        setValue={selectedKey => setFilter(selectedKey)}
                        menuItems={['All', 'No Score', 'No Predictions']}
                    />
                </MDBCol>
            </SearchFilterRow>
        </SearchFilterContainer>
    );
};

AlgoSearchFilter.propTypes = {
    gameData: arrayOf(shape({})).isRequired,
    value: string,
    setValue: func.isRequired,
    filter: string,
    setFilter: func.isRequired,
};

export default AlgoSearchFilter;
