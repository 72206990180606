// Library imports
import React from 'react';
import { MDBTabs, MDBTabsItem, MDBTabsLink } from 'mdb-react-ui-kit';
import { array, func, shape, string } from 'prop-types';
// Util imports
import { capitalize } from '../../utils';

const TabNav = ({ tabs = [], activeTab = '', setTab, containerStyle = {}, buttonStyle = {} }) => {
    return (
        <MDBTabs style={{ ...containerStyle }}>
            {tabs.map((tab, i) => {
                return (
                    <MDBTabsItem key={i} style={{ ...buttonStyle }}>
                        <MDBTabsLink onClick={() => setTab(tab)} active={tab === activeTab}>
                            {capitalize(tab)}
                        </MDBTabsLink>
                    </MDBTabsItem>
                );
            })}
        </MDBTabs>
    );
};

TabNav.propTypes = {
    tabs: array,
    activeTab: string,
    setTab: func.isRequired,
    buttonStyle: shape({}),
    containerStyle: shape({}),
};

export default TabNav;
