// Library imports
import styled from 'styled-components';
// Util imports
import { MEDIUM_SCREEN_WIDTH } from '../../../utils/constants';

export const AccessWrapper = styled.div`
    margin: 2rem auto;
    padding: 1rem;
`;

export const AlertContainer = styled.div`
    background-color: #d4edda;
    border: 1px solid #c3e6cb;
    padding: 1rem;
    border-radius: 0.25rem;
    margin-bottom: 1.5rem;
    text-align: center;
`;

export const AlertHeading = styled.h4`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 1rem;
`;

export const InfoRow = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    @media (max-width: ${MEDIUM_SCREEN_WIDTH}) {
        flex-direction: column;
    }
`;

export const InfoLabel = styled.div`
    font-size: 18px;
    font-weight: bold;
    width: 30%;
    @media (max-width: ${MEDIUM_SCREEN_WIDTH}) {
        width: 100%;
    }
`;

export const InfoValue = styled.div`
    font-size: 18px;
    width: 70%;
    @media (max-width: ${MEDIUM_SCREEN_WIDTH}) {
        width: 100%;
    }
`;
