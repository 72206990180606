// Library imports
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { MDBCardBody, MDBCardSubTitle } from 'mdb-react-ui-kit';
// Component imports
import LayoutWithHeader from '../../components/Layouts/LayoutWithHeader';
import Alert from '../../components/Alert';
import Information from './Information';
import Pay from './Pay';
import Access from './Access';
// Utils imports
import { scrollToTop } from '../../utils';
import { PACKAGES } from '../../utils/constants';
// Styled components
import { RegisterCard, StageBackButton, StageTitle } from './styles';
// Action imports
import { setData, setStage } from '../../store/slices/registerSlice';

const Register = () => {
    // Redux
    const { data, stage } = useSelector(state => state.register);
    const dispatch = useDispatch();
    // Local state
    const [error, setError] = useState('');

    const stageData = {
        info: {
            title: 'User Information',
            backFunc: () => (window.location.href = '/packages'),
            backTitle: 'Cancel',
        },
        pay: {
            title: 'Payment',
            backFunc: () => {
                setError('');
                dispatch(setStage('info'));
            },
            backTitle: 'Change Info',
        },
        access: {
            title: 'Complete',
            backFunc: () => (window.location.href = '/login'),
            backTitle: 'Login',
        },
    };

    useEffect(() => {
        setError('');
        scrollToTop();
    }, [stage]);

    useEffect(() => {
        if (error) {
            scrollToTop();
        }
    }, [error]);

    useEffect(() => {
        let params = queryString.parse(window.location.href.split('?')[1]);
        const plan = PACKAGES[params.package] ? { ...PACKAGES[params.package] } : { ...PACKAGES['yearly-access'] };
        dispatch(setData({ plan }));
    }, []);

    return (
        <LayoutWithHeader page="packages" contentStyle={{ backgroundColor: 'rgb(237, 237, 237)', padding: '0px 15px' }}>
            <Helmet>
                <title>Register | Line Prophet</title>
                <meta
                    name="description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Register | Line Prophet" />
                <meta
                    property="og:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta property="og:url" content="https://www.lineprophet.com/" />
                <meta property="og:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Register | Line Prophet" />
                <meta
                    name="twitter:description"
                    content="Line Prophet gives you everything you need to gain an edge over the books. Our supercomputer gives an algorithm-generated prediction for every game. We follow the most relevant trends using the most up to date data."
                />
                <meta name="twitter:image" content="https://lineprophet.com/static/media/logo-icon.3f56cee251416e22eb67.png" />
                <meta name="twitter:site" content="@LineProphetLLC" />
                <meta name="twitter:creator" content="@LineProphetLLC" />

                {/* Favicon */}
                <link rel="icon" href="/favicon.ico" />
            </Helmet>
            <RegisterCard>
                <StageBackButton color="link" onClick={stageData[stage].backFunc}>
                    {stageData[stage].backTitle}
                </StageBackButton>
                <StageTitle>{data.plan?.name}</StageTitle>
                <MDBCardSubTitle style={{ fontSize: 14, fontWeight: 400 }} className="text-muted">
                    {stageData[stage].title}
                </MDBCardSubTitle>
                <MDBCardBody>
                    {error && <Alert variant="danger" message={error} style={{ marginTop: 0 }} />}
                    {stage === 'info' ? (
                        <Information setError={setError} />
                    ) : stage === 'pay' ? (
                        <Pay setError={setError} />
                    ) : stage === 'access' ? (
                        <Access setError={setError} />
                    ) : (
                        <></>
                    )}
                </MDBCardBody>
            </RegisterCard>
        </LayoutWithHeader>
    );
};

export default Register;
