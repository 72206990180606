// Library imports
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { MDBContainer } from 'mdb-react-ui-kit';
// Component imports
import StatGraphs from './StatGraphs';
import HistoryTable from './HistoryTable';
import TabNav from '../../../components/TabNav';
import LoadingSpinner from '../../../components/LoadingSpinner';
// Controller imports
import { getAlgorithmStats } from '../../../controllers/AlgorithmController';

const Algorithm = () => {
    // Redux
    const { sport } = useSelector(state => state.app);
    // Local state
    const [tab, setTab] = useState('stats');
    const [algoData, setAlgoData] = useState({});
    const [games, setGames] = useState([]);
    const [loading, setLoading] = useState(true);

    const fetchAlgoStats = async () => {
        try {
            setLoading(true);
            const data = await getAlgorithmStats(sport, 'current_season');
            setAlgoData(data);
        } catch (error) {
            console.log(`Error getting algo stats data\n${error.message}`);
        } finally {
            setLoading(false);
        }
    };

    const fetchGameHistory = async () => {
        setGames([]);
    };

    useEffect(() => {
        if (tab === 'stats') {
            fetchAlgoStats();
        }
        if (tab === 'history') {
        }
    }, [sport, tab]);

    return (
        <div>
            <TabNav activeTab={tab} setTab={setTab} tabs={['stats', 'history']} containerStyle={{ width: '201px', margin: '0 auto' }} />
            {loading ? <LoadingSpinner /> : tab === 'stats' ? <StatGraphs algoData={algoData} /> : <HistoryTable games={games} />}
        </div>
    );
};

export default Algorithm;
