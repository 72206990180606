// Library imports
import styled from 'styled-components';
import { MDBCard, MDBListGroupItem } from 'mdb-react-ui-kit';
// Util imports
import { SMALL_SCREEN_WIDTH } from '../../../utils/constants';

export const PropTableCard = styled(MDBCard)`
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #ddd;
`;

export const GameDetailsHeader = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;
    align-items: center;
    gap: 0.5rem;
`;

export const TeamNameWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.25rem;
`;

export const TeamLogo = styled.img`
    height: 25px;
    width: auto;
`;

export const TeamName = styled.span`
    font-size: 1.25rem;
    font-weight: 700;
    font-family: ${({ theme }) => theme.fonts.heading};

    @media (max-width: ${SMALL_SCREEN_WIDTH}) {
        font-size: 1rem;
    }
`;

export const GameDate = styled.span`
    display: block;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.55rem;
    color: ${({ theme }) => theme.colors.darkGray};
    font-family: ${({ theme }) => theme.fonts.body};
`;

export const PropListItem = styled(MDBListGroupItem)`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor} !important;
    border-radius: 0px !important;
    padding: 0.75rem 1rem;
    margin-bottom: 0.75rem;
`;

export const OddsButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

/* Modal styles */
export const PickName = styled.span`
    font-size: 1em;
    font-weight: bold;
    margin-right: 10px;
`;
