// Library imports
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { SocialIcon } from 'react-social-icons';
import { MDBBtn, MDBCollapse, MDBContainer, MDBIcon, MDBNavbarBrand, MDBNavbarItem, MDBNavbarNav, MDBNavbarToggler } from 'mdb-react-ui-kit';
import { useTheme } from 'styled-components';
import Skeleton from 'react-loading-skeleton';
import { bool, string } from 'prop-types';
// Component imports
import UpgradeTooltip from '../UpgradeTooltip';
// Util imports
import { ACTIVE_SPORTS, SPORTS } from '../../utils/constants';
// Styled Components
import { StyledNavbarLink, Logo, StyledNavbar, SocialIconContainer, BannerWrapper, BannerText } from './styles';
// Image imports
import colorLogo from '../../img/horiz-logos/color.png';
import lightLogo from '../../img/horiz-logos/white.png';
import appstoreicon from '../../img/appstoreicon.png';
// Constant imports
import { APP_LINKS, STATIC_LINKS } from './constants';
// Action imports
import { setSubPage } from '../../store/slices/appSlice';

const Header = ({ fixedHeader = false, showBanner = false, currentPage = '', shadow = true }) => {
    // Redux
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    // Hooks
    const theme = useTheme();
    const history = useHistory();
    // Local state
    const [openNavNoToggler, setOpenNavNoToggler] = useState(false);
    const [transparent, setTransparent] = useState(true);
    const bannerEnabled = showBanner && user?.reciept?.type === 'free-account' ? true : false;

    if (typeof window !== 'undefined') {
        window.addEventListener('scroll', () => setTransparent(window.scrollY <= 100));
    }

    const getLinkArray = () => {
        if (STATIC_LINKS.find(link => link.id === currentPage)) return STATIC_LINKS;
        if (APP_LINKS.find(link => link.id === currentPage)) return APP_LINKS.filter(link => ACTIVE_SPORTS.includes(link.id) || link.id === 'profile');
        return [];
    };

    const changeMainPage = async e => {
        const pageName = e.currentTarget.id;
        setOpenNavNoToggler(false);
        if (pageName === 'home') {
            history.push(`/`);
        }
        if (pageName === 'login') {
            history.push(`/sports/${ACTIVE_SPORTS[0]}`);
        }
        if (pageName === 'profile') {
            dispatch(setSubPage('picks'));
            history.push(`/profile`);
        }
        if (pageName === 'about') {
            history.push(`/about`);
        }
        if (pageName === 'packages') {
            history.push('/packages');
        }
        if (pageName === 'blog') {
            window.open('https://blog.lineprophet.com/', '_blank');
        }
        if (SPORTS.includes(pageName)) {
            history.push(`/sports/${pageName}`);
        }
    };

    const renderSocialIcons = () => {
        if (!user?.reciept) return null;
        if (user.reciept?.type === 'free-account') {
            return (
                <SocialIconContainer>
                    <UpgradeTooltip
                        children={
                            <SocialIcon
                                style={{
                                    height: '35px',
                                    width: '35px',
                                    pointerEvents: 'none',
                                }}
                                network="twitter"
                            />
                        }
                        innerChildStyle={{
                            display: 'inline-block',
                            marginRight: '5px',
                        }}
                        label="You must be a VIP member access private Twitter."
                    />
                    <UpgradeTooltip
                        children={
                            <SocialIcon
                                style={{
                                    height: '35px',
                                    width: '35px',
                                    pointerEvents: 'none',
                                }}
                                network="discord"
                            />
                        }
                        innerChildStyle={{ display: 'inline-block' }}
                        label="You must be a VIP member access private Discord."
                    />
                    <UpgradeTooltip
                        children={
                            <a href="https://apps.apple.com/us/app/line-prophet/id1641926747" target="_blank" rel="noopener noreferrer">
                                <img src={appstoreicon} alt="appstore" style={{ height: '35px', width: '35px', marginRight: '5px' }} />
                            </a>
                        }
                        innerChildStyle={{ display: 'inline-block' }}
                        label="You must be a VIP member access mobile app."
                    />
                    <UpgradeTooltip
                        children={
                            <SocialIcon
                                style={{ height: '35px', width: '35px' }}
                                network="google_play"
                                url="https://play.google.com/store/apps/details?id=com.kwaggoner00.lineProphet&hl=en&gl=US&pli=1"
                            />
                        }
                        innerChildStyle={{ display: 'inline-block' }}
                        label="You must be a VIP member access mobile app."
                    />
                </SocialIconContainer>
            );
        }
        return (
            <SocialIconContainer>
                <SocialIcon style={{ height: '35px', width: '35px', marginRight: '5px' }} url="https://twitter.com/LineProphetVIP" />
                <SocialIcon
                    style={{
                        height: '35px',
                        width: '35px',
                        marginRight: '5px',
                    }}
                    url="https://discord.gg/VSwkA2tDYE"
                />
                <a href="https://apps.apple.com/us/app/line-prophet/id1641926747" target="_blank" rel="noopener noreferrer">
                    <img src={appstoreicon} alt="appstore" style={{ height: '35px', width: '35px', marginRight: '5px' }} />
                </a>
                <SocialIcon
                    style={{ height: '35px', width: '35px' }}
                    network="google_play"
                    url="https://play.google.com/store/apps/details?id=com.kwaggoner00.lineProphet&hl=en&gl=US&pli=1"
                />
            </SocialIconContainer>
        );
    };

    return (
        <>
            <StyledNavbar
                transparent={fixedHeader && !openNavNoToggler ? transparent.toString() : 'false'}
                {...(fixedHeader ? { fixed: 'top' } : {})}
                collapseOnSelect
                expand="lg"
                shadow={shadow}
            >
                <MDBContainer fluid>
                    <MDBNavbarBrand href="/">
                        <Logo src={fixedHeader && transparent && !openNavNoToggler ? lightLogo : colorLogo} alt="home" />
                    </MDBNavbarBrand>
                    <MDBNavbarToggler
                        style={{ color: fixedHeader && transparent && !openNavNoToggler ? theme.colors.white : theme.colors.primary }}
                        type="button"
                        aria-controls="responsive-navbar-nav"
                        onClick={() => setOpenNavNoToggler(!openNavNoToggler)}
                    >
                        <MDBIcon icon="bars" fas />
                    </MDBNavbarToggler>
                    {APP_LINKS.find(link => link.id === currentPage) && renderSocialIcons()}
                    <MDBCollapse navbar open={openNavNoToggler} style={{ justifyContent: 'flex-end' }}>
                        <MDBNavbarNav variant="pills" activeKey={currentPage} className="align-items-center">
                            {getLinkArray().map((link, i) => {
                                if (link.id === 'profile') {
                                    link.label = user?.displayName ? user.displayName : <Skeleton inline={false} width={75} />;
                                }
                                return (
                                    <MDBNavbarItem key={i}>
                                        <StyledNavbarLink
                                            transparent={(transparent && !openNavNoToggler).toString()}
                                            dark={fixedHeader.toString()}
                                            id={link.id}
                                            active={(link.id === currentPage).toString()}
                                            onClick={changeMainPage}
                                        >
                                            <MDBIcon icon={link.icon} className="me-1" />
                                            {typeof link.label === 'object' ? link.label : <span>{`${link.label}`}</span>}
                                        </StyledNavbarLink>
                                    </MDBNavbarItem>
                                );
                            })}
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </StyledNavbar>
            {bannerEnabled && (
                <BannerWrapper>
                    <BannerText>
                        <span style={{ color: theme.colors.white, fontWeight: '600', fontSize: '1.25rem' }}>Upgrade to VIP: </span>
                        <span style={{ color: theme.colors.white, fontWeight: '400', fontSize: '.75rem' }}>
                            (Get access to expert picks and algo predictions){' '}
                        </span>
                    </BannerText>
                    <MDBBtn
                        color="light"
                        onClick={() => {
                            dispatch(setSubPage('settings'));
                            history.push('/profile');
                        }}
                    >
                        Become a VIP
                    </MDBBtn>
                </BannerWrapper>
            )}
        </>
    );
};

Header.propTypes = {
    fixedHeader: bool,
    showBanner: bool,
    currentPage: string,
    shadow: bool,
};

export default Header;
