// Library imports
import React from 'react';
import { func, shape, string } from 'prop-types';
// Styled components
import { AlertWrapper, CloseButton } from './styles';

const Alert = ({ message, variant = 'primary', onClose = undefined, style = {} }) => {
    return (
        <AlertWrapper variant={variant} style={style}>
            {message}
            {onClose && <CloseButton onClick={onClose}>&times;</CloseButton>}
        </AlertWrapper>
    );
};

Alert.propTypes = {
    message: string.isRequired,
    variant: string,
    onClose: func,
    style: shape({}),
};

export default Alert;
