// Controller imports
import { keyFactorList } from '../../../../../../controllers/GetDataController';

export const keyFactorData = async (sport, awayData, homeData, type = 'off') => {
    sport = String(sport).toLowerCase();
    var fieldsArray = await keyFactorList(sport);
    var awayFactors = [];
    var homeFactors = [];
    var labels = [];
    fieldsArray
        .filter(field => field.type === type)
        .forEach(field => {
            var result = assignAdvantage(field, awayData, homeData);
            var roundTo =
                field.id === 'obp' ||
                field.id === 'era' ||
                field.id === 'babip' ||
                field.id === 'avg' ||
                field.id === 'slg' ||
                field.id === 'fpct' ||
                field.id === 'whip' ||
                field.id === 'xfip'
                    ? 3
                    : field.id === 'astTo' ||
                      field.id === 'offEff' ||
                      field.id === 'defEff' ||
                      field.id === 'Earned Run Average' ||
                      field.id === 'Walks Plus Hits Per Inning Pitched'
                    ? 2
                    : 1;
            labels.push(field.label);
            const suffix = field.suffix ? field.suffix : '';
            awayFactors.push({
                value: result.awayFactor !== 'N/A' ? `${result.awayFactor.toFixed(roundTo)}${suffix}` : result.awayFactor,
                class: result.resAwayClass,
            });
            homeFactors.push({
                value: result.homeFactor !== 'N/A' ? `${result.homeFactor.toFixed(roundTo)}${suffix}` : result.homeFactor,
                class: result.resHomeClass,
            });
        });
    return [awayFactors, homeFactors, labels];
};

const assignAdvantage = (field, awayData, homeData) => {
    let data = {
        awayFactor: 0,
        resAwayClass: '',
        homeFactor: 0,
        resHomeClass: '',
    };
    if (field.type === 'pitcher') {
        data.awayFactor = awayData.pitcher?.stats?.[field.id] ? awayData['pitcher']['stats'][field.id] : 0;
        data.homeFactor = homeData.pitcher?.stats?.[field.id] ? homeData['pitcher']['stats'][field.id] : 0;
    } else {
        data.awayFactor = awayData.stats?.[field.type]?.[field.id] ? awayData['stats'][field.type][field.id] : 0;
        data.homeFactor = homeData.stats?.[field.type]?.[field.id] ? homeData['stats'][field.type][field.id] : 0;
    }
    if (field.winner === 'gt' /*&& Math.abs(data.awayFactor - data.homeFactor) > field.threshold*/) {
        data.resAwayClass = data.awayFactor === data.homeFactor ? 'light' : data.awayFactor > data.homeFactor ? 'success' : 'danger';
        data.resHomeClass = data.awayFactor === data.homeFactor ? 'light' : data.homeFactor > data.awayFactor ? 'success' : 'danger';
    }
    if (field.winner === 'lt' /*&& Math.abs(data.awayFactor - data.homeFactor) > field.threshold*/) {
        data.resAwayClass = data.awayFactor === data.homeFactor ? 'light' : data.awayFactor < data.homeFactor ? 'success' : 'danger';
        data.resHomeClass = data.awayFactor === data.homeFactor ? 'light' : data.homeFactor < data.awayFactor ? 'success' : 'danger';
    }
    return data;
};
